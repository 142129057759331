<mat-card style="width:1000px;margin:0 auto">
  <h1 mat-card-title>Privacy Policy</h1>
  <mat-card-content>
    <div class="padding20 small">

      <p>Effective (Last edit) May 2022.</p>
      <p>Your privacy is important to Alpha Warranty Services, Inc (“Alpha,” “we,” “us” or “our”) and we have set forth the details about our privacy policies and how your information is used and protected. Alpha understands the right to privacy of every visitor to our Website zoom.alphawarranty.com (“Website”).  Our Privacy Policy (“Privacy Policy”) explains our online practices and how we collect, use, and protect your information.  The Privacy Policy may be changed or updated without prior notice to you.  By visiting our Website, you acknowledge your agreement to be bound by the practices described in this Privacy Policy.  Our Privacy Policy applies to all consumers, and we encourage you to read through and review this Privacy Policy each time you access the Website.  If you do not agree with this Privacy Policy, we ask that you not use the Website.</p>
      <h3>Protecting Your Privacy</h3>
      <h4>I. The Website Covered</h4>
      <p>This Privacy Policy covers the information practices of zoom.alphawarranty.com. When Websites are posted by Alpha, the Website will link to this Privacy Policy, and this Privacy Policy applies.  However, when Websites are posted by third parties, the Privacy Policy of the third party applies, and this Privacy Policy does not apply. In addition, zoom.alphawarranty.com may contain links to other Websites. This Privacy Policy applies only to information collected on zoom.alphawarranty.com and does not govern the information practices or the content of such other Websites.  We encourage you to review the Privacy Policies of other Websites to understand their information practices.</p>
      <p>Alpha reserves the right to change this Privacy Policy at any time.  You will not be given any notice to these modifications.  Your continued use of the Website after any changes are posted will be deemed to constitute your agreement to and acceptance of such changes to this Privacy Policy.</p>
      <h4>II. Data Collection</h4>
      <p>You are not required to provide us your personal information. Alpha may collect information from you when you use our Website or services.  For example, we may collect your name, address, telephone number, email address, and details about your vehicle.  We may also collect information about your claims, as well as information that we receive from dealers, agents, or others who sell Alpha vehicle service contracts and information you provide on vehicle service applications or other forms.</p>
      <p>Alpha may use your information to determine your eligibility for certain products, process claims, and fulfill its duties under its vehicle service contracts.  Alpha may also use your information to personalize and improve its services, communicate with you, and/or respond to your requests for information about Alpha products, services, offers, or promotions.</p>
      <p>Alpha restricts access to your information to authorized Alpha personnel.  Additionally, we apply commercially reasonable security measures to protect your data from use by unauthorized people or for improper purposes.</p>
      <h4>III. Collected Data</h4>
      <p>Alpha uses standard internet data gathering tools (e.g. cookies), to collect information as users navigate zoom.alphawarranty.com (“Collected Data”). Types of Collected Data that Alpha might collect and how Alpha may use the Collected Data are listed below.</p>
      <h5>Cookies</h5>
      <p>Alpha uses cookies to simplify use of the Website.  When a user visits the Website, Alpha’s servers send a cookie to the user’s computer.  These standard cookies only recognize information such as a web browser.  These cookies do not personally identify individual users.  Unless you choose to identify yourself to Alpha, such as by responding to marketing material or filling out a form, you remain anonymous to us.</p>
      <p>There are two types of cookies: session and persistent. Session cookies are temporary.  They only exist only during one session.  They are automatically removed from your computer when you close your browser software or turn off your computer.  Persistent cookies remain on your computer until you manually elect to remove them.</p>
      <p>If you have chosen to identify yourself to Alpha (such as by logging in with your credentials), we use session cookies containing encrypted information to allow us to uniquely identify you.  Each time you log into the Website, a session cookie containing an encrypted, unique handle that is tied to your account is placed in your browser.  These encrypted cookies allow us to uniquely identify you when you are logged into the Website and are used to facilitate your online submissions and requests.  Session cookies are required to use the Website.</p>
      <p>Alpha uses persistent cookies that only we can read and use to identify browsers that have previously visited our Website.  When you access the Website or provide Alpha with personal information, a unique handle is assigned to you, by which we can identify you.  This unique handle is associated with a persistent cookie that Alpha places on your web browser.  We are careful about the security and confidentiality of the information stored in persistent cookies.  Passwords, account numbers, and other critical data are not stored in persistent cookies.  If you disable your web browser’s ability to accept cookies, you will be able to navigate our Website, but you will not be able to successfully use the Website.</p>
      <h4>Marketing Data</h4>
      <h5>Web Beacons</h5>
      <p>Alpha uses web beacons, also known as pixel tags to collect data about users’ usage of the Website.  Web beacons are clear electronic images that can recognize certain types of information on your computer, such as cookies, when you viewed a particular website tied to the web beacon, and a description of a website tied to the web beacon.  For example, we may place web beacons in marketing emails that notify us when you click on a link in the email that directs you to an Alpha website.  Alpha uses web beacons to operate and improve our website and email communications and to analyze the performance of our marketing campaigns.  Alpha may use information from web beacons in combination with data about users to provide you with information about us.</p>
      <h5>IP Addresses</h5>
      <p>When you visit the Website, we collect your Internet Protocol (“IP”) addresses to track and collect non-personally identifiable information.  IP address information may be used to understand from which regions users access the Website, among other non-personal uses.</p>
      <h5>Third-Party Cookies</h5>
      <p>Alpha periodically engages third-party service providers to track and analyze non-personally identifiable statistical usage and traffic information from Website visitors.  We may also use other third-party cookies to track the performance of Alpha advertising.  The information provided to such third parties does not include personal information, but this information may be re-associated with personal information after we receives it.  This Privacy Policy does not cover the use of third-party cookies.</p>
      <h4>IV. Sharing of Collected Data, Security, Tracking</h4>
      <p>Alpha may disclose your information to authorized associates and partners where doing so would assist us in serving you better.  For example, if you choose to file a claim via our Website, Alpha may disclose your contact information, vehicle information, and vehicle service contract information with a repair facility to assist your vehicle with repairs.</p>
      <p>Alpha does not share your information with nonaffiliated third parties unless Alpha believes it reasonably necessary to comply with the law or a court order or to protect the rights of Alpha, or our employees, customers or others.</p>
      <p>Alpha is committed to protecting the confidentiality of your information and online interactions with us.  We maintain various safeguards designed to protect your personal information.  We have security procedures in place to safeguard against loss, theft, unauthorized access, destruction, misuse, and modification of this information after we receive it.  For example, Alpha restricts access to the information to our employees, agents, and contractors who need to know such information to provide products or services to you.</p>
      <p>Alpha does not process or respond to “do not track” signals or other similar mechanisms.</p>
      <p>The Website is intended for use people at least 18 years of age.  If you are under the age of 18, you should not be visiting zoom.alphawarranty.com.  We do not knowingly collect or retain personal information from children under the age of 13.  If you are under the age of 13 and are visiting or otherwise using zoom.alphawarranty.com, you are expressly prohibited from using our services and are advised not to disclose or provide any personally identifiable information on zoom.alphawarranty.com.</p>
      <p>Additional information for CA residents concerning relevant CA law <a href="~/pdf/CaliforniaFinancialPrivacyAct.pdf" tabindex="-1">download here</a>.</p>
      <h4>V. Outside of United States, Data and Tracking</h4>
      <p>If you reside outside the United States, you understand and agree that any information you provide to Alpha will be transferred from the country of your residence or location at the time it was provided to the United States.  If you do not want your personally identifiable information to be transferred to the United States, do not provide that information to us.  By providing us with your information, you consent to the transfer of your information to the United States.  You understand that the privacy protection laws in place in the United States may differ from those of your country of residence or your location at time of access, and you agree that any data given to us will receive only the protections stated herein, or as required by U.S. law.</p>
      <h4>VI. Contact Us</h4>
      <p>Questions regarding this Privacy Policy or the information practices of our Website should be directed to us at (800) 662-5519.</p>
    </div>
  </mat-card-content>
</mat-card>



