<div *ngIf="isLoaded">
  <div
    *ngIf="isInvalidToken"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 100vh"
  >
    <div class="form-card">
      <img src="../../assets/logo-md.png" />
      <h2>Invalid Token</h2>
      <p>
        This token is expired. To reset your password, please verify your email
        address and click "Resend Email" button.
      </p>
      <form [formGroup]="resetPasswordForm" (click)="resend()">
        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput type="text" formControlName="userName" required />
        </mat-form-field>
        <div fxLayoutAlign="center">
          <button
            *ngIf="!isResending"
            mat-flat-button
            color="primary"
            type="submit"
          >
            resend email
          </button>
          <mat-spinner
            *ngIf="isResending"
            diameter="36"
            strokeWidth="8"
          ></mat-spinner>
        </div>
      </form>
    </div>
  </div>

  <div
    *ngIf="!isInvalidToken"
    fxLayout="row"
    fxLayoutAlign="center center"
    style="height: 100vh"
  >
    <div class="form-card">
      <img src="../../assets/logo-md.png" />
      <h2>Set Password</h2>
      <p>
        Please enter and confirm your new password below. Note: Your password
        will need to be at least 6 characters long. It will also need to contain
        a capital letter, one number, and one special character (!, ?, $, etc.)
      </p>
      <form
        style="margin-bottom: 1em"
        [formGroup]="resetPasswordForm"
        (submit)="submit()"
      >
        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput type="text" formControlName="userName" required />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>New Password</mat-label>
          <input matInput type="password" formControlName="password" required />

          <mat-error
            *ngIf="resetPasswordForm.controls.password?.errors?.minlength"
          >
            Passwords must be at least 6 characters
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="newPassword"
            required
          />
          <mat-error
            *ngIf="resetPasswordForm.controls.newPassword?.errors?.same"
          >
            Passwords do not match
          </mat-error>
        </mat-form-field>
        <div fxLayout="column" fxLayoutGap="1em">
          <div fxLayoutAlign="center">
            <mat-spinner
              *ngIf="isSubmitting"
              diameter="36"
              strokeWidth="8"
            ></mat-spinner>
            <button
              *ngIf="!isSubmitting"
              mat-flat-button
              color="primary"
              type="submit"
            >
              submit
            </button>
          </div>
          <div fxLayoutAlign="center">
            <button
              *ngIf="!isResending"
              style="background-color: #e8d238"
              mat-flat-button
              (click)="resend()"
            >
              resend email
            </button>
            <mat-spinner
              *ngIf="isResending"
              diameter="36"
              strokeWidth="8"
            ></mat-spinner>
          </div>
          <div>
            <a
              mat-button
              style="background-color: #f44336; color: white"
              routerLink="/login"
              >cancel</a
            >
          </div>
        </div>
      </form>

      <div class="mat-error" *ngIf="message">
        {{ message }}
      </div>

      <div class="copyright-state">
        <div>
          Contact Sales Support at <strong>1.800.662.5519</strong> for
          Assistance
        </div>
        <div>
          Copyright &#169; All Rights Reserved by Alpha Warranty Services Inc.
        </div>
      </div>
    </div>
  </div>
</div>
