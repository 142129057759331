import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  AbstractControl,
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { OverrideDialogConfirmationComponent } from './override-dialog-confirmation/override-dialog-confirmation.component';

@Component({
  selector: 'app-override-dialog',
  templateUrl: './override-dialog.component.html',
  styleUrls: ['./override-dialog.component.css'],
})
export class OverrideDialogComponent implements OnInit {
  overrideForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<OverrideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.overrideForm = this.fb.group({
      name: [null, Validators.required],
      title: [null, Validators.required],
      paymentRecipient: [null, Validators.required],
      paymentAmount: [null, Validators.required],
      additionalComments: [null, Validators.required],
    });
  }

  ngOnInit(): void {}

  onCancelClick(): void {
    this.dialogRef.close();
  }
  
  openConfirmDialog(): void {
    const dialogRef = this.dialog.open(OverrideDialogConfirmationComponent, {
      width: '400px',
      data: {
        formData: this.overrideForm.value,
      },
    });
  }
}
