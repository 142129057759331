<div class="sig-wrapper">
  <div fxLayout="row">
    <button mat-flat-button type="button" color="primary" (click)="clear()">
      Clear Signature
    </button>
  </div>
  <div class="signature-message">
    <small>Please use your cursor or finger to sign in the box below</small>
  </div>
  <signature-pad
    [options]="options"
    [existingSignature]="existingSignature"
    (onBeginEvent)="drawBegin()"
    (onEndEvent)="drawComplete()"
  ></signature-pad>
</div>
