<mat-form-field appearance="outline">
  <mat-label>{{ formLabel }}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="filterInput"
    [matAutocomplete]="autoComplete"
    [required]="controlRequired" />
  <mat-icon
    matSuffix
    (click)="clearText()"
    *ngIf="showClearButton && !inputIsClear"
    >close</mat-icon
  >
  <mat-autocomplete
    #autoComplete="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="optionSelected()">
    <mat-option *ngFor="let opt of filteredAgents | async" [value]="opt">
      {{ opt.scsNumber }} - {{ opt.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
