<div *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading">
  <div matDialogTitle fxLayoutAlign="space-between">
    <h3 style="margin: 0">{{ notification.displayText }}</h3>
    <div fxLayoutAlign="center center">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div
    *ngIf="notification.notificationBody"
    matDialogContent
    [innerHtml]="notification.notificationBody"
  ></div>
</div>
