import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { SiriusXMRealTimeModel, SiriusXMRealTimeResponseModel } from './sirius-xm.model';

@Injectable({
  providedIn: 'root'
})
export class SiriusXMService {
  private endpoint: string;
  response: SiriusXMRealTimeResponseModel | undefined;
  constructor(private http: HttpClient) {
    this.endpoint = environment.dashboardEndpoint + environment.version;
   }

  public RealTimeRequest(data: SiriusXMRealTimeModel){
    return this.http.post<SiriusXMRealTimeResponseModel>(`${this.endpoint}sirius-xm/real-time`, data)
  }

  public RealTimeTestCases(){
    return this.http.get(`${this.endpoint}sirius-xm/real-time/test-cases`)
  }
  public RealTimeCrmTest(){
    return this.http.get(`${this.endpoint}sirius-xm/real-time/crm-test`)
  }

  public RealTimeApplyToExistingContracts(){
    return this.http.get(`${this.endpoint}sirius-xm/real-time/apply-to-historical-vins`)
  }

}
