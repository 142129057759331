import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quicksign-service-plus-breakdown',
  templateUrl: './quicksign-service-plus-breakdown.component.html',
  styleUrls: ['./quicksign-service-plus-breakdown.component.css']
})
export class QuicksignServicePlusBreakdownComponent implements OnInit {
  @Input() expanded: boolean;
  benefitsMarkupData: any[] = [
    {
      name: 'Limited 3rd Party Part Sourcing',
      budgetValue: 75,
      aPlusP1NewCarValue: 115,
      otherValue: 15,
    },
    {
      name: 'No 3rd Party Part Sourcing',
      budgetValue: 100,
      aPlusP1NewCarValue: 150,
      otherValue: 20,
    },
    {
      name: 'Enhanced Rental Car',
      budgetValue: 30,
      aPlusP1NewCarValue: 30,
      otherValue: 10,
    },
    {
      name: 'Coverage for Fluids, Flushes, Alignments, etc.',
      budgetValue: 45,
      aPlusP1NewCarValue: 65,
      otherValue: 15,
    },
    {
      name: 'Enhanced Labor Rate',
      budgetValue: 25,
      aPlusP1NewCarValue: 35,
      otherValue: 10,
    },
  ]
  displayedColumns: string[] = [
    'title',
    'budget',
    'aPlusP1NewCar',
    'other'
  ];
  constructor() { 
    if(this.expanded == undefined || this.expanded == null) {
      this.expanded = false;
    }
  }

  ngOnInit(): void {
  }

}
