<div fxLayout="column" style="min-height: 100vh">
  <div>
    <a href="https://alphawarranty.com">
      <img
        height="65"
        src="/assets/alpha-logo-black.png"
        alt="Alpha Warranty Services"
      />
    </a>
  </div>
  <div fxFlex="5 0 auto" class="container">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
