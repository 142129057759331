import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { AuthService } from '../../shared/auth.service';
import { PrincipalService } from '../../shared/principal.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.css'],
})
export class ImpersonateComponent implements OnInit {
  userViewAccessType = new FormControl();
  actorSelection = new FormControl();
  inputFilter = new FormControl();

  userList$: Observable<any[]>;

  constructor(
    private authentication: AuthService,
    private principal: PrincipalService,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    this.userViewAccessType.setValue(3);
    this.userList$ = this.inputFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap((value: string | any) => {
        if (!value) {
          return this.authentication.impersonateUsers(
            this.userViewAccessType.value
          );
        }
        if (value && (value as string).length > 0) {
          return this.authentication.impersonateUsers(
            this.userViewAccessType.value,
            value as string
          );
        }
        return EMPTY;
      })
    );
  }

  impersonateUser() {
    this.authentication
      .impersonate(this.actorSelection.value, this.principal.uuid)
      .subscribe({
        next: (response) => {
          localStorage.clear();
          if (this.cookieService.check("alphaOrAlpine")) {
            this.cookieService.delete("alphaOrAlpine");
          }
          this.principal.set(response);
        },
        error: () => {},
        complete: () => {},
      });
  }

  scsNumber(entry: any) {
    if (entry.userViewAccessType == 'Dealer') {
      return entry.assignedDealerNum;
    }
    return entry.scsNumber;
  }

  displayWith(value: any) {
    if (value && value.userViewAccessType == 'Dealer') {
      return `${value.assignedDealerNum} - ${value.userName}`;
    } else if (value && value.userViewAccessType) {
      return `${value.scsNumber} - ${value.userName}`;
    }
    return value;
  }

  optionSelected() {
    this.actorSelection.setValue(this.inputFilter.value.id);
  }
}
