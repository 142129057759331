import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CilajetService } from 'src/shared/cilajet.service';
import { ContractService } from 'src/shared/contract.service';
import { PrincipalService } from 'src/shared/principal.service';
import { State, states } from 'src/shared/states.model';
import { ZoomValidators } from 'src/shared/zoom-validators';

@Component({
  selector: 'app-cilajet-dialog',
  templateUrl: './cilajet-dialog.component.html',
  styleUrls: ['./cilajet-dialog.component.css'],
})
export class CilajetDialogComponent implements OnInit {
  states$: State[] = states;
  showBillingCategory: boolean = false;
  submitting: boolean = false;

  formGroup: FormGroup = new FormGroup({
    shippingTo: new FormControl(null, [Validators.required]),
    shippingEmail: new FormControl(null, [
      Validators.required,
      ZoomValidators.emailAddress,
    ]),
    shippingAttention: new FormControl(null),
    shippingAddress: new FormControl(null, [Validators.required]),
    shippingCity: new FormControl(null, [Validators.required]),
    shippingState: new FormControl(null, [Validators.required]),
    shippingPostalCode: new FormControl(null, [Validators.required]),

    sameAsShipping: new FormControl(false),
    billingCategory: new FormControl('', [Validators.required]),
    billingTo: new FormControl(null, [Validators.required]),
    billingEmail: new FormControl(null, [
      Validators.required,
      ZoomValidators.emailAddress,
    ]),
    billingAddress: new FormControl(null, [Validators.required]),
    billingCity: new FormControl(null, [Validators.required]),
    billingState: new FormControl(null, [Validators.required]),
    billingPostalCode: new FormControl(null, [Validators.required]),

    quantity: new FormControl(null, [Validators.required, Validators.min(1)]),
    unitPrice: new FormControl(null),
  });

  constructor(
    private service: CilajetService,
    private contractService: ContractService,
    private principal: PrincipalService,
    private snack: MatSnackBar,
    private dialog: MatDialogRef<CilajetDialogComponent>
  ) {}

  ngOnInit() {
    switch (this.principal.userViewAccessType.replace(' ', '').toLowerCase()) {
      case 'dealer':
      case 'dealfi':
        this.formGroup.patchValue({ billingCategory: 'dealer' });
        this.showBillingCategory = false;
        break;
      default:
        this.showBillingCategory = true;
        break;
    }

    this.formGroup.valueChanges.subscribe({
      next: (val) => {
        if (!this.billingMatchesShipping) {
          this.formGroup.get('sameAsShipping').setValue(false, {
            emitEvent: false,
          });
        }
      },
    });
  }

  postalLookup(event: any, isShipping: boolean = true) {
    if (event.target.value.length < 5) {
      return;
    }
    this.contractService.getLocation(event.target.value).subscribe({
      next: (res) => {
        if (isShipping) {
          this.formGroup.patchValue({
            shippingCity: res.places[0]['place name'],
            shippingState: res.places[0]['state abbreviation'],
          });
        } else {
          this.formGroup.patchValue({
            billingCity: res.places[0]['place name'],
            billingState: res.places[0]['state abbreviation'],
          });
        }
      },
    });
  }
  toggleSameAsShipping(event: any) {
    if (!event.target.checked) {
      this.formGroup.patchValue({
        billingTo: this.formGroup.get('shippingTo').value,
        billingEmail: this.formGroup.get('shippingEmail').value,
        billingAddress: this.formGroup.get('shippingAddress').value,
        billingCity: this.formGroup.get('shippingCity').value,
        billingState: this.formGroup.get('shippingState').value,
        billingPostalCode: this.formGroup.get('shippingPostalCode').value,
      });
    }
  }

  submit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.submitting = true;
    this.formGroup.get('unitPrice').setValue(this.unitPrice);
    this.service.submitForm(this.formGroup.value).subscribe({
      next: () => {
        this.submitting = false;
        this.snack.open('Order submitted successfully', 'Close', {
          duration: 3000,
        });
        this.close();
      },
      error: () => {
        this.submitting = false;
        this.snack.open(
          'Error submitting order. Please try again later.',
          'Close',
          {
            duration: 3000,
          }
        );
      },
      complete: () => {
        this.submitting = false;
      },
    });
  }

  close() {
    this.dialog.close();
  }

  get unitPrice(): number {
    switch (this.principal.userViewAccessType.replace(' ', '').toLowerCase()) {
      case 'dealer':
      case 'dealfi':
        return 40;
      default:
        break;
    }
    var billingCategory = this.formGroup.get('billingCategory').value;
    switch (billingCategory) {
      case 'dealer':
        return 40;
      case 'agency':
        return 30;
      case 'admin':
        return 14;
      default:
        return 40;
    }
  }

  get billingMatchesShipping(): boolean {
    return (
      this.formGroup.get('billingTo').value ===
        this.formGroup.get('shippingTo').value &&
      this.formGroup.get('billingEmail').value ===
        this.formGroup.get('shippingEmail').value &&
      this.formGroup.get('billingAddress').value ===
        this.formGroup.get('shippingAddress').value &&
      this.formGroup.get('billingCity').value ===
        this.formGroup.get('shippingCity').value &&
      this.formGroup.get('billingState').value ===
        this.formGroup.get('shippingState').value &&
      this.formGroup.get('billingPostalCode').value ===
        this.formGroup.get('shippingPostalCode').value
    );
  }

  get selectedQty(): number {
    var qty = this.formGroup.get('quantity').value;
    return qty ? qty : 0;
  }

  get isAdmin(): boolean {
    return (
      this.principal.userViewAccessType.replace(' ', '').toLowerCase() ===
      'siteadmin'
    );
  }
}
