import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormControl } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PrincipalService } from '../principal.service';
import { ScsDetail, UserService } from '../user.service';

@Component({
  selector: 'app-chip-dealer-select',
  templateUrl: './chip-dealer-select.component.html',
  styleUrls: ['./chip-dealer-select.component.css'],
})
export class ChipDealerSelectComponent implements OnInit, AfterViewInit {
  dealerSelect = new FormArray([]);
  @Input('label')
  displaylabel: string = 'Dealers';
  @Input('required')
  isRequired: boolean = false;
  @Input()
  includeDemoDealers = true;
  @Input()
  onlyActiveDealers: boolean | undefined;
  @Input()
  includeDFIDealers: boolean | undefined;
  @Input()
  excludeProfitSharing: boolean = false;
  @Input()
  isDealerReinsurance: boolean = false;
  scsAccessFilter: Observable<any[]>;
  selectedDealers: any[] = [];

  visible = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  inputFilter = new FormControl();
  initialLoad: boolean = true;

  constructor(
    private parent: ControlContainer,
    public principal: PrincipalService,
    private userService: UserService
  ) {}
  ngAfterViewInit(): void {
    this.inputFilter.setValue('');
  }

  ngOnInit() {
    this.dealerSelect = this.parent.control as FormArray;
    this.selectedDealers = [];
    this.scsAccessFilter = this.inputFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap((value: string | ScsDetail | null) => {
        if (this.initialLoad) {
          this.initialLoad = false;
          return this.userService.scsAccessFilter(
            '',
            this.includeDFIDealers,
            this.onlyActiveDealers,
            this.includeDemoDealers,
            this.excludeProfitSharing,
            this.isDealerReinsurance
          );
        }
        if (typeof value == 'string' && (value as string).length > 4) {
          return this.userService.scsAccessFilter(
            value as string,
            this.includeDFIDealers,
            this.onlyActiveDealers,
            this.includeDemoDealers,
            this.excludeProfitSharing,
            this.isDealerReinsurance
          );
        }
        return EMPTY;
      })
    );

    if (this.dealerSelect.length > 0) {
      const data = this.dealerSelect.value;
      this.userService.preloadAccessFilter(data).subscribe({
        next: (result) => {
          this.selectedDealers = new Array<any>(data.length);
          // Loop thru the data and add as need to the display
          data.forEach((value, index) => {
            this.selectedDealers[index] = result.find(
              (rv) => rv.scsNumber.toUpperCase() == value.toUpperCase()
            );
          });
        },
      });
    }
  }

  autoSelectChange(dealerInput: HTMLInputElement) {
    const selectedDealer = this.inputFilter.value;
    if (typeof selectedDealer == 'string') {
      console.error('How did this happen?');
      return;
    }
    // validate this value doesn't already exist
    if (
      this.dealerSelect.controls.some(
        (d) => d.value == selectedDealer.dealerNumber
      )
    ) {
      this.inputFilter.updateValueAndValidity();
      dealerInput.value = null;
      return;
    }
    this.add(selectedDealer, false);
    dealerInput.value = null;
    this.inputFilter.updateValueAndValidity();
  }

  displayFn(value: ScsDetail) {
    if (value && value.scsNumber) {
      return `${value.name} - ${value.scsNumber}`;
    }
    return value;
  }

  private add(dealer: ScsDetail, sync: boolean) {
    this.selectedDealers.push(dealer);
    this.dealerSelect.push(new FormControl(dealer.scsNumber));
  }

  remove(index: number) {
    this.selectedDealers.splice(index, 1);
    this.dealerSelect.removeAt(index);
  }

  blur() {
    this.dealerSelect.updateValueAndValidity();
    this.inputFilter.updateValueAndValidity();
  }
}

interface DealerDetail {
  dealerNumber: string;
  dealerName: string;
}
