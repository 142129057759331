import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  endpoint: string;
  constructor(
    private http: HttpClient
  ) {
    this.endpoint = environment.dashboardEndpoint + environment.version
  }

  createNotification(postBody: any) {
    return this.http.post<any>(`${this.endpoint}notification`, postBody);
  }

  editNotification(body: any, notificationId: number) {
    return this.http.put<any>(`${this.endpoint}notification/${notificationId}`, body)
  }

  getNotifications() {
    return this.http.get<any>(`${this.endpoint}notification`);
  }

  publishNotification(notificationId: Number) {
    return this.http.post(`${this.endpoint}notification/${notificationId}/publish`, null);
  }

  deleteNotification(notificationId: Number) {
    return this.http.delete(`${this.endpoint}notification/${notificationId}`);
  }

  getUserNotification(userId: any) {
    return this.http.get<any[]>(`${this.endpoint}notification/${userId}`);
  }

  markNotificationRead(notificationId: any, id: any) {
    return this.http.post(`${this.endpoint}notification/mark-as-read`, null, {
      params: {
        notificationId,
        userId: id
      }
    });
  }
}
