import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import {
  ClaimSearchInput,
  ClaimList,
  Claim,
  ClaimDetails,
  ClaimNotes,
  Vehicle,
  ClaimSummary,
  ClaimDetailSummary,
  ClaimPart,
} from './claim.model';
import { Observable } from 'rxjs';
import { Contract } from './contract.model';
import { DatePipe } from '@angular/common';
import { Params } from '@angular/router';
import { map } from 'rxjs/operators';
import { Principal } from './principal.model';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  private endPoint: string;
  datePipe = new DatePipe('en-US');

  constructor(private httpClient: HttpClient) {
    this.endPoint = environment.dashboardEndpoint + environment.version;
  }

  private buildParams(source: any) {
    const result = {};
    for (const prop in source) {
      if (source[prop]) {
        result[prop] = source[prop];
      }
    }
    return result as Params;
  }

  getClaimsList(searchInput: ClaimSearchInput): Observable<ClaimList[]> {
    return this.httpClient.get<ClaimList[]>(`${this.endPoint}claims/search/`, {
      params: this.buildParams(searchInput),
    });
  }
  getPagedClaimsList(searchInput: ClaimSearchInput): Observable<ClaimList[]> {
    return this.httpClient.get<any>(`${this.endPoint}claims/search/paged`, {
      params: this.buildParams(searchInput),
    });
  }

  getClaimsPaidTotal(searchInput: ClaimSearchInput): Observable<number> {
    return this.httpClient.get<number>(`${this.endPoint}claims/search/paid/`, {
      params: this.buildParams(searchInput),
    });
  }

  getClaimsSubmittedTotal(searchInput: ClaimSearchInput): Observable<number> {
    return this.httpClient.get<number>(
      `${this.endPoint}claims/search/submitted/`,
      { params: this.buildParams(searchInput) }
    );
  }

  getClaimsOpenTotal(searchInput: ClaimSearchInput): Observable<number> {
    return this.httpClient.get<number>(`${this.endPoint}claims/search/open/`, {
      params: this.buildParams(searchInput),
    });
  }

  getClaimsAmountPaidTotal(searchInput: ClaimSearchInput): Observable<number> {
    return this.httpClient.get<number>(
      `${this.endPoint}claims/search/amount-paid/`,
      { params: this.buildParams(searchInput) }
    );
  }

  getClaimAvgClaimDuration(searchInput: ClaimSearchInput): Observable<number> {
    return this.httpClient.get<number>(
      `${this.endPoint}claims/search/claim-duration/`,
      { params: this.buildParams(searchInput) }
    );
  }

  getNpsScore(searchInput: ClaimSearchInput): Observable<number> {
    return this.httpClient.get<number>(`${this.endPoint}claims/search/nps/`, {
      params: this.buildParams(searchInput),
    });
  }

  getClaim(scsClaimNumber: string, dealerNumber: string): Observable<Claim> {
    return this.httpClient.get<Claim>(
      `${this.endPoint}claims/${scsClaimNumber}`,
      {
        params: { dealerNumber },
      }
    );
  }

  getClaimContract(
    scsClaimNumber: string,
    dealerNumber: string
  ): Observable<Contract> {
    return this.httpClient.get<Contract>(
      `${this.endPoint}claims/${scsClaimNumber}/contract`,
      {
        params: { dealerNumber },
      }
    );
  }

  getClaimDetails(
    scsClaimNumber: string,
    dealerNumber: string
  ): Observable<ClaimDetails[]> {
    return this.httpClient.get<ClaimDetails[]>(
      `${this.endPoint}claims/${scsClaimNumber}/details`,
      {
        params: { dealerNumber },
      }
    );
  }

  getClaimSummary(claimNumber: string): Observable<any> {
    return this.httpClient.get<ClaimSummary>(
      `${this.endPoint}claims/${claimNumber}/summary`
    );
  }

  getClaimNotes(
    scsClaimNumber: string,
    dealerNumber: string
  ): Observable<ClaimNotes[]> {
    return this.httpClient.get<ClaimNotes[]>(
      `${this.endPoint}claims/${scsClaimNumber}/notes`,
      {
        params: { dealerNumber },
      }
    );
  }

  getAgentClaimAlerts(agentNumber: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.endPoint}claims/agent/${agentNumber}/alerts`
    );
  }

  addAgentClaimAlert(agentNumber: string, dealerNumber: string) {
    return this.httpClient.post(
      `${this.endPoint}claims/agent/${agentNumber}/alerts/${dealerNumber}`,
      {}
    );
  }

  removeAgentClaimAlert(agentNumber: string, dealerNumber: string) {
    return this.httpClient.delete(
      `${this.endPoint}claims/agent/${agentNumber}/alerts/${dealerNumber}`
    );
  }

  getAgentClaimClosedAlerts(agentNumber: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.endPoint}claims/agent/${agentNumber}/closed-alerts`
    );
  }

  addAgentClaimClosedAlert(agentNumber: string, dealerNumber: string) {
    return this.httpClient.post(
      `${this.endPoint}claims/agent/${agentNumber}/closed-alerts/${dealerNumber}`,
      {}
    );
  }

  removeAgentClaimClosedAlert(agentNumber: string, dealerNumber: string) {
    return this.httpClient.delete(
      `${this.endPoint}claims/agent/${agentNumber}/closed-alerts/${dealerNumber}`
    );
  }

  vehicleInformation(vin: string): Observable<Vehicle> {
    return this.httpClient.get<Vehicle>(
      `${this.endPoint}claims/vehicle/${vin}`
    );
  }

  downloadClaimDetails(claimNumber: string) {
    return this.httpClient
      .get(`${this.endPoint}claims/details/download`, {
        params: { claimNumber },
        responseType: 'blob',
      })
      .pipe(
        map((result: Blob) => {
          return URL.createObjectURL(result);
        })
      );
  }

  downloadClaimSummary(claimNumbers: string[]) {
    return this.httpClient
      .post(`${this.endPoint}claims/summary-report`, claimNumbers, {
        responseType: 'blob',
      })
      .pipe(
        map((result: Blob) => {
          return URL.createObjectURL(result);
        })
      );
  }

  getServicerUrl(servicerNumber: string, claimNumber: string) {
    return this.httpClient.get(
      `${this.endPoint}claims/generate-servicer-url/?servicerNumber=${servicerNumber}&claimNumber=${claimNumber}`,
      { responseType: 'text' }
    );
  }

  getQualifyOverride(claimNumber: any, dealerNumber) {
    return this.httpClient.get<boolean>(
      `${this.endPoint}claims/qualify-override/${claimNumber}`,
      {
        params: { dealerNumber },
      }
    )
  }

  postOverridePayment(claimNumber: any, overridePaymentForm: any) {
    return this.httpClient.post(
      `${this.endPoint}claims/post-override/${claimNumber}`,
       overridePaymentForm
    );
  }

  rfEncodedLogin(encodedId: string) {
    return this.httpClient.post<Principal>(
      `${this.endPoint}claims/rf-encoded-login/${encodedId}`,
      {}
    );
  }
}

class PageResult<T>
{
    count: number;
    pageIndex: number;
    pageSize: number;
    items: T[];
}
