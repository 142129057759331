import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { AnnualVscSales } from '../../shared/dashboard.model';

@Component({
  selector: 'app-annual-vsc-sales',
  templateUrl: './annual-vsc-sales.component.html',
  styleUrls: ['./annual-vsc-sales.component.css'],
})
export class AnnualVscSalesComponent implements OnChanges {
  @Input() annualVscSales$: Observable<AnnualVscSales[]>;
  @Input() hideFutureMonths: boolean = false;

  data;
  chartType = 'AreaChart';
  columnNames = ['Month', 'This Year', 'Last Year'];
  options = {
    colors: ['#333333', '#01B8AA'],
    aggregationTarget: 'year',
    chartArea: {left:100, width: '85%' },
    animation: {
      startup: true,
      duration: 1000,
      easing: 'out',
    },
    legend: 'bottom',
    lineWidth: 3,
    pointSize: 2
  };

  chartImage:string = null;

  constructor() {}

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges) {
    this.chartImage = null;
    this.annualVscSales$.subscribe({
      next: (res) => {
        if (res.length > 0) {
          this.data = res.map((d) => [d.monthName, d.current, d.previous]);
          // for any month that is null, show as 0
          this.data.forEach((d) => {
            if (d[1] === null) {
              d[1] = 0;
            }
            if (d[2] === null) {
              d[2] = 0;
            }
          });

          if(this.hideFutureMonths){
            var currentMonth = new Date().getMonth();
            for (let i = currentMonth + 1; i < this.data.length; i++) {
              this.data[i][1] = null;
            }
          }
        } else {
          this.data = null;
        }
      },
    });
  }

  onReady(event) {
    this.chartImage = event.chart.getImageURI();
  }
}
