import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import {
  NewDealerSignup,
  AnnualVscSales,
  UnpaidContractTotals,
  TopSellers,
  AList,
  ClaimsList,
  OutfieldVisits,
  WorkingDaysbyYear,
  Outstanding,
  SavedQuotes,
  Products,
} from './dashboard.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Params, UrlHandlingStrategy } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private endPoint: string;

  constructor(private httpClient: HttpClient) {
    this.endPoint = environment.dashboardEndpoint + environment.version;
  }

  private buildParams(source: any) {
    const result = {};
    for (const prop in source) {
      if (source[prop] !== null && source[prop] !== undefined) {
        result[prop] = source[prop];
      }
    }
    return result as Params;
  }

  getNewDealerSignups(paramsObj: any): Observable<NewDealerSignup[]> {
    return this.httpClient.get<NewDealerSignup[]>(
      `${this.endPoint}dashboard/new-dealers`,
      { params: this.buildParams(paramsObj) }
    );
  }

  getAnnualVscSales(filters: any) {
    return this.httpClient.get<AnnualVscSales[]>(
      `${this.endPoint}dashboard/annual-vsc-sales`,
      { params: this.buildParams(filters) }
    );
  }

  getRevenue(filters: any) {
    return this.httpClient.get<number>(`${this.endPoint}dashboard/revenue`, {
      params: this.buildParams(filters),
    });
  }

  getCommission(filters: any): Observable<number> {
    return this.httpClient.get<number>(`${this.endPoint}dashboard/commission`, {
      params: this.buildParams(filters),
    });
  }

  getClaimsPaidAmount(filters: any): Observable<number> {
    return this.httpClient.get<number>(
      `${this.endPoint}dashboard/claims-paid-amount`,
      { params: this.buildParams(filters) }
    );
  }

  getContractSaleTotal(filters: any): Observable<number> {
    return this.httpClient.get<number>(
      `${this.endPoint}dashboard/contract-sale-total`,
      { params: this.buildParams(filters) }
    );
  }

  getSavedQuoteTotal(filters: any): Observable<number> {
    return this.httpClient.get<number>(
      `${this.endPoint}dashboard/saved-quote-total`,
      { params: this.buildParams(filters) }
    );
  }

  getTopSellers(filters: any): Observable<TopSellers[]> {
    return this.httpClient.get<TopSellers[]>(
      `${this.endPoint}dashboard/top-sellers`,
      { params: this.buildParams(filters) }
    );
  }

  redDealers(filters: any) {
    return this.httpClient.get<any[]>(`${this.endPoint}dashboard/red-dealers`, {
      params: this.buildParams(filters),
    });
  }

  dealerPerformance(filters: any) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/dealer-performance`,
      {
        params: this.buildParams(filters),
      }
    );
  }

  downloadDealerPerformancePDF(filters: any) {
    return this.httpClient
      .get(`${this.endPoint}dashboard/dealer-performance/download`, {
        params: this.buildParams(filters),
        responseType: 'blob',
      })
      .pipe(map((res: Blob) => URL.createObjectURL(res)));
  }

  getUnpaidContractTotals(filters: any): Observable<UnpaidContractTotals[]> {
    return this.httpClient.get<UnpaidContractTotals[]>(
      `${this.endPoint}dashboard/unpaid-contract-totals`,
      { params: this.buildParams(filters) }
    );
  }

  getAListStatus(agentNumber: string): Observable<AList> {
    return this.httpClient.get<AList>(
      `${this.endPoint}dashboard/alist-status`,
      { params: { agentNumber } }
    );
  }

  agentRank(
    startDate: string,
    endDate: string,
    agentNumber: string
  ): Observable<number> {
    return this.httpClient.get<number>(`${this.endPoint}dashboard/agent-rank`, {
      params: { startDate, endDate, agentNumber },
    });
  }

  getClaimsList(params: any): Observable<ClaimsList[]> {
    return this.httpClient.get<ClaimsList[]>(
      `${this.endPoint}dashboard/claims-list`,
      { params: this.buildParams(params) }
    );
  }

  outfieldVisits(filters: any) {
    return this.httpClient.get<OutfieldVisits>(
      `${this.endPoint}dashboard/outfield-visits`,
      {
        params: this.buildParams(filters),
      }
    );
  }

  contractSalesGoal(filters: any): Observable<number> {
    return this.httpClient.get<number>(
      `${this.endPoint}dashboard/contract-sales-goal`,
      {
        params: this.buildParams(filters),
      }
    );
  }

  workingDaysByYear(year: string): Observable<WorkingDaysbyYear[]> {
    return this.httpClient.get<WorkingDaysbyYear[]>(
      `${this.endPoint}dashboard/working-days`,
      {
        params: { year },
      }
    );
  }

  unpaidContracts(params: Params) {
    return this.httpClient.get<Outstanding[]>(
      `${this.endPoint}dashboard/unpaid-contracts`,
      {
        params: params as Params,
      }
    );
  }

  downloadUnpaidContracts(params) {
    return this.httpClient.get<Blob>(
      `${this.endPoint}dashboard/unpaid-contracts/download`,
      {
        params: params,
        responseType: 'blob' as 'json',
      }
    );
  }

  savedQuotes(params: Params) {
    return this.httpClient.get<SavedQuotes[]>(
      `${this.endPoint}dashboard/saved-quotes`,
      {
        params: params as Params,
      }
    );
  }

  downloadSavedQuotes(params) {
    return this.httpClient.get<Blob>(
      `${this.endPoint}dashboard/saved-quotes/download`,
      {
        params: params,
        responseType: 'blob' as 'json',
      }
    );
  }

  getProductSalesReport(params: Params) {
    return this.httpClient.get<Products[]>(
      `${this.endPoint}dashboard/product-sales-report`,
      {
        params: params,
      }
    );
  }

  downloadProductSalesReport(params: Params) {
    return this.httpClient.get<Blob>(
      `${this.endPoint}dashboard/product-sales-report/download`,
      {
        params: params,
        responseType: 'blob' as 'json',
      }
    );
  }

  productSales(payload: {
    startDate: string;
    endDate: string;
    scsNumber?: string;
    productTypeId?: number;
  }) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/product-sales`,
      {
        params: payload as any,
      }
    );
  }

  contractList(payload: {
    dealerNumber: string;
    startDate: string;
    endDate: string;
  }) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/contract-list`,
      {
        params: payload as any,
      }
    );
  }

  userActivity(params) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/user-activity`,
      {
        params: params,
      }
    );
  }

  downloadUserActivity(params) {
    return this.httpClient.get<Blob>(
      `${this.endPoint}dashboard/user-activity/download`,
      {
        params: params,
        responseType: 'blob' as 'json',
      }
    );
  }

  getSaleOverview(dealerNumber: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}dashboard/sales-overview`,
      {
        params: { dealerNumber },
      }
    );
  }

  getDealerOnboardingStats(dealerNumber: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}dashboard/dealer-onboarding`,
      {
        params: { dealerNumber },
      }
    );
  }

  getDealerOnboardingStatus(dealerNumber: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}dashboard/dealer-onboarding/status`,
      {
        params: { dealerNumber },
      }
    );
  }

  postDealerOnboardingNote(dealerNumber: string, payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dashboard/dealer-onboarding/note`,
      payload,
      {
        params: { dealerNumber },
      }
    );
  }

  getAgentOpportunities(filters) {
    return this.httpClient.get(
      `${this.endPoint}dashboard/agent-opportunities`,
      {
        params: this.buildParams(filters),
      }
    );
  }

  getOpportunity(opportunityID: string) {
    return this.httpClient.get(
      `${this.endPoint}dashboard/agent-opportunity/${opportunityID}`
    );
  }

  getOpportunityNotes(opportunityID: string) {
    return this.httpClient.get(
      `${this.endPoint}dashboard/agent-opportunity/${opportunityID}/notes`
    );
  }

  postOpportunityNote(opportunityID: string, payload) {
    return this.httpClient.post(
      `${this.endPoint}dashboard/agent-opportunity/${opportunityID}/note`,
      payload
    );
  }

  getYtdSalesTotal(params) {
    return this.httpClient.get(`${this.endPoint}dashboard/ytd-sales-total`, {
      params,
    });
  }

  // filters
  filtersDealersByScsNumber(
    scsNumber: string,
    onlyActiveDealers: boolean = false
  ) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/filters/dealers-by-scs-number`,
      {
        params: { scsNumber, onlyActiveDealers: onlyActiveDealers.toString() },
      }
    );
  }

  filtersDealersByAgentNumber(
    agentNumber,
    dealerFilter,
    includeDFI = true,
    includeAPC = true,
    includeCancelled = true
  ) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/filters/dealers-by-agent-number`,
      {
        params: this.buildParams({
          agentNumber,
          dealerFilter,
          includeDFI,
          includeAPC,
          includeCancelled,
        }),
      }
    );
  }

  filtersAgents(agentNumber: string, includeCancelled = true) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/filters/agents`,
      {
        params: this.buildParams({ agentNumber, includeCancelled }),
      }
    );
  }

  filterAgentsDealers(agentNumber: string, onlyActiveDealers: boolean = false) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/filters/agents/dealers`,
      {
        params: {
          agentNumber,
          onlyActiveDealers: onlyActiveDealers.toString(),
        },
      }
    );
  }

  filtersGetSelectedDealers(dealerNumber) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/filters/selected-dealers`,
      {
        params: this.buildParams({ dealerNumber }),
      }
    );
  }

  filtersProductTypes() {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dashboard/filters/product-types`
    );
  }
}
