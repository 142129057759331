import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrincipalService } from 'src/shared/principal.service';
import { DealerService } from 'src/shared/dealer.service';

@Component({
  selector: 'app-dealer-survey-dialog',
  templateUrl: './dealer-survey-dialog.component.html',
  styleUrls: ['./dealer-survey-dialog.component.css'],
})
export class DealerSurveyDialogComponent {
  surveyForm: FormGroup;

  dialogChanger: string;
  dialogClosed = false;
  urlPath: string;
  constructor(
    public dialogRef: MatDialogRef<DealerSurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private principal: PrincipalService,
    private dealerService: DealerService,
  ) {
    this.dialogChanger = 'None';

    this.surveyForm = this.fb.group({
      DealerSurveyId: [this.data.dealerSurveyId ?? 0],
      UserId: [this.principal.id],
      FeedbackResponse: [null],
      Testimonial: [null],
    });

    if (
      this.principal.id != null &&
      (this.principal.hasUserViewAccess(['Dealer']) ||
        this.principal.hasUserViewAccess(['Agent']))
    ) {
      this.surveyForm.patchValue({
        UserId: this.principal.id,
      });
    }
  }

  onPositiveFeedback() {
    this.dialogChanger = 'postiveFeedback';
    this.surveyForm.patchValue({
      FeedbackResponse: 'Positive',
    });
    this.dealerService.updateDealerSurvey(this.surveyForm.value).subscribe({
      next: (result: any) => {
        this.surveyForm.patchValue({
          DealerSurveyId: result.dealerSurveyId,
          Testimonial: result.testimonial,
          FeedbackResponse: result.feedbackResponse,
        });
      },
      error: (err) => {},
    });
  }

  onNegativeFeedback() {
    this.dialogChanger = 'negativeFeedback';
    this.surveyForm.patchValue({
      FeedbackResponse: 'Negative',
    });
    this.dealerService.updateDealerSurvey(this.surveyForm.value).subscribe({
      next: (result: any) => {
        this.surveyForm.patchValue({
          DealerSurveyId: result.dealerSurveyId,
          Testimonial: result.testimonial,
          FeedbackResponse: result.feedbackResponse,
        });
      },
      error: (err) => {},
    });
  }

  popoutChat() {
    window.open(
      'https://home-c54.nice-incontact.com/inContact/ChatClient/ChatClient.aspx?poc=85ff4c00-80ed-40f6-a2c4-940b29873980&bu=4604375&P1=Name&P2=ContactType&P3=VINorContractNumber&P4=555-555-5555',
      'icPatronChatWin',
      'location = no, height = 630, menubar = no, status = no, width = 410',
      true,
    );
  }

  onDialogClose(): void {
    if (this.surveyForm.value.FeedbackResponse == null) {
      this.surveyForm.patchValue({
        FeedbackResponse: 'Declined',
      });
    }
    this.dealerService.updateDealerSurvey(this.surveyForm.value).subscribe({
      next: (result: any) => {
        this.surveyForm.patchValue({
          DealerSurveyId: result.dealerSurveyId,
          Testimonial: result.testimonial,
          FeedbackResponse: result.feedbackResponse,
        });
        this.dialogRef.close(false);
      },
      error: (err) => {
        this.dialogRef.close(false);
      },
    });
  }

  submitTestimonial() {
    this.dealerService.updateDealerSurvey(this.surveyForm.value).subscribe({
      next: (result) => {
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.dialogRef.close(false);
      },
    });
    return;
  }

  isTimeoutTriggered() {
    return this.data.isTimeoutTriggered;
  }
}
