import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QueryManagerService {
  private paramMapKey = 'query_param_key';
  private qPLoaded = false;

  constructor(private router: Router) {
    // Flush - Temp as we are no longer persisting past session
    localStorage.removeItem(this.paramMapKey);
    sessionStorage.removeItem(this.paramMapKey);
  }

  initialize() {
    if (!this.qPLoaded) {
      this.qPLoaded = true;
      const queryMapString = localStorage.getItem(this.paramMapKey);
      if (queryMapString) {
        const parsed = JSON.parse(queryMapString) as Params;
        this.router.navigate([], {
          queryParams: parsed,
          queryParamsHandling: 'merge',
        });
      }
    }
  }

  mergeQueryParams(context: ActivatedRoute, params: any, clearToken = true) {
    // exists to always clear out token
    if (clearToken) params.token = null;
    this.router
      .navigate([], {
        relativeTo: context,
        queryParams: params,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      })
      .then((fulfilled) => {
        // if (fulfilled) {
        //   this.persistQueryParams(context);
        // }
      });
  }

  clearAndSetQueryParams(context: ActivatedRoute, params: any) {
    this.router.navigate([], {
      relativeTo: context,
      queryParams: params,
      queryParamsHandling: '',
      replaceUrl: true,
    });
  }

  clearQueryParams(context: ActivatedRoute, params: any) {}

  private persistQueryParams(activatedRoute: ActivatedRoute) {
    const map = activatedRoute.snapshot.queryParams;
    if (!environment.production) {
      console.log('Setting Query Params: ', map);
    }
    localStorage.setItem(this.paramMapKey, JSON.stringify(map));
  }
}
