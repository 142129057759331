import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { SettingsService } from '../../shared/settings.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  environment: any;
  logoPath: any;
  year = new Date().getFullYear();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private service: SettingsService
    ) {}

  ngOnInit() {
    this.service.getUserLogo().subscribe({
      next: (res) => {
        if(res) {
          this.logoPath = environment.toolsEndpoint + res.FilePath;
          console.log('res', res);
        } else {
          this.logoPath = '../../assets/alpha-logo-white.png';
        }
      },
    });
  }
}
