<div class="container">
  <h1>Claim Details</h1>
  <div
    *ngIf="contract && claim"
    class="margin-top-lg"
    fxLayout="row wrap"
    fxLayout.lt-sm="column"
    fxLayoutGap="20px"
  >
    <div fxFlex="calc(25% - 20px)">
      <div class="fancy-title margin-bottom-xs">
        <h3 class="no-bottom-padding">customer</h3>
      </div>
      <h3>
        {{ contract.firstName | titlecase }} {{ contract.lastName | titlecase }}
      </h3>
      <p>
        {{ contract.address1 | titlecase }} {{ contract.address2 | titlecase
        }}<br />
        {{ contract.city | titlecase }}, {{ contract.state | titlecase }}
        {{ contract.zipCode }}<br />
      </p>
      <p>
        <strong>Claim Number: </strong
        ><span class="pull-right">{{ claim.claimNumber }}</span
        ><br /><strong>Repair Number:</strong
        ><span class="pull-right">{{ claim.repairOrderNumber }}</span
        ><br />
        <strong>Days Since Sale: </strong
        ><span class="pull-right">{{
          contract.daysSinceContactSale | number
        }}</span
        ><br />
        <strong>Claim Date: </strong
        ><span class="pull-right">{{
          claim.dateLossOccurred | date: 'M/d/yyyy'
        }}</span
        ><br />
        <strong>Claim Odometer: </strong
        ><span class="pull-right">{{
          claim.odometerAtTimeOfLoss | number
        }}</span
        ><br />
        <strong>Claim Activity: </strong
        ><span class="pull-right claim-activity">{{
          deniedExceptInspection ? 'Closed - Not Covered' : claim.claimStatus
        }}</span>
      </p>
    </div>
    <div fxFlex="calc(50% - 20px)">
      <div class="fancy-title margin-bottom-xs">
        <h3 class="no-bottom-padding">contract</h3>
      </div>
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="20px">
        <div fxFlex>
          <h3>
            <a
              class="alpha-blue"
              [href]="zoomUrl + 'contracts/' + contract.contractNumber"
            >
              {{ contract.contractNumber }}
            </a>
          </h3>
          <strong>Sale Date: </strong
          >{{ contract.contractSaleDate | date: 'M/d/yyyy' }}<br />
          <strong>Effective Date: </strong
          >{{ contract.contractEffective | date: 'M/d/yyyy' }} -
          {{ contractExpirationDate }}<br />
          <strong>Effective Odometer: </strong
          >{{ contract.contractEffectiveOdometer | number }} -
          {{ contractExpirationOdo }}<br />
        </div>
        <div fxFlex>
          <h3>
            {{
              contract.displayProgram
                ? contract.displayProgram
                : contract.program
            }}
          </h3>
          {{ contract.displayPlan ? contract.displayPlan : contract.plan
          }}<br />
          <strong>Term: </strong>{{ contract.contractTerm }}/{{ contractMileage
          }}<br />
          <strong>Deductible: </strong
          >{{ contract.deductibleAmount | currency }}
        </div>
        <div fxFlex="100%" class="margin-top-sm">
          <strong>Repair Facility: </strong>
          {{ claim.repairFacility.payeeDescription }}
          <br />
          <strong>Address: </strong>

          {{ claim.repairFacility.payeeAddress1 | titlecase }}
          {{ claim.repairFacility.payeeAddress2 | titlecase }}
          <br />
          {{ claim.repairFacility.payeeCity | titlecase }},
          {{ claim.repairFacility.payeeState | titlecase }}
          {{ claim.repairFacility.payeeZipCode | titlecase }}
          <br />
          <span *ngIf="claimSummary">
            <strong>Phone: </strong>
            {{ claim.repairFacility.payeePhone | slice: 0:3 }}-
            {{ claim.repairFacility.payeePhone | slice: 3:6 }}-
            {{ claim.repairFacility.payeePhone | slice: 6:10 }}
          </span>
        </div>
      </div>
    </div>
    <div fxFlex="calc(25% - 20px)">
      <div class="fancy-title margin-bottom-xs">
        <h3 class="no-bottom-padding">vehicle</h3>
      </div>
      <div *ngIf="vehicle">
        <div *ngIf="vehicle.image" class="content-panel">
          <img width="100%" src="{{ vehicle.image }}" />
        </div>
        <div *ngIf="!vehicle.image">
          <mat-icon style="font-size: 140px">no_photography</mat-icon>
        </div>
        <h4 style="margin-bottom: 0px">
          {{ vehicle.modelYear }} {{ vehicle.make | titlecase }}
          {{ vehicle.model | titlecase }}
        </h4>
        <div><strong>VIN: </strong>{{ contract.vehicleIdNumber }}</div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="1em" fxLayout.gt-xs="row">
      <div
        fxFlex.lt-sm
        *ngIf="contract.status == 'A' || contract.status == 'P'"
      >
        <a
          [disabled]="this.contractDownloadDisabled"
          class="decoration-none white"
          mat-raised-button
          color="primary"
          (click)="downloadContractPdf()"
        >
          <mat-icon>cloud_download</mat-icon> DOWNLOAD CONTRACT
        </a>
      </div>
      <div fxFlex>
        <a
          *ngIf="showDownload"
          mat-raised-button
          class="decoration-none white"
          target="_blank"
          [href]="href"
          [download]="filename"
          color="primary"
          (click)="download()"
        >
          <mat-icon>get_app</mat-icon>
          CLAIM DETAILS
        </a>
      </div>
      <div fxflex>
        <a
          mat-raised-button
          id="chat-with-us"
          class="decoration-none white"
          color="primary"
          (click)="onChatClick()"
        >
          <mat-icon>forum</mat-icon>
          CHAT WITH US
        </a>
      </div>
      <div fxflex fxFlexAlign="row">
        <button
          fxFlex
          mat-raised-button
          class="decoration-none white"
          color="primary"
          (click)="onOverrideClick()"
          [disabled]="!isOverride"
          matTooltip="Override a claim by using your premium reserve. If greyed out, the claim does not qualify to be overridden."
        >
        OVERRIDE REQUEST
        <mat-spinner
          style="align-self: center;"
          fxFlex
          *ngIf="isOverrideLoading"
          class="spinner"
          [diameter]="20"
          [strokeWidth]="2"
        ></mat-spinner>
        </button>
      </div>
    </div>
  </div>
  <br />
  <h2>Claim Jobs</h2>
  <div style="overflow-x: auto">
    <table
      mat-table
      class="table-border full-width margin-bottom-lg"
      [dataSource]="claimDataSource"
    >
      <ng-container matColumnDef="category">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip=""
          matTooltipPosition="above"
        >
          Category
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.category }}
        </td>
        <td mat-footer-cell *matFooterCellDef>Totals</td>
      </ng-container>
      <ng-container matColumnDef="lossDescription">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Details of items related to specific Job Numbers."
          matTooltipPosition="above"
        >
          Loss Description
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.detailDescription }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="part">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip=""
          matTooltipPosition="above"
        >
          Detail
        </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.detailType === 'P'">
            {{ row.partDescription }}
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="requestedAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Amount requested by the repair facility for the repair requested."
          matTooltipPosition="above"
        >
          Requested Amount
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container [ngSwitch]="row.detailType">
            <div *ngSwitchCase="'L'">
              {{ row.requestedQuantity | number: '.2' }} Hours @
              {{ row.requestedUnitCost | currency }}/hour
            </div>
            <div *ngSwitchCase="'P'">
              {{ row.requestedQuantity | number: '.2' }} Qty @
              {{ row.requestedUnitCost | currency }}
            </div>
            <div *ngSwitchDefault>
              {{ row.externalTotalAmount | currency }}
            </div>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ requestedTotal() | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payableAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Amount payable to the repair facility after claim adjustment."
          matTooltipPosition="above"
        >
          Payable Amount
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container [ngSwitch]="row.detailType">
            <div *ngSwitchCase="'L'">
              {{ row.authorizedQuantity | number: '.2' }}
              Hours @ {{ row.authorizedUnitCost | currency }}/hour
            </div>
            <div *ngSwitchCase="'P'">
              {{ row.authorizedQuantity | number: '.2' }}
              Qty @ {{ row.authorizedUnitCost | currency }}
            </div>
            <div *ngSwitchDefault>
              {{ row.amountPaid - row.taxAmount | currency }}
            </div>
          </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ authorizedTotal() | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tax">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Applicable where required by state law."
          matTooltipPosition="above"
        >
          Tax
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.taxAmount | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef>{{ taxTotal() | currency }}</td>
      </ng-container>
      <ng-container matColumnDef="totalPayable">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Sum of Payable Amount and Tax."
          matTooltipPosition="above"
        >
          Total Payable
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.amountPaid + row.taxAmount | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ totalPayable() | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Indicates progress of claim."
          matTooltipPosition="above"
        >
          Status
        </th>
        <td mat-cell *matCellDef="let row">
          <div *ngIf="row.status != 'Denied'">
            {{ row.status }}
          </div>
          <div *ngIf="row.status == 'Denied'">Ineligible</div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="payee">
        <th
          mat-header-cell
          *matHeaderCellDef
          matTooltip="Entity requesting payment."
          matTooltipPosition="above"
        >
          Payee
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.payee }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="claimColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: claimColumns"
        [ngClass]="{ gray: row.color == 2, blue: row.color == 0 }"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="claimColumns"></tr>
    </table>
  </div>
  <div *ngIf="showNotes">
    <h2>Claim Notes</h2>
    <div class="table-border">
      <table
        class="table-striped"
        mat-table
        [dataSource]="noteDataSource"
        matSort
      >
        <ng-container matColumnDef="noteDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let row">
            {{ row.noteDate | date: 'M/d/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
          <td mat-cell *matCellDef="let row">
            {{ row.userId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
          <td mat-cell *matCellDef="let row">
            {{ row.note }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="noteColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: noteColumns"></tr>
      </table>
      <mat-paginator
        #paginator
        [length]="100"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
      ></mat-paginator>
    </div>
  </div>
</div>
