import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router'; 
import { PrincipalService } from '../principal.service';

@Component({
  selector: 'app-repair-facility',
  templateUrl: './repair-facility.component.html',
  styleUrls: ['./repair-facility.component.css']
})
export class RepairFacilityComponent implements OnInit {

  constructor(
    private service: ClaimService,
    private route: ActivatedRoute, 
    private principal: PrincipalService
  ) { }

  servicerUrl;
  routeId; 

  ngOnInit(): void {
    this.routeId = this.route.snapshot.params['id'];
    
    if (this.routeId) {
      this.principal.clear();
      this.service.rfEncodedLogin(this.routeId).subscribe({
        next: (result) => {
          console.log(result);
          this.principal.set(result);
        },
        error: (err) => {
          console.log("Error:" + err);
        }
      });
    }
  }
}
