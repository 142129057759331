import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Credentials, Token } from './authentication.model';
import { Principal } from './principal.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.zoomEndpoint + environment.version;
  }

  authenticateToken(payload: Token, uuid: string) {
    const headers = {};
    if (uuid) {
      headers['Zoom-UUID'] = uuid;
    }
    return this.http.post<Principal>(
      `${this.endpoint}authentication`,
      payload,
      {
        headers: headers,
      }
    );
  }

  login(payload: Credentials, uuid: string) {
    const headers = {};
    if (uuid) {
      headers['Zoom-UUID'] = uuid;
    }
    return this.http.post<Principal>(
      `${this.endpoint}authentication/login`,
      payload,
      {
        headers: headers,
      }
    );
  }

  oneTimeCode(payload: any) {
    return this.http.post<Principal>(
      `${this.endpoint}authentication/one-time-code`,
      payload,
      {
        observe: 'response',
      }
    );
  }

  sendCode(payload: any) {
    return this.http.post<Principal>(
      `${this.endpoint}authentication/generate-code`,
      payload
    );
  }

  impersonate(actorId: string, uuid?: string) {
    const headers = {};
    if (uuid) {
      headers['Zoom-UUID'] = uuid;
    }
    return this.http.post<Principal>(
      `${this.endpoint}authentication/impersonate`,
      {
        actorId,
      },
      {
        headers: headers,
      }
    );
  }

  impersonateUsers(userViewAccessType: string, filter: string = '') {
    const query = {} as any;
    if (filter) {
      query.filter = filter;
    }
    return this.http.get<any[]>(
      `${this.endpoint}authentication/impersonate/users-by-access-type/${userViewAccessType}`, {
      params: query
    }
    );
  }

  forgotPassword(username: any) {
    return this.http.get<any>(`${this.endpoint}authentication/forgot-password`, {
      params: { username },
      responseType: 'text' as 'json'
    });
  }


  validateToken(token) {
    return this.http.get(`${this.endpoint}authentication/validate-password-token`, {
      params: { token }
    });
  }

  updateForgottenPassword(payload: any) {
    return this.http.post<any>(`${this.endpoint}authentication/forgot-password`, payload);
  }

  // ACCOUNT
  accountDetails() {
    return this.http.get<any>(
      `${this.endpoint}account/`
    );
  }

  updateDetails(payload: any) {
    return this.http.post(
      `${this.endpoint}account`,
      payload
    );
  }

  updatePassword(payload: any) {
    return this.http.post(
      `${this.endpoint}account/password`,
      payload
    );
  }

  twoFactor(payload: any) {
    return this.http.post(
      `${this.endpoint}account/two-factor`,
      payload
    );
  }

  validateTwoFactor(payload: any) {
    return this.http.post(
      `${this.endpoint}account/two-factor/validate`,
      payload
    );
  }

  smsTwoFactor(payload: any) {
    return this.http.post(
      `${this.endpoint}account/sms-two-factor`,
      payload
    );
  }

  twoFactorEnable() {
    return this.http.post(`${this.endpoint}account/two-factor/enable`, null);
  }

  twoFactorDisable() {
    return this.http.post(`${this.endpoint}account/two-factor/disable`, null);
  }

  validateSmsTwoFactor(payload: any) {
    return this.http.post(
      `${this.endpoint}account/sms-two-factor/validate`,
      payload
    );
  }

  usersByScsNumber(scsNumber: string) {
    return this.http.get<any[]>(`${this.endpoint}users/by-scs-number`, {
      params: {
        scsNumber
      }
    });
  }
}
