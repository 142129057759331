import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClaimService } from '../../claim.service';
import { ActivatedRoute, Router} from '@angular/router';
import {OverrideDialogComponent} from '../override-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrincipalService } from 'src/shared/principal.service';

@Component({
  selector: 'app-override-dialog-confirmation',
  templateUrl: './override-dialog-confirmation.component.html',
  styleUrls: ['./override-dialog-confirmation.component.css']
})
export class OverrideDialogConfirmationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OverrideDialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private claimService: ClaimService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private principal: PrincipalService
    ) { }

  ngOnInit(): void {
    console.log(this.data)
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    const claimNumber = this.router.url.split('/')[2];
    console.log(this.data);
    const overridePostBody = {
      "EmailAddressedTo": this.principal.firstName ?? "Customer",
      "Name": this.data.formData.name,
      "Title": this.data.formData.title,
      "PaymentRecipient": this.data.formData.paymentRecipient,
      "PayeeName": this.data.formData.payeeName,
      "PaymentMethod": this.data.formData.paymentMethod,
      "AccountName" : this.data.formData.accountName,
      "AccountType" : this.data.formData.accountType,
      "RoutingNumber" : this.data.formData.routingNumber,
      "AccountNumber" : this.data.formData.accountNumber,
      "PaymentAmount": this.data.formData.paymentAmount,
      "AdditionalComments" : this.data.formData.additionalComments,
    };
    console.log(overridePostBody);
    this.claimService.postOverridePayment(claimNumber, overridePostBody).subscribe((res) => {
      console.log(res);
    });
    this.dialog.closeAll();
  }
}
