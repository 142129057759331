<mat-form-field class="dealer-chip-list" appearance="outline">
  <mat-label *ngIf="label && dealersLoaded">{{ label }}</mat-label>
  <mat-label *ngIf="!dealersLoaded"
             fxLayout="row"
             fxLayoutAlign="space-between">
    <span fxFlex="110px">Loading dealers...</span>
    <mat-spinner fxFlex class="spinner" diameter="20"></mat-spinner>
  </mat-label>
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let dealer of dealers" (removed)="remove(dealer)">
      {{ dealer.dealerNumber }} {{ dealer.dealerName }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input #dealerInput
           placeholder="Start typing to filter..."
           [formControl]="inputFilter"
           [matAutocomplete]="matAutocomplete"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="add($event)" />
  </mat-chip-list>
  <mat-autocomplete #matAutocomplete="matAutocomplete"
                    [displayWith]="displayWith"
                    (optionSelected)="selected($event)"
                    autoActiveFirstOption="true">
    <mat-option *ngFor="let opt of accessFilter | async" [value]="opt">
      {{ opt.dealerNumber }} - {{ opt.dealerName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
