<div class="container">
  <h1>User Impersonation</h1>
  <div class="content-panel">
    <form (submit)="impersonateUser()">
      <mat-form-field appearance="outline">
        <mat-label>User View Access Type</mat-label>
        <mat-select [formControl]="userViewAccessType">
          <mat-option [value]="4">Dealers</mat-option>
          <mat-option [value]="5">Sub Agents</mat-option>
          <mat-option [value]="3">Agents</mat-option>
          <mat-option [value]="2">MGAs</mat-option>
          <mat-option [value]="7">DealFi</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>User</mat-label>
        <input
          type="text"
          matInput
          placeholder="Start entering characters to filter..."
          [formControl]="inputFilter"
          [matAutocomplete]="matAutocomplete"
        />
        <mat-autocomplete
          #matAutocomplete="matAutocomplete"
          [displayWith]="displayWith"
          (optionSelected)="optionSelected()"
          autoActiveFirstOption="true"
        >
          <mat-option *ngFor="let opt of userList$ | async" [value]="opt">
            {{ scsNumber(opt) }} - {{ opt.userName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button mat-raised-button type="submit" color="primary">
        Begin Impersonation
      </button>
      <span style="margin-left: 20px"
        >* Note, you will have to log out after impersonating to return to your
        own permissions.</span
      >
    </form>
  </div>
</div>
