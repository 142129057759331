import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-agent-filtered-multi-select',
  templateUrl: './agent-filtered-multi-select.component.html',
  styleUrls: ['./agent-filtered-multi-select.component.css'],
})
export class AgentFilteredMultiselectComponent implements OnInit {
  @ViewChild('agentSearch') searchTextBox: ElementRef;

  @Input()
  agents: FormControl;
  @Input()
  selectedAgents: FormControl;

  searchTextboxControl = new FormControl();
  selectedValues = [];
  data = [];
  filteredOptions: Observable<any[]>;

  constructor() {}

  ngOnInit() {
    this.data = [];
    if (this.agents.value) this.data = this.agents.value;

    this.agents.valueChanges.subscribe({
      next: (res) => {
        if (res) {
          this.data = res;
          this.filteredOptions = this._filter('');
        }
      },
    });

    this.selectedAgents.valueChanges.subscribe({
      next: (res) => {
        if (res) {
          this.filteredOptions = this._filter('');
        }
      },
    });

    this.searchTextboxControl.valueChanges.subscribe((val) => {
      this.filteredOptions = this._filter(val);
    });

    console.log('in agent select', this.agents);
  }

  private _filter(name: string): Observable<any[]> {
    if (name === '') {
      return of(this.data);
    }

    const filterValue = name.toLowerCase();
    this.setSelectedValues();
    let filteredList = this.data.filter(
      (option) =>
        option.agentName.toLowerCase().includes(filterValue) ||
        option.agentNumber.toLowerCase().includes(filterValue)
    );
    return of(filteredList);
  }

  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
  }

  openedChange(e) {
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  setSelectedValues() {
    if (this.selectedAgents.value && this.selectedAgents.value.length > 0) {
      this.selectedAgents.value.forEach((val) => {
        if (this.selectedValues.indexOf(val) == -1) {
          this.selectedValues.push(val);
        }
      });
    }
  }
}
