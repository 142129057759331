import { Component, OnInit, forwardRef, ViewChild, Input } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { any } from 'underscore';

@Component({
  selector: 'app-signature-field',
  templateUrl: './signature-field.component.html',
  styleUrls: ['./signature-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureFieldComponent),
      multi: true,
    },
  ],
})
export class SignatureFieldComponent implements ControlValueAccessor, OnInit {
  @ViewChild(SignaturePad, { static: false }) public signaturePad: SignaturePad;

  @Input('existingSignature')
  existingSignature: any;

  onChange: (arg0: any) => any;
  options: any = {
    canvasWidth: 750,
    canvasHeight: 250,
  };
  handset = this.breakPointObserver.observe(Breakpoints.Handset);
  tablet = this.breakPointObserver.observe(Breakpoints.Tablet);
  web = this.breakPointObserver.observe(Breakpoints.Web);
  constructor(private breakPointObserver: BreakpointObserver) {}
  ngOnInit() {
    this.handset.subscribe((result) => {
      if (result.matches) {
        this.options = { canvasWidth: 250, canvasHeight: 100 };
      }
    });
    this.tablet.subscribe((result) => {
      if (result.matches) {
        this.options = { canvasWidth: 550, canvasHeight: 200 };
      }
    });
    this.web.subscribe((result) => {
      if (result.matches) {
        this.options = { canvasWidth: 750, canvasHeight: 250 };
      }
    });
  }
  ngAfterViewInit(): void {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
    this.signature = this.signaturePad.onEndEvent.subscribe(() =>
      this.onChange(this.signaturePad.toDataURL('image/png', 0.5).split(',')[1])
    );
    if (this.existingSignature) {
      this.signaturePad.fromDataURL(
        'data:image/png;base64,' + this.existingSignature
      );
      this.signature = 'data:image/png;base64,' + this.existingSignature;
    }
  }

  // tslint:disable-next-line: variable-name
  public _signature: any = null;

  // tslint:disable-next-line: ban-types
  public propagateChange: Function = null;

  get signature(): any {
    return this._signature;
  }

  set signature(value: any) {
    this._signature = value;
    // this.propagateChange(this.signature);
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this._signature = value;
    this.signaturePad.fromDataURL(this.signature);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {
    // no-op
  }

  // tslint:disable-next-line: use-life-cycle-interface

  public drawBegin(): void {}

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL('image/png', 0.5);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
  }
}
