<mat-form-field appearance="outline">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    type="text"
    matInput
    placeholder="Start entering text to filter..."
    [formControl]="inputFilter"
    [matAutocomplete]="matAutocomplete"
    [required]="required" />
  <mat-icon
    matSuffix
    (click)="clearText()"
    *ngIf="(autoClearInput && !inputIsClear) || !autoClearInput"
    >close</mat-icon
  >
  <mat-autocomplete
    #matAutocomplete="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="optionSelected()"
    autoActiveFirstOption="true">
    <mat-option *ngFor="let opt of scsAccessFilter | async" [value]="opt">
      {{ opt.scsNumber }} - {{ opt.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
