import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { stringify } from 'querystring';
import { isNull } from 'underscore';

export class ZoomValidators {
  static emailAddress(control: AbstractControl): ValidationErrors | null {
    const technicalValidation = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const defactoValidation = new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/
    );
    let email = control.value;
    if (
      (email || email == '') &&
      (!technicalValidation.test(email) ||
        !defactoValidation.test(email) ||
        ZoomValidators.emailBlackList(email))
    ) {
      return {
        emailAddress: {
          found: email,
        },
      };
    }
    return null;
  }

  private static emailBlackList(email: string): boolean {
    let domainBlackList = [
      'none',
      'test',
      'noemail',
      'noone',
      '123',
      'sales',
      'somewhere',
      'noemial',
      'emmail',
      'abc',
      'email',
      'non',
      'na',
      'testing',
      'eamil',
      'nomail',
      'no',
      'noe',
      'a',
      'b',
      'n',
      'e',
      'dealer',
      'gmai',
      'aaa',
      'ddd',
      'example',
    ];
    let pageType = ['.com', '.net', '.org'];
    let exists = false;

    if (email) {
      domainBlackList.forEach((domain) => {
        pageType.forEach((pageType) => {
          let test = '@' + domain + pageType;
          if (email.includes(test)) {
            exists = true;
            return;
          }
        });
      });
    }

    return exists;
  }

  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    const regEx = new RegExp(/^\d{10}$/);
    let phone = control.value;
    if (phone && !regEx.test(phone)) {
      return {
        phone: {
          found: phone,
        },
      };
    }
    return null;
  }

  static areSame(target: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let controlValue = control.value;
      let targetValue = target.value;
      if (controlValue && targetValue && controlValue !== targetValue) {
        return {
          same: 'Passwords are not the same',
        };
      }
      return null;
    };
  }

  static firstOfMonth(): Date {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  }

  // This is designed to convert 'flat' formGroup
  // turning dates into string
  static toSafeParams(uiData: any) {
    let params = {};
    for (const key in uiData) {
      if (!Object.prototype.hasOwnProperty.call(uiData, key)) {
        continue;
      }
      const element = uiData[key];
      if (element instanceof Date) {
        params[key] = (element as Date).toJSON();
      } else {
        params[key] = element;
      }
    }
    return params;
  }

  static downloadBlob(data: Blob, download: string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(data);
    a.href = objectUrl;
    a.download = download;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  static greaterThan(min: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value <= min) {
        return { lessThanMin: true, min: min };
      }
      return null;
    };
  }
}
