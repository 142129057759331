import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuickSignService } from '../../quick-sign.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { QuicksignServicePlusTooltipDialogComponent } from '../quicksign-service-plus-tooltip-dialog/quicksign-service-plus-tooltip-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-quicksign-service-plus',
  templateUrl: './quicksign-service-plus.component.html',
  styleUrls: ['./quicksign-service-plus.component.css'],
})
export class QuicksignServicePlusComponent implements OnInit {
  @Output() hasServiceCenterChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() checkBoxChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() preSelectedOptions: any[] = [];
  @Input() showLogo: boolean = true;
  @Input() showBreakdown: boolean;
  @Input() enableAll: boolean = false;
  @Input() hasRetroOrReinsurance: boolean = false;
  @Input() showServiceCenterQuestion: boolean = true;
  @Input() initiateForm: FormGroup = new FormGroup({
    isFranchise: new FormControl(false),
  });
  @Input() preselectedServiceCenter: boolean = false;
  selectedOptions: any[] = [];
  servicePlusOptions: any[] = [];
  hasServiceCenter = new FormControl(false);
  checkboxGroupControl = new FormControl();
  partSourcingIds = [1, 2];
  constructor(private service: QuickSignService, private dialog: MatDialog) {
    this.service.getServicePlusOptions().subscribe({
      next: (res: any[]) => {
        if (this.preSelectedOptions.length > 0) {
          this.selectedOptions = this.preSelectedOptions.map(
            (d) => d.servicePlusId
          );
        }
        res.forEach((option) => {
          this.isDisabledChecked(option);
          this.servicePlusOptions.push({
            checked: this.preSelectedOptions.some(
              (d) => d.servicePlusId == option.servicePlusId
            ),
            servicePlusId: option.servicePlusId,
            name: option.name,
            tooltipHtml: option.tooltipHtml,
            defaultDisabled: option.defaultDisabled,
          });
        });

        // Emit the checkBoxChange event after initializing the arrays
        this.checkBoxChange.emit(this.selectedOptions);
      },
    });
  }

  ngOnInit(): void {
    if (this.preselectedServiceCenter) {
      this.hasServiceCenter.setValue(true);
    }
    this.hasServiceCenter.valueChanges.subscribe((newValue) => {
      this.servicePlusOptions.forEach((option) => {
        this.isDisabledChecked(option);
      });

      // Emit the checkBoxChange event with selected options
      this.checkBoxChange.emit(this.selectedOptions);
      // Emit the hasServiceCenterChange event
      this.hasServiceCenterChange.emit(newValue);
    });

    this.initiateForm.controls.isFranchise.valueChanges.subscribe(
      (newValue) => {
        if (newValue == 'true') {
          this.hasServiceCenter.setValue(true);
          this.servicePlusOptions.forEach((option) => {
            if (option.servicePlusId != 1) {
              this.isDisabledChecked(option);
              this.selectedOptions.push(option.servicePlusId);
              option.checked = true;
            }
          });
          this.checkBoxChange.emit(this.selectedOptions);
        }
        if (newValue == 'false') {
          this.hasServiceCenter.setValue(false);
          this.servicePlusOptions.forEach((option) => {
            let index = this.selectedOptions.findIndex(
              (d) => d == option.servicePlusId
            );
            this.selectedOptions.splice(index, 1);
            option.checked = false;
          });
          this.checkBoxChange.emit(this.selectedOptions);
        }
      }
    );
  }

  getDisabledValue(option: any) {
    if (this.enableAll) {
      return false;
    }
    if (this.hasRetroOrReinsurance && option.defaultDisabled) {
      return true;
    }
    if (option.defaultDisabled && !this.hasServiceCenter.value) {
      return true;
    }
    return false;
  }

  openTooltipDialog(option: any) {
    this.dialog.open(QuicksignServicePlusTooltipDialogComponent, {
      data: option,
    });
  }

  isDisabledChecked(option: any) {
    if (option.defaultDisabled && option.checked) {
      option.checked = false;

      // Remove the option's ID from the selectedOptions array
      const index = this.selectedOptions.findIndex(
        (d) => d == option.servicePlusId
      );
      if (index !== -1) {
        this.selectedOptions.splice(index, 1);
      }
    }
  }

  checkboxChangeEvent(event: MatCheckboxChange, option: any, index: number) {
    if (!this.hasServiceCenter.value) {
      option.checked = false;
    }
    if (event.checked) {
      if (this.partSourcingIds.includes(option.servicePlusId)) {
        for (let index = 0; index < this.partSourcingIds.length; index++) {
          const id = this.partSourcingIds[index];
          const selectedIndex = this.selectedOptions.findIndex((d) => d == id);
          if (selectedIndex !== -1) {
            this.selectedOptions.splice(selectedIndex, 1);
          }
          if (option.servicePlusId != id) {
            const selectedOptionIndex = this.servicePlusOptions.findIndex(
              (d) => d.servicePlusId == id
            );
            this.servicePlusOptions[selectedOptionIndex].checked = false;
          }
        }
      }
      this.selectedOptions.push(option.servicePlusId);
    } else {
      const index = this.selectedOptions.findIndex(
        (d) => d == option.servicePlusId
      );
      this.selectedOptions.splice(index, 1);
    }
    this.servicePlusOptions[index].checked = event.checked;
    // Emit the checkBoxChange event with selected options
    this.checkBoxChange.emit(this.selectedOptions);
  }
}
