import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { PrincipalService } from './principal.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DealerSurveyDialogComponent } from 'src/dealer-survey-dialog/dealer-survey-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class DealerService {
  private endpoint: string;
  constructor(
    private http: HttpClient,
    private principal: PrincipalService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
    this.endpoint = environment.dashboardEndpoint + environment.version;
  }

  timeout: any = null;

  getTerminatedDealersLastMonth() {
    return this.http.get<any[]>(
      `${this.endpoint}dealer/terminated-dealers-last-month`,
    );
  }

  activateDealer(dealerNumber: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = JSON.stringify(dealerNumber);

    return this.http.post(
      `${this.endpoint}dealer/quickreinstate-reactivate-dealer`,
      body,
      { headers: headers },
    );
  }

  getPendingDealerAttrition() {
    var baseUrl = `${this.endpoint}dealer/pending-dealer-attrition`;
    return this.http.get<any[]>(baseUrl);
  }

  postponeTermination(dealerNumber: string, lastSaleDate: Date) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = JSON.stringify(lastSaleDate);

    return this.http.post(
      `${this.endpoint}dealer/postpone-termination/${dealerNumber}`,
      body,
      { headers: headers },
    );
  }
  getCurrentDealerSurvey(userId: string) {
    return this.http.get(`${this.endpoint}dealer/${userId}/survey`);
  }

  updateDealerSurvey(survey: any) {
    return this.http.put(`${this.endpoint}dealer/survey`, survey);
  }

  public checkForDealerSurvey(
    waitTime: number = 0,
    allowPage: boolean = false,
  ) {
    if (this.timeout != null) {
      if (!environment.production) {
        console.log('Dealer Survey: (TIMEOUT ' + this.timeout + ') clearedx');
      }
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (!this.principal.isAuthenticated) return;
    if (
      !this.principal.hasUserViewAccess(['Dealer']) &&
      !this.principal.hasUserViewAccess(['Agent'])
    ) {
      return;
    }
    this.getCurrentDealerSurvey(this.principal.id).subscribe({
      next: (result: any) => {
        if (result != null) {
          return;
        } else {
          if (waitTime > 0) {
            this.setDealerSurveyTimeout(waitTime, allowPage);
            return;
          }
          this.openDealerSurveyDialog(false, allowPage);
        }
      },
    });
  }

  public openDealerSurveyDialog(
    isTimeoutTriggered: boolean = false,
    allowPage: boolean = false,
  ) {
    if (this.timeout != null) {
      if (!environment.production) {
        console.log('Dealer Survey: (TIMEOUT ' + this.timeout + ') cleared');
      }
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (
      (this.router.url.includes('quicktrack') ||
        this.router.url.includes('contract/') ||
        this.router.url.includes('contracts/saved-quotes') ||
        this.router.url.includes('dashboard') ||
        this.router.url.includes('report')) &&
      !allowPage
    ) {
      if (!environment.production) {
        console.log('Dealer Survey: Page not allowed.');
      }
      return;
    }
    this.dialog.closeAll();
    this.dialog
      .open(DealerSurveyDialogComponent, {
        width: '570px',
        data: { isTimeoutTriggered: isTimeoutTriggered },
        autoFocus: false,
        disableClose: true,
      })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (result)
            this.snackBar.open('Thank you for your feedback!', 'Dismiss', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 2000,
            });
        },
        error: (error) => {},
      });
  }

  private setDealerSurveyTimeout(waitTime: number, allowPage: boolean = false) {
    this.timeout = setTimeout(() => {
      this.openDealerSurveyDialog(waitTime > 0, allowPage);
    }, waitTime * 1000);
    if (!environment.production) {
      console.log(
        'Dealer Survey: (TIMEOUT ' +
          this.timeout +
          ') waiting for ' +
          waitTime +
          ' seconds.',
      );
    }
  }
}
