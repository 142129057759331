import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SettingsService } from '../../shared/settings.service';
import { UsersService } from '../../shared/users.service';
import { environment } from '../../environments/environment';
import { NotificationService } from '../../shared/notification.service';
import { PrincipalService } from '../../shared/principal.service';
import { QueryManagerService } from '../../shared/query-manager.service';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { CmsService } from '../../shared/cms.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CilajetDialogComponent } from 'src/cilajet-dialog/cilajet-dialog.component';

declare let olark: Function;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit {
  title = 'app';
  loading = false;
  loadingSubject$ = new Subject<boolean>();
  loadingEvent$ = this.loadingSubject$.asObservable().pipe(debounceTime(250));
  notifications: any[];
  notificationBadgeCount: number | null;
  isProduction = environment.production;
  cmsNotifications: any;
  firstLoad: boolean = true;

  constructor(
    private queryManager: QueryManagerService,
    public principal: PrincipalService,
    private router: Router,
    private route: ActivatedRoute,
    private settings: SettingsService,
    private users: UsersService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private cmsService: CmsService,
    private snackBar: MatSnackBar
  ) {
    principal.authenticated().subscribe({
      next: (value) => {
        if (!value) {
          this.router.navigate(['/login']);
        }
      },
    });

    this.router.events.subscribe({
      next: (event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loadingSubject$.next(true);
            break;
          }

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loadingSubject$.next(false);
            break;
          }
          default: {
            break;
          }
        }
      },
    });
  }

  get notReadyForLive() {
    return !environment.production;
  }

  get token() {
    return btoa(this.principal.id);
  }

  ngOnInit() {
    this.getCmsNotifications();
    this.getNotifications();
  }

  getCmsNotifications() {
    this.cmsService.getNotifications().subscribe({
      next: (res) => {
        this.cmsNotifications = res.data.zoomNotifications;
      },
    });
  }

  getNotificationTitle(cmsNotificationId: string) {
    if (!this.cmsNotifications) {
      return '';
    }
    return this.cmsNotifications
      .filter((x) => x.contentItemId == cmsNotificationId)
      .map((x) => x.displayText)[0];
  }

  getNotifications() {
    this.notificationService.getUserNotification(this.principal.id).subscribe({
      next: (res) => {
        this.notifications = res;
        let unopened = res.filter((x) => x.dateOpened == null);
        let popUpArray = res.filter((x) => x.makePopUp);
        this.notificationBadgeCount =
          unopened.length > 0 ? unopened.length : null;
        this.triggerPopUpNotifications(popUpArray);
        if (this.firstLoad) {
          this.triggerSnackbarNotifications(
            unopened.filter((x) => !x.makePopUp)
          );
          this.firstLoad = false;
        }
      },
    });
  }

  triggerSnackbarNotifications(unopened: any[]) {
    unopened.forEach((notification, index) => {
      setTimeout(() => {
        this.openNotificationSnack(
          this.getNotificationTitle(notification.cmsNotificationId)
        );
      }, index * 5500);
    });
  }

  openNotificationSnack(
    message: string,
    action: string = 'close',
    duration: number = 5000
  ) {
    this.snackBar.open(message, action, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: duration,
    });
  }

  triggerPopUpNotifications(popUpArray: any[]) {
    popUpArray.forEach((x) => {
      if (!x.dateOpened) {
        this.openNotificationDialog(x);
      }
    });
  }

  openNotificationDialog(notification: any) {
    this.dialog
      .open(NotificationDialogComponent, {
        data: notification,
        minWidth: 400,
      })
      .afterClosed()
      .subscribe({
        next: (val) => {
          this.getNotifications();
        },
      });
  }

  openCilajetOrderFormDialog() {
    this.dialog.open(CilajetDialogComponent, {
      width: '900px',
      autoFocus: false,
    });
  }

  logoff() {
    this.principal.clear();
  }

  routeHome() {
    let home = '/contract/rate';
    let activatedRoute = this.route.snapshot['_routerState'].url.toString();
    if (activatedRoute.includes(home)) {
      location.reload();
    } else {
      this.router.navigate([home]);
    }
  }

  getDealFiAgentReportUrl() {
    const token = btoa(this.principal.id);
    return `${environment.dealFiUrl}login?token=${token}&redirectUrl=reports/agent`;
  }
}
