import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'zoom-signature-form-control',
  templateUrl: './signature-form-control.component.html',
  styleUrls: ['./signature-form-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SignatureFormControlComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SignatureFormControlComponent,
    },
  ],
})
export class SignatureFormControlComponent
  implements
    ControlValueAccessor,
    OnInit,
    AfterViewInit,
    Validator,
    AfterContentChecked
{
  @ViewChild(SignaturePad, { static: false }) public signaturePad: SignaturePad;
  @ViewChild('signatureRegion') signatureRegion: ElementRef;

  onChange: (signature: any) => any;
  onTouched: () => {};
  onValidate: () => void;

  touched = false;
  disabled = false;

  private imgSignature: string;
  private previousWidth = 0;

  options: any = {
    canvasWidth: 750,
    canvasHeight: 250,
  };

  constructor() {}

  ngAfterContentChecked(): void {
    if (!this.signatureRegion) {
      return;
    }
    const expectedWidth = this.signatureRegion.nativeElement.offsetWidth - 1;
    if (expectedWidth != this.previousWidth) {
      this.previousWidth = expectedWidth;
      this.signaturePad.set('canvasWidth', expectedWidth);
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.signaturePad) {
      this.signaturePad.clear();
    }
  }

  drawComplete(): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.imgSignature = this.signaturePad.toDataURL('image/png', 0.5);
      this.onChange(this.imgSignature);
    }
  }

  writeValue(obj: any): void {
    if (typeof obj != 'string') {
      this.imgSignature = null;
    } else {
      this.imgSignature = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  clearSignature(): void {
    this.imgSignature = null;
    this.signaturePad.clear();
    this.onChange(this.imgSignature);
  }

  validate(control: AbstractControl): ValidationErrors {
    const value = control.value;
    if (!value) {
      return {
        missingSignature: {
          signature: 'n/a',
        },
      };
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidate = fn;
  }
}
