import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-container',
  templateUrl: './container-headerless.component.html',
  styleUrls: ['./container-headerless.component.css']
})
export class ContainerHeaderlessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
