import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import {
  Agreement,
  AgreementViewModel,
  AlphaProgram,
  Dealer,
  DealerAuthPrograms,
  FloridaCommissionOptions,
  FormType,
  GapProviders,
  ModifyDefaultCommissionModel,
  OtherMenuSystem,
  Overbill,
  OverbillResults,
  ProgramTerms,
  SimpleDealerProfile,
  WarrantyEligibilityType,
} from './quick-sign.model';

@Injectable({
  providedIn: 'root',
})
export class QuickSignService {
  private endPoint: string;

  constructor(private httpClient: HttpClient) {
    this.endPoint = environment.dealerSignupEndpoint + environment.version;
  }
  getDealers() {
    return this.httpClient.get<any[]>(`${this.endPoint}dealer`);
  }

  getDealerProfile(id: any): Observable<SimpleDealerProfile> {
    return this.httpClient
      .get<SimpleDealerProfile>(`${this.endPoint}dealer/get-dealer-profile`, {
        params: {
          dealerProfileId: id,
        },
      })
      .pipe(map((r) => Object.assign(new SimpleDealerProfile(), r)));
  }

  createPcrsDealer(dealerProfileId: any, checkForDuplicates: boolean) {
    const params: any = {
      dealerProfileId: dealerProfileId,
    };
    if (checkForDuplicates !== undefined || checkForDuplicates !== null) {
      params.checkForDuplicates = checkForDuplicates;
    }

    return this.httpClient.post(
      `${this.endPoint}dealer/create-pcrs-dealer`,
      null,
      { params: params }
    );
  }

  getDealerAgreementsForAcceptance(dealerProfileId: string) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dealer/${dealerProfileId}/agreements-for-acceptance`
    );
  }

  updateDealerClick(dealerProfileId: string) {
    return this.httpClient.put(`${this.endPoint}dealer/dealer-click`, {
      dealerProfileId: dealerProfileId,
    });
  }

  getDealerInformation(id: any) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer/get-dealer-information`,
      { params: { dealerInformationId: id } }
    );
  }

  getDealerState(dealerNumber: any) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/dealer-selling-state`,
      {
        params: { dealerNumber },
      }
    );
  }

  getDealerPrograms(id: any) {
    return this.httpClient.get<DealerAuthPrograms[]>(
      `${this.endPoint}dealer-adjustments/${id}/dealer-programs`
    );
  }

  getDefaultLender(dealerNumber: string) {
    return this.httpClient.get<any>(`${this.endPoint}dealer/default-lender`, {
      params: { dealerNumber },
    });
  }

  updateDealerDefaultLender(dealerNumber: string, lenderNumber: string) {
    return this.httpClient.put(`${this.endPoint}dealer/default-lender`, {
      DealerNumber: dealerNumber,
      LenderNumber: lenderNumber,
    });
  }

  getDealerProgramsList(id: any): Observable<DealerAuthPrograms[]> {
    return this.httpClient.get<DealerAuthPrograms[]>(
      `${this.endPoint}dealer-adjustments/${id}/get-dealer-programs`
    );
  }

  getFormType(id: any): Observable<FormType> {
    return this.httpClient.get<FormType>(
      `${this.endPoint}signup/get-form-type`,
      {
        params: { formTypeId: id },
      }
    );
  }

  getWarrantyEligibilities(): Observable<WarrantyEligibilityType[]> {
    return this.httpClient.get<WarrantyEligibilityType[]>(
      `${this.endPoint}signup/get-warranty-eligibilities`
    );
  }

  getOverbill(overbillId: any): Observable<OverbillResults> {
    return this.httpClient.get<OverbillResults>(
      `${this.endPoint}signup/get-overbill`,
      {
        params: { overbillId },
      }
    );
  }

  getFloridaCommission(profileId: any) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer/florida-commission`,
      { params: { dealerProfileId: profileId } }
    );
  }

  getAgreement(model: AgreementViewModel, endpoint: any): Observable<any> {
    return this.httpClient.post<AgreementViewModel>(
      `${this.endPoint}agreements/${endpoint}`,
      model,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  getOverbillAgreement(overbillId: any) {
    return this.httpClient.get(
      `${this.endPoint}agreements/overbill/${overbillId}`,
      { responseType: 'text' }
    );
  }

  createDealerInformation(model: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer/create-dealer-information`,
      model
    );
  }

  createAgreement(model: Agreement): Observable<Agreement> {
    const datePipe = new DatePipe('en-US');
    const data = new FormData();
    data.append('DealerProfileId', model.dealerProfileId);
    data.append(
      'EffectiveDate',
      datePipe.transform(model.effectiveDate, 'short')
    );
    data.append(
      'AgreementDate',
      datePipe.transform(model.agreementDate, 'short')
    );
    data.append('SignerFirstName', model.signerFirstName);
    data.append('SignerLastName', model.signerLastName);
    data.append('SignerTitle', model.signerTitle);
    data.append('SignedDate', datePipe.transform(model.signedDate, 'short'));
    data.append('File', model.file);

    return this.httpClient.post<Agreement>(
      `${this.endPoint}dealer/create-agreement`,
      data
    );
  }

  createOverbill(model: Overbill): Observable<Overbill> {
    return this.httpClient.post<Overbill>(
      `${this.endPoint}dealer/overbill`,
      model
    );
  }

  createAgentCommissionCrmActivity(data: ModifyDefaultCommissionModel) {
    return this.httpClient.post<ModifyDefaultCommissionModel>(
      `${this.endPoint}dealer-adjustments/default-commission`,
      data
    );
  }

  getDealerSignupAgent(dealerProfileId: any) {
    return this.httpClient.get<any>(`${this.endPoint}dealer/get-signup-agent`, {
      params: { dealerProfileId: dealerProfileId },
    });
  }

  sendDealerInformationEmail(
    id: any,
    crmNote: string | null = null
  ): Observable<any> {
    const data = new FormData();
    data.append('DealerProfileId', id);
    if (crmNote) {
      data.append('CrmNote', crmNote);
    }
    return this.httpClient.post<any>(
      `${this.endPoint}email/send-dealer-information-email`,
      data,
      { params: { dealerProfileId: id } }
    );
  }

  sendDealerProfileEmail(postBody: any, initialSend?: boolean) {
    let params: any = {};
    if (initialSend) {
      params.initialSend = initialSend;
    }
    return this.httpClient.post(
      `${this.endPoint}email/send-dealer-profile-email`,
      postBody,
      { params: params }
    );
  }

  sendEditAgentAch(data: any) {
    return this.httpClient.post<any>(
      `${this.endPoint}email/send-edit-agent-ach-email`,
      data
    );
  }

  sendEditDealerEmail(model: Dealer): Observable<Dealer> {
    return this.httpClient.post<Dealer>(
      `${this.endPoint}email/send-edit-dealer-email`,
      model
    );
  }

  addUsers(payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/add-users`,
      payload
    );
  }

  addSubAgents(payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/add-sub-agents`,
      payload
    );
  }
  initiateAgent() {
    return this.httpClient.get<any>(`${this.endPoint}dealer/initiate-agent`);
  }

  getMenuSystemOptions() {
    return this.httpClient.get<OtherMenuSystem[]>(
      `${this.endPoint}dealer/other-menu-systems`
    );
  }

  updateDealerInfoInfo(data: any) {
    return this.httpClient.put(`${this.endPoint}dealer/information`, data);
  }

  updateDealerAdditionalInfo(data: any) {
    return this.httpClient.put(`${this.endPoint}dealer/additional-info`, data);
  }

  updateDealerMenuSystem(data: any) {
    return this.httpClient.put(`${this.endPoint}dealer/menu-system`, data);
  }

  updateDealerProfileFloridaCommission(profile: any) {
    return this.httpClient.put(
      `${this.endPoint}dealer/florida-commission`,
      profile
    );
  }

  getPrograms(includeExclusions: boolean = false): Observable<AlphaProgram[]> {
    return this.httpClient.get<AlphaProgram[]>(
      `${this.endPoint}signup/get-alpha-programs`,
      { params: { includeExclusions: includeExclusions.toString() } }
    );
  }

  getDealerProgramsForAdjustment(dealerNumber: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dealer-adjustments/${dealerNumber}/dealer-programs`
    );
  }

  getGapPrograms(): Observable<AlphaProgram[]> {
    return this.httpClient.get<AlphaProgram[]>(
      `${this.endPoint}signup/get-gap-programs`
    );
  }

  getProgramTerms(id: any): Observable<ProgramTerms> {
    return this.httpClient.get<ProgramTerms>(
      `${this.endPoint}signup/program-terms`,
      { params: { alphaProgramId: id } }
    );
  }

  getGapTerms(id: any, dealerNumber: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/gap-program-terms`,
      { params: { coverageGroupId: id, dealerNumber } }
    );
  }

  getProgramTermsForAdjustment(id: any): Observable<ProgramTerms> {
    return this.httpClient.get<ProgramTerms>(
      `${this.endPoint}dealer-adjustments/program-terms`,
      { params: { coverageGroupId: id } }
    );
  }

  getProgramsTerms(id: any) {
    return this.httpClient.get<ProgramTerms[]>(
      `${this.endPoint}signup/programs-terms`,
      { params: { alphaProgramIds: id } }
    );
  }

  getForms(): Observable<FormType[]> {
    return this.httpClient.get<FormType[]>(
      `${this.endPoint}signup/get-form-types`
    );
  }

  getUsStates(): Observable<any> {
    return this.httpClient.get('./assets/states_titlecase.json');
  }

  getGapProviders(): Observable<GapProviders[]> {
    return this.httpClient.get<GapProviders[]>(
      `${this.endPoint}signup/get-gap-providers`
    );
  }

  createDealerProfile(
    profile: SimpleDealerProfile
  ): Observable<SimpleDealerProfile> {
    return this.httpClient.post<SimpleDealerProfile>(
      `${this.endPoint}dealer/create-dealer-profile`,
      profile
    );
  }

  getFloridaCommissionOptions(): Observable<FloridaCommissionOptions[]> {
    return this.httpClient.get<FloridaCommissionOptions[]>(
      `${this.endPoint}signup/florida-commission-options`
    );
  }

  getInitiateDealerProfile(profileId: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}signup/initiate-dealer-profile`,
      { params: { dealerProfileId: profileId } }
    );
  }

  updateDealerProfileAgentCommission(profile: any) {
    return this.httpClient.put(
      `${this.endPoint}signup/agent-commissions`,
      profile
    );
  }

  updateDealerProfileOverbillCommission(profile: any) {
    return this.httpClient.put(
      `${this.endPoint}signup/overbill-commissions`,
      profile
    );
  }

  updateDealerProfile(profile: SimpleDealerProfile) {
    return this.httpClient.put(
      `${this.endPoint}signup/dealer-profile`,
      profile
    );
  }

  dealerEdit(dealerNumber: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/dealers/${dealerNumber}`
    );
  }

  submitDealerEdit(payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/update-dealer`,
      payload
    );
  }

  submitDealerContactsEdit(payload: any, dealerNumber: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/update-dealer-contacts`,
      payload,
      { params: { dealerNumber } }
    );
  }

  submitEditOverbill(payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/edit-overbill`,
      payload
    );
  }

  submitEditProgram(payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/edit-program`,
      payload
    );
  }

  submitPartnerFinancing(payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/partner-financing`,
      payload
    );
  }

  submitNoChargeback(payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/no-chargeback`,
      payload
    );
  }

  getAdjustmentAgreement(adjustmentId: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/agreement/${adjustmentId}`
    );
  }

  getAgentContactInfo() {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/agent/contact-details`
    );
  }

  getAgentSubAgents() {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/agent/sub-agents`
    );
  }

  updateAgentContactInfo(putObj: any) {
    return this.httpClient.put<any>(
      `${this.endPoint}dealer-adjustments/agent/contact-details`,
      putObj
    );
  }

  updateAdjustmentAgreement(adjustmentId: string, postObj: any) {
    return this.httpClient.post<string>(
      `${this.endPoint}dealer-adjustments/agreement?adjustmentId=${adjustmentId}`,
      postObj
    );
  }

  sendAdjustmentAgreementEmail(adjustmentId: string) {
    return this.httpClient.post(
      `${this.endPoint}email/send-adjustment-agreement-email?adjustmentId=${adjustmentId}`,
      { params: { adjustmentId } }
    );
  }

  submitActivateAlternateMenu(payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/agent/dealer-alternate-menu`,
      payload
    );
  }

  agreementSignature(id: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/agreement-signature`,
      {
        params: {
          agreementId: id,
        },
      }
    );
  }

  submitAgreementSignature(id: string, payload: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/agreement-signature`,
      payload,
      {
        params: {
          agreementId: id,
        },
      }
    );
  }

  loadTemplate(name: string, dealerNumber: string) {
    return this.httpClient.get<string>(
      `${environment.dealerSignupEndpoint}preview/agreements/${name}`,
      {
        responseType: 'text' as 'json',
        params: {
          dealerNumber,
        },
      }
    );
  }

  getStatus(params: any) {
    return this.httpClient.get<any>(`${this.endPoint}signup/status`, {
      params: params,
    });
  }

  deleteProfile(dealerProfileId: string) {
    return this.httpClient.post(
      `${this.endPoint}signup/delete-profile?dealerProfileId=${dealerProfileId}`,
      { params: { dealerProfileId } }
    );
  }

  updateSignature(data: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer/update-signature`,
      data
    );
  }

  getSignature(dealerProfileId: string) {
    return this.httpClient.get<any>(`${this.endPoint}dealer/get-signature`, {
      params: { dealerProfileId },
    });
  }

  updateDealerPrograms(postObj: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/dealer-programs`,
      postObj
    );
  }

  modifyAgentDefaultReserves(data: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/agent/modify-default-reserves`,
      data
    );
  }

  getAgentDefaultReserves(agentNumber: string) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/agent/get-current-default-reserves`,
      { params: { agentNumber } }
    );
  }

  getDealerContactPositions() {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dealer-adjustments/dealer-contact-positions`
    );
  }

  addPolly(data: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/polly`,
      data
    );
  }

  initializeDealerRetro(postObj: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/retro`,
      postObj
    );
  }

  getRetroById(retroId: number, includeAgreement: boolean) {
    let params: any = {
      includeAgreement,
    };
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/retro/${retroId}`,
      { params }
    );
  }

  updateRetro(retroId: number, postBody: any) {
    return this.httpClient.put(
      `${this.endPoint}dealer-adjustments/retro/${retroId}`,
      postBody
    );
  }

  completeRetro(retroId: number, postBody: any) {
    return this.httpClient.put(
      `${this.endPoint}dealer-adjustments/retro/${retroId}/complete`,
      postBody
    );
  }

  getDealerRetro(dealerNumber: any) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dealer-adjustments/${dealerNumber}/retro`
    );
  }

  getMyAdjustments(params: any) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dealer-adjustments/my-submissions`,
      { params }
    );
  }

  deleteMyAdjustment(mySubmissionId: number) {
    return this.httpClient.delete(
      `${this.endPoint}dealer-adjustments/my-submissions/${mySubmissionId}`
    );
  }

  resendMySubmission(postBody: any) {
    return this.httpClient.post(
      `${this.endPoint}dealer-adjustments/my-submissions/resend`,
      postBody
    );
  }

  getMySubmissionTypes(): any {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dealer-adjustments/my-submissions/types`
    );
  }

  getServicePlusOptions(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.endPoint}signup/service-plus-options`
    );
  }

  submitServicePlus(postBody: any) {
    return this.httpClient.put(
      `${this.endPoint}dealer-adjustments/service-plus`,
      postBody
    );
  }

  dealerAssignment(dealerProfileId: any, dealerNumber: any) {
    return this.httpClient.put(
      `${this.endPoint}dealer/${dealerProfileId}/number/${dealerNumber}`,
      null
    );
  }

  getDealerServicePlus(dealerNumber: any) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dealer-adjustments/${dealerNumber}/service-plus`
    );
  }

  getDealerServicePlusHistory(dealerNumber: any) {
    return this.httpClient.get<any[]>(
      `${this.endPoint}dealer-adjustments/${dealerNumber}/service-plus-history`
    );
  }

  getHasDealerRetroOrReinsurance(dealerNumber: any) {
    return this.httpClient.get<any>(
      `${this.endPoint}dealer-adjustments/${dealerNumber}/has-retro-or-reinsurance`
    );
  }
}
