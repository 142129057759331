import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  graphQLURL = environment.graphQLEndpoint;
  private endPoint: string;
  adminId: string = '864268341';
  constructor(private httpClient: HttpClient) {
    this.endPoint = environment.dashboardEndpoint;
  }

  getNotifications() {
    return this.httpClient.get<any>(`${this.graphQLURL}`, {
      params: {
        admin: this.adminId,
        query: `query {
      zoomNotifications {
        displayText
        notificationBody
        contentItemId
      }
    }`
      }
    });
  }

  getNotification(cmsNotificationId: any) {
    return this.httpClient.get<any>(`${this.graphQLURL}`, {
      params: {
        admin: this.adminId,
        query: `query {
      zoomNotifications(where: {contentItemId: "${cmsNotificationId}"}) {
        displayText
        notificationBody
        contentItemId
      }
    }`
      }
    });
  }
}
