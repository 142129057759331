import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ClaimService } from '../claim.service';
import { ClaimList } from '../claim.model';

@Component({
  selector: 'app-quicktrack-table',
  templateUrl: './quicktrack-table.component.html',
  styleUrls: ['./quicktrack-table.component.css'],
})
export class QuicktrackTableComponent implements OnInit {
  @Input() params: Observable<any>;
  @Input() multipleDealers = false;
  @Output() tableLoading = new EventEmitter<boolean>();
  loading = true;
  appearance = 'none';
  color = 'primary';

  claimDataSource = new MatTableDataSource<ClaimList>();
  claimColumns = [
    'customer',
    'claimEntryDate',
    'contractNumber',
    'claimNumber',
    'status',
    'datePaid',
    'paidAmount',
    'action',
  ];
  claimColumnsWithDealers = [
    'customer',
    'claimEntryDate',
    'contractNumber',
    'dealerNumber',
    'claimNumber',
    'status',
    'datePaid',
    'paidAmount',
    'action',
  ];
  mostRecentInput: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ClaimService
  ) {
    this.appearance = environment.theme.input;
    this.color = environment.theme.primary;
  }

  ngOnInit() {
    this.params.subscribe({
      next: (result) => {
        this.paginator.pageIndex = 0;
        this.paginator.length = 0;
        this.mostRecentInput = result;
        this.query(result);
      },
    });
    this.paginator.page.subscribe({
      next: () => {
        if (this.mostRecentInput != null) {
          this.query(this.mostRecentInput);
        }
      },
    });
  }

  ngAfterViewInit() {
    this.claimDataSource.sort = this.sort;
    this.claimDataSource.paginator = this.paginator;
    this.claimDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'customer':
          return `${item.firstName} ${item.lastName}`;
        case 'claimEntryDate':
          return item.claimSystemEntryDate;
        case 'status':
          return item.claimStatus;
        case 'datePaid':
          return item.dateClaimPaid;
        case 'paidAmount':
          return item.amountPaid;
        default:
          return item[property];
      }
    };
  }

  query(params: any) {
    if (params == null) {
      return;
    }
    this.loading = true;
    this.tableLoading.emit(this.loading);
    params.page = this.paginator.pageIndex;
    params.pageSize = this.paginator.pageSize;

    this.service.getPagedClaimsList(params).subscribe({
      next: (result: any) => {
        console.log('Fetched result:', result);
        if (Array.isArray(result)) {
          this.claimDataSource.data = result;
          this.paginator.pageIndex = params.page;
          this.paginator.length = result.length;
        } else if (result && result.items) {
          this.claimDataSource.data = result.items;
          this.paginator.pageIndex = result.page;
          this.paginator.length = result.count;
        } else {
          console.error('Result items are undefined or null');
        }
        this.loading = false;
        this.tableLoading.emit(this.loading);
      },
      error: (err: HttpErrorResponse) => {
        console.error('Error fetching paged claims list:', err);
        this.loading = false;
        this.tableLoading.emit(this.loading);
      },
    });
  }

  getClaimStatus(status: string) {
    let softStatus = '';
    switch (status) {
      case 'Denied':
        softStatus = 'Closed - Not Covered';
        break;
      case 'Pending':
      case 'Authorized':
      case 'Approved':
        softStatus = 'Open';
        break;
      case 'Paid':
      case 'Closed':
        softStatus = status;
        break;
    }
    return softStatus;
  }

  navigateToClaim(claimNumber: string, dealerNumber: string) {
    const url = this.router.createUrlTree(['/quicktrack', claimNumber], {
      relativeTo: this.route,
      queryParams: { dealerNumber },
    });
    window.open(url.toString(), '_blank');
  }

  navigateToContract(contractNumber: string) {
    const url = this.router.createUrlTree(['/contracts', contractNumber]);
    window.open(url.toString(), '_blank');
  }

  mergeQueryParams(
    context: ActivatedRoute,
    params: any,
    clearToken = true,
    claimNumber: string
  ) {
    if (clearToken) params.token = null;
    this.router
      .navigate([], {
        relativeTo: context,
        queryParams: params,
        queryParamsHandling: 'merge',
      })
      .then(() => {
        this.router.navigate([claimNumber], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve',
        });
      });
  }
}
