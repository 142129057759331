import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import {
  Component,
  OnInit,
  SecurityContext,
  ViewChild,
  Renderer2,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolsService } from '../tools.service';
import { environment } from '../../environments/environment';
import {
  Claim,
  ClaimDetails,
  ClaimNotes,
  Vehicle,
  ClaimSummary,
  ClaimDetailSummary,
  ClaimPart,
} from '../claim.model';
import { ClaimService } from '../claim.service';
import { Contract } from '../contract.model';
import { PrincipalService } from '../principal.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OverrideDialogComponent } from '../override-dialog/override-dialog.component';
import { ZoomContractService } from '../zoom-contract.service';
import { ZoomValidators } from '../zoom-validators';

declare const icPatronChat: any;

@Component({
  selector: 'app-quicktrack-claim-detail',
  templateUrl: './quicktrack-claim-detail.component.html',
  styleUrls: ['./quicktrack-claim-detail.component.css'],
})
export class QuicktrackClaimDetailComponent implements OnInit {
  claimNumber: string;
  claim: Claim;
  contract: Contract;
  contractNumber: string;
  vehicle: Vehicle;
  href: SafeResourceUrl;
  filename: string;
  contractHref: SafeResourceUrl;
  contractFilename: string;
  showDownload = false;
  contractDownloadDisabled = false;
  showNotes = true;
  noteCutoff = new Date(2020, 8, 14);
  contractMileage: string;
  contractExpirationOdo: string;
  contractExpirationDate: string;
  zoomUrl = environment.zoomEndpoint;
  deniedExceptInspection: boolean = false;
  claimSummary: ClaimSummary;
  isOverride: boolean = false;
  isOverrideLoading: boolean = true;

  appearance = 'none';
  color = 'primary';

  claimDataSource = new MatTableDataSource<ClaimDetails>([]);
  claimColumns = [
    'category',
    'lossDescription',
    'part',
    'requestedAmount',
    'payableAmount',
    'tax',
    'totalPayable',
    'status',
    'payee',
  ];
  noteDataSource = new MatTableDataSource<ClaimNotes>([]);
  noteColumns = ['noteDate', 'userId', 'note'];
  termDictionary = {
    lol: 'Limit of Liability. The max amount by category or NADA value that will be contributed towards claims paid for the vehicle on the contract.',
    adj: 'adjustment',
    pri: 'previous repair invoices',
    pq: 'part quote',
    pqs: 'part quotes',
    "pq's": 'part quotes',
    kb: 'knowledge base',
    auth: 'Authorized',
    rfd: 'reason for ineligibility',
    mods: 'modifications to the vehicle',
    alt: 'alterations to the vehicle',
    diag: 'diagnosis of the issue the vehicle is experiencing ',
    swis: 'southwest inspection services',
    swi: 'southwest inspection',
    wis: 'western inspection services',
    nada: 'national automobile dealer association',
    rf: 'repair facility',
    vm: 'voice mail',
    "cq's": 'Customer questions. Questions asked of the customer relating to their vehicle usage.',
    '12/12':
      "Standard warranty required from repair facility's.  Warranty covers parts and labor for 12 months.",
    eoc: 'warranty extended by vendors on some parts until the end of the VSC term.',
    ev: 'electric vehicle',
    dl: 'selling dealership',
    ag: 'agent',
    si: 'self inspection',
    npa: 'No Prior Authorization claim.  This type of claim is submitted by the contract holder when they have a repair completed prior to  notifying Alpha.',
    cel: 'check engine light',
    lh: 'lienholder',
    oem: 'original equipment manufacturer',
    spp: 'service payment plan; A finance company used by Alpha customers to fund their VSC.',
    lop: 'loss of power',
    lof: 'loss of fluids',
    tx: 'transferred call',
    rsa: 'roadside assistance',
    odo: 'odometer',
    cxl: 'cancel',
    na: 'not available',
    "3 c's": 'complaint, cause, correction',
    '3 c’s': 'complaint, cause, correction',
    "3c's": 'complaint, cause, correction',
    y: 'yes',
    n: 'no',
    td: 'teardown',
    pof: 'point of failure',
    mr: 'maintenance record',
    mrs: 'maintenance records',
    rr: 'labor',
    msrp: 'manufacturer\'s suggested retail price.',
    vpi: 'vender part issue'
  };

  pointOfContact: string;  
  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (!this.noteDataSource.sort) {
      this.noteDataSource.sort = sort;
    }
  }
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    if (!this.noteDataSource.paginator) {
      this.noteDataSource.paginator = paginator;
    }
  }
  isScsContract: boolean;

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private service: ClaimService,
    private router: Router,
    private zoomContract: ZoomContractService,
    title: Title,
    private domSanitizer: DomSanitizer,
    public dialog: MatDialog,
    private principal: PrincipalService,
    public datepipe: DatePipe,
    private el: ElementRef
  ) {
    title.setTitle(environment.titlePrefix + 'Claim Details');
    this.appearance = environment.theme.input;
    this.color = environment.theme.primary;
    this.claimDataSource.data = [];
  }

  ngOnInit() {
    if (environment.production) {
      this.pointOfContact = 'ad52f710-7db5-404b-83ee-cbb8b988d199'; // Prod POC:
    }
    else {
      this.pointOfContact = '390de163-bb21-49c6-85e5-c5ce14ae9f63'; //Test POC
    }
    this.removeResidualChat();
  }

  ngAfterViewInit() {
    this.query();
    var chatFrame = document.getElementById('icChat') as HTMLIFrameElement;
    if (chatFrame == undefined) {      
      this.removeResidualChat;
      this.query();
    } else if (chatFrame == undefined) {      
      this.query();
    }
    this.checkicPatronChat();
  }

  checkicPatronChat() {
    if (icPatronChat == undefined) {     
      document.getElementById('icChat').remove();
      this.removeResidualChat;
      this.loadChat(this.pointOfContact);
    }    
  }
  removeResidualChat() {
    const chat = document.getElementsByClassName('button-iframe-wrap')[0];
    if (chat) {
      chat.remove();
    }
  }

  loadChat(poc: string) {      
    if (this.contract == undefined) {
      setTimeout(() => {}, 250);
    }
    try {
      document.getElementsByClassName('ie-div-position-customer-chat')[0].remove();
    }
    catch (e) { }
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `      
    icPatronChat.init({
      serverHost: "https://home-c54.nice-incontact.com",
      bus_no: 4604375,
      poc: "${poc}",
      params: [
        "${this.principal.userViewAccessType}",
        "${this.principal.phoneNumber}",
        "${this.principal.userName}",
        "${this.claimNumber}",
        "${this.contract.contractNumber}",
        "${this.principal.firstName}",
        "${this.principal.lastName}"
      ],
    });
    `;
    this.renderer.appendChild(document.body, script);
    var chatFrame = document.getElementById('icChat') as HTMLIFrameElement;  
    return script;
  }

  onChatClick() {
    this.checkicPatronChat();
    icPatronChat.show();
  }

  query() {
    this.claimNumber = this.route.snapshot.paramMap.get('claimNumber');
    const dealerNumberQuery =
      this.route.snapshot.queryParamMap.get('dealerNumber');
    // pre load claim details pdf
    this.download();
    this.service
      .getClaim(this.claimNumber, dealerNumberQuery)
      .subscribe((result) => {
        this.claim = result;
        if (new Date(result.dateLossOccurred) <= this.noteCutoff) {
          this.showNotes = false;
        }
      });
    this.service
      .getClaimContract(this.claimNumber, dealerNumberQuery)
      .subscribe((result) => {
        this.contract = result;
        this.contractNumber = result.contractNumber;
        this.isScsContract =
          new Date(result.contractSaleDate) <= new Date(2021, 9, 1);
        this.service
          .vehicleInformation(result.vehicleIdNumber)
          .subscribe((v) => {
            this.vehicle = v;
          });
        if (result.contractMileage === 999999) {
          this.contractMileage = 'Unlimited';
        } else {
          this.contractMileage =
            result.contractMileage.toLocaleString('number');
        }
        if (result.contractExpirationOdometer > 900000) {
          this.contractExpirationOdo = 'Unlimited';
        } else {
          this.contractExpirationOdo =
            result.contractExpirationOdometer.toLocaleString('number');
        }
        if (new Date(result.contractExpiration).getFullYear() > 2050) {
          this.contractExpirationDate = 'Lifetime';
        } else {
          this.contractExpirationDate = this.datepipe.transform(
            result.contractExpiration,
            'M/d/yyyy'
          );
          this.loadChat(this.pointOfContact);
          var chatFrame = document.getElementById('icChat') as HTMLIFrameElement;
          if (chatFrame == undefined) {          
            this.query();
            this.checkicPatronChat();
          }
        }
      });
    this.service
      .getQualifyOverride(this.claimNumber, dealerNumberQuery)
      .subscribe((result) => {
        this.isOverride = result;
        this.isOverrideLoading = false;
      });
    this.service
      .getClaimDetails(this.claimNumber, dealerNumberQuery)
      .subscribe((result) => {
        this.sortAndColor(result);
        this.claimDataSource.data = result;
        this.checkLossCodes(result);
      });
    this.service
      .getClaimNotes(this.claimNumber, dealerNumberQuery)
      .subscribe((result) => {
        this.noteDataSource.data = result;
        //this.noteDataSource.sort = this.sort;
        //this.noteDataSource.paginator = this.paginator;
        this.wordReplace();
        this.removeSystemNotes();
      });

    // Get additional claim info to display
    this.service.getClaimSummary(this.claimNumber).subscribe((result) => {
      this.claimSummary = result;
    });
    this.checkicPatronChat();
  }

  checkLossCodes(result: any[]) {
    var deniedExceptInspection = true;
    result.forEach((row) => {
      //if the loss code row is either denied or the loss code is in (GN003, GN080, GN081) then hide the system notes and mark the status denied
      var inspectionLossCodes = [
        '1st Inspection / General',
        '1st Inspection',
        '2nd Inspection',
        '3rd Inspection',
      ];
      if (
        row.status != 'Denied' &&
        !inspectionLossCodes.includes(row.lossDescription)
      ) {
        deniedExceptInspection = false;
      }
    });
    this.deniedExceptInspection = deniedExceptInspection;
  }

  qualifyOverride() {
    return this.isOverride;
  }

  removeSystemNotes() {
    var systemNotesRemoved = [];
    if (this.deniedExceptInspection) {
      this.noteDataSource.data.forEach((note) => {
        if (!note.systemNoteFlag) {
          systemNotesRemoved.push(note);
        }
      });
      this.noteDataSource.data = systemNotesRemoved;
    }
  }

  sortAndColor(result: any[]) {
    result.sort((cd1, cd2) => {
      if (cd1 === cd2) return 0;
      else if (cd1 === null) return 1;
      else if (cd2 === null) return 1;
      else if (cd1.claimFailureId > cd2.claimFailureId) return -1;
      else return 1;
    });
    var lastDistClaimFailure = result[0].claimFailureId;
    var colorInd = 0;
    for (let i = 0; i < result.length; i++) {
      var cur = result[i];
      if (cur.claimFailureId == lastDistClaimFailure) {
        cur.color = colorInd;
        continue;
      } else {
        lastDistClaimFailure = cur.claimFailureId;
        colorInd = ++colorInd % 3;
        cur.color = colorInd;
      }
    }
  }

  requestedTotal() {
    return this.claimDataSource.data.reduce((runningTotal, currentValue) => {
      if (currentValue.detailType === 'L' || currentValue.detailType === 'P') {
        if (currentValue.status === 'Informational') {
          return runningTotal;
        }
        return (
          runningTotal +
          currentValue.requestedQuantity * currentValue.requestedUnitCost
        );
      }
      return runningTotal + currentValue.externalTotalAmount;
    }, 0);
  }

  authorizedTotal() {
    return this.claimDataSource.data.reduce((runningTotal, currentValue) => {
      if (currentValue.detailType === 'L' || currentValue.detailType === 'P') {
        if (currentValue.status === 'Informational') {
          return runningTotal;
        }
        return (
          runningTotal +
          currentValue.authorizedQuantity * currentValue.authorizedUnitCost
        );
      }
      return runningTotal + (currentValue.amountPaid - currentValue.taxAmount);
    }, 0);
  }

  totalPayable() {
    return this.claimDataSource.data.reduce((runningTotal, currentValue) => {
      if (currentValue.status === 'Informational') {
        return runningTotal;
      }
      return runningTotal + currentValue.amountPaid + currentValue.taxAmount;
    }, 0);
  }

  taxTotal() {
    let total = this.claimDataSource.data.reduce(
      (runningTotal, currentValue) => runningTotal + currentValue.taxAmount,
      0
    );
    return total;
  }

  async wordReplace() {
    let re = new RegExp(
      Object.keys(this.termDictionary)
        .map((v) => '\\b' + v.replace('/', '\\/').replace(' ', '\\s') + '\\b')
        .join('|'),
      'gi'
    );
    this.noteDataSource.data.map(async (data) => {
      data.note = data.note.replace(re, (matched) => {
        return this.termDictionary[matched.toLowerCase()];
      });
    });
  }

  onOverrideClick() {
    const requestedTotal = this.requestedTotal();
    if (window.innerWidth > 600) {
      const dialogRef = this.dialog.open(OverrideDialogComponent, {
        width: '40vw',
        height: '38em',
        data: requestedTotal,
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
      });
    } else {
      const dialogRef = this.dialog.open(OverrideDialogComponent, {
        width: '90vw',
        height: '38em',
        data: requestedTotal,
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
      });
    }
  }

  download() {
    this.filename = 'Claim Details - ' + this.claimNumber + '.pdf';
    this.service.downloadClaimSummary([this.claimNumber]).subscribe({
      next: (url) => {
        this.href = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      },
      complete: () => (this.showDownload = true),
      error: (err) => {
        console.log(err);
      },
    });
  }

  downloadContractPdf() {
    this.contractDownloadDisabled = true;
    this.contractFilename = 'Contract - ' + this.contractNumber + '.pdf';
    this.zoomContract
      .downloadContractPdf(this.contractNumber, this.isScsContract.toString())
      .subscribe({
        next: (result) => {
          ZoomValidators.downloadBlob(result, this.contractFilename);
          this.contractDownloadDisabled = false;
        },
        error: () => (this.contractDownloadDisabled = false),
      });
  }
}
