import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { DashboardService } from '../dashboard.service';
import { PrincipalService } from '../principal.service';

@Component({
  selector: 'app-dealer-multi-select',
  templateUrl: './dealer-multi-select.component.html',
  styleUrls: ['./dealer-multi-select.component.css']
})
export class DealerMultiSelectComponent implements OnInit {
  @ViewChild('dealerSearch') searchTextBox: ElementRef;

  @Input()
  selectFormControl: FormControl;
  @Input()
  agentNumber: FormControl;

  searchTextboxControl = new FormControl();
  selectedValues = [];
  data = []

  filteredOptions: Observable<any[]>;
  constructor(
    private dashboard: DashboardService,
    private principal: PrincipalService
  ) {

  }

  ngOnInit() {
      this.dashboard.filtersDealersByScsNumber(this.agentNumber.value ?? '').subscribe({
        next: (res) => {
          this.data = res;
          this.filteredOptions = this._filter('');
        }
      });
    
    this.agentNumber.valueChanges.subscribe({
      next: (val) => {
        if (val) {
          this.dashboard.filtersDealersByScsNumber(val).subscribe({
            next: (res) => {
              this.data = [];
              this.data = res;
              this.filteredOptions = this._filter('');
            }
          });
        }
      }
    });

    this.searchTextboxControl.valueChanges.subscribe(val => {
      this.filteredOptions = this._filter(val);
    });
  }

  private _filter(name: string): Observable<any[]> {
    if (name === '') {      
      return of(this.data);
    }

    const filterValue = name.toLowerCase();
    this.setSelectedValues();
    let filteredList = this.data.filter(option => option.dealerName.toLowerCase().includes(filterValue) || option.dealerNumber.toLowerCase().includes(filterValue));
    return of(filteredList);
  }

  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1)
    }
  }

  openedChange(e) {
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  setSelectedValues() {
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((val) => {
        if (this.selectedValues.indexOf(val) == -1) {
          this.selectedValues.push(val);
        }
      });
    }
  }
}
