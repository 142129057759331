export const environment = {
  production: true,
  development: false,
  version: 'api/',
  zoomEndpoint: '/',
  dealfiDirectUrl: 'https://dealfidirect.com/',
  dashboardEndpoint: 'https://dashboard.alphawarranty.com/',
  brEndpoint: 'https://review.alphawarranty.com/',
  dealerSignupEndpoint: 'https://quicksign.alphawarranty.com/',
  cancellationEndpoint: 'https://cancellation.alphawarranty.com/',
  toolsEndpoint: 'https://tools.alphawarranty.com/',
  pcrsWebhook: 'https://pcrs-webhook.alphawarranty.com',
  marketingEndpoint: 'https://iiapi.alphawarranty.com/',
  dealFiUrl: 'https://app.dealfi.com/',
  theme: {
    primary: 'primary',
    secondary: 'accent',
    input: 'outline',
  },
  graphQLEndpoint: 'https://blog.alphawarranty.com/api/GraphQL',
  titlePrefix: 'Zoom - ',
  lmsModuleId: '4amka45rd0vztrszb21gn52x4n',
  pcrsEnabled: false,
};
