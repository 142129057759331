import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AgentGuard } from '../shared/agent.guard';
import { AuthorizedGuard } from '../shared/authorized.guard';
import { JwtInterceptor } from '../shared/jwt.interceptor';
import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './app.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { LandingComponent } from './landing/landing.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { ContainerComponent } from './container/container.component';
import { ContainerHeaderlessComponent } from './container-headerless/container-headerless.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SiriusXMRealtimeComponent } from '../sirius-xm/sirius-xm-realtime/sirius-xm-realtime.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { environment } from '../environments/environment';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { RepairFacilityComponent } from '../shared/repair-facility/repair-facility.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LandingComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'sirius-xm-realtime',
    component: SiriusXMRealtimeComponent,
  },
  {
    path: 'rf',
    loadChildren: () =>
      import('../repair-facility/repair-facility.module').then(
        (d) => d.RepairFacilityModule,
      ),
  },
  {
    path: '',
    component: NavComponent,
    canActivate: [AuthorizedGuard],
    children: [
      {
        path: 'dashboard',
        canActivate: [AgentGuard],
        loadChildren: () =>
          import('../dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'quicktrack',
        loadChildren: () =>
          import('../quicktrack/quicktrack.module').then(
            (m) => m.QuicktrackModule,
          ),
      },
      {
        path: 'quicksign',
        loadChildren: () =>
          import('../quicksign/quicksign.module').then(
            (m) => m.QuicksignModule,
          ),
      },
      {
        path: 'quicksync',
        loadChildren: () =>
          import('../quicksync/quicksync.module').then(
            (m) => m.QuicksyncModule,
          ),
      },
      {
        path: 'outfield',
        loadChildren: () =>
          import('../outfield/outfield.module').then((m) => m.OutfieldModule),
      },
      {
        path: 'initiate',
        loadChildren: () =>
          import('../initiate/initiate.module').then((d) => d.InitiateModule),
      },
      {
        path: 'ratecard',
        canActivate: [AgentGuard],
        loadChildren: () =>
          import('../ratecard/ratecard.module').then((d) => d.RatecardModule),
      },
      {
        path: 'videos',
        loadChildren: () =>
          import('../videos/videos.module').then((d) => d.VideosModule),
      },
      {
        path: 'business-reviews',
        loadChildren: () =>
          import('../business-reviews/business-review.module').then(
            (d) => d.BusinessReviewModule,
          ),
      },
      {
        path: 'impersonate',
        component: ImpersonateComponent,
      },
      {
        path: 'cancellation',
        loadChildren: () =>
          import('../cancellation/cancellation.module').then(
            (d) => d.CancellationModule,
          ),
      },
      {
        path: 'dealer-cancellation',
        loadChildren: () =>
          import('../dealer-cancellation/dealer-cancellation.module').then(
            (d) => d.DealerCancellationModule,
          ),
      },
      {
        path: 'contract',
        loadChildren: () =>
          import('../contract/contract.module').then((d) => d.ContractModule),
      },
      {
        path: 'contracts',
        loadChildren: () =>
          import('../contracts/contracts.module').then(
            (d) => d.ContractsModule,
          ),
      },
      {
        path: 'saved-quotes-report',
        canActivate: [AgentGuard],
        loadChildren: () =>
          import('../saved-quotes-report/saved-quotes-report.module').then(
            (d) => d.SavedQuotesReportModule,
          ),
      },
      {
        path: 'product-sales-report',
        canActivate: [AgentGuard],
        loadChildren: () =>
          import('../product-sales-report/product-sales-report.module').then(
            (d) => d.ProductSalesReportModule,
          ),
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('../faqs/faqs.module').then((d) => d.FaqsModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('../settings/settings.module').then((d) => d.SettingsModule),
      },
      {
        path: 'remittance',
        loadChildren: () =>
          import('../remittance/remittance.module').then(
            (d) => d.RemittanceModule,
          ),
      },
      {
        path: 'remittance/apc',
        loadChildren: () =>
          import('../apc-remittance/apc-remittance.module').then(
            (d) => d.APCRemittanceModule,
          ),
      },
      {
        path: 'remittance/agent',
        loadChildren: () =>
          import('../agent-remittance/agent-remittance.module').then(
            (d) => d.AgentRemittanceModule,
          ),
      },
      {
        path: 'site-admin',
        loadChildren: () =>
          import('../site-admin/site-admin.module').then(
            (d) => d.SiteAdminModule,
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('../reports/reports.module').then((d) => d.ReportsModule),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('../forms/forms.module').then((d) => d.FormsModule),
      },
      {
        path: 'wealth-dashboards',
        loadChildren: () => {
          return import('../wealth-dashboards/wealth-dashboards.module').then(
            (d) => d.WealthDashboardsModule,
          );
        },
      },
      {
        path: 'marketing-materials',
        loadChildren: () =>
          import('../marketing-materials/marketing-materials.module').then(
            (d) => d.MarketingMaterialsModule,
          ),
      },
      {
        path: 'call-center',
        loadChildren: () =>
          import('../call-center/call-center.module').then(
            (d) => d.CallCenterModule,
          ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('../notification/notification.module').then(
            (d) => d.NotificationModule,
          ),
      },
      {
        path: 'tooltips',
        loadChildren: () =>
          import('../tooltips/tooltips.module').then((d) => d.TooltipsModule),
      },
    ],
  },
  {
    path: '',
    component: ContainerHeaderlessComponent,
    children: [
      {
        path: 'contract-holder/cancellation',
        loadChildren: () =>
          import('../customer-cancellation/customer-cancellation.module').then(
            (d) => d.CustomerCancellationModule,
          ),
      },
      {
        path: 'lien-holder/cancellation',
        loadChildren: () =>
          import('../lien/lien.module').then((d) => d.LienModule),
      },
    ],
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'profile',
        loadChildren: () =>
          import('../profile/profile.module').then((d) => d.ProfileModule),
      },
      {
        path: 'overbill',
        loadChildren: () =>
          import('../overbill/overbill.module').then((d) => d.OverbillModule),
      },
      {
        path: 'dealer-adjustment',
        loadChildren: () =>
          import('../adjustment-agreement/adjustment-agreement.module').then(
            (d) => d.AdjustmentAgreementModule,
          ),
      },
      {
        path: 'retro',
        loadChildren: () =>
          import('../retro/retro.module').then((m) => m.RetroModule),
      },
      {
        path: 'reinsurance',
        loadChildren: () =>
          import('../reinsurance/reinsurance.module').then(
            (d) => d.ReinsuranceModule,
          ),
      },
    ],
  },
  {
    path: 'printer',
    canActivate: [AuthorizedGuard],
    children: [
      {
        path: 'business-reviews',
        loadChildren: () =>
          import('../business-reviews/business-review.module').then(
            (d) => d.BusinessReviewModule,
          ),
      },
    ],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    ImpersonateComponent,
    NavComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    ContainerComponent,
    ContainerHeaderlessComponent,
    ForgotPasswordComponent,
    NotificationDialogComponent,
    RepairFacilityComponent,
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    GoogleChartsModule,
  ],
  providers: [
    HttpClientModule,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { horizontalPosition: 'center', verticalPosition: 'top' },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
