<mat-form-field appearance="outline">
  <mat-label>Dealers</mat-label>
  <mat-select (openedChange)="openedChange($event)"
              placeholder="Dealers"
              [formControl]="selectFormControl"
              multiple>
    <div class="select-container">
      <mat-optgroup>
        <mat-form-field style="width: 100%">
          <input #dealerSearch
                 autocomplete="off"
                 placeholder="Search"
                 aria-label="Search"
                 matInput
                 [formControl]="searchTextboxControl" />
          <button [disableRipple]="true"
                  *ngIf="dealerSearch.value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearSearch($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </mat-optgroup>
      <mat-optgroup *ngIf="
          (filteredOptions | async) && (filteredOptions | async).length == 0">
        <div>No results found!</div>
      </mat-optgroup>
      <mat-option (onSelectionChange)="selectionChange($event)"
                  *ngFor="let option of filteredOptions | async"
                  [value]="option.dealerNumber">
        {{ option.dealerNumber }} - {{option.dealerName}}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
