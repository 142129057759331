import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CmsService } from '../../shared/cms.service';
import { NotificationService } from '../../shared/notification.service';
import { PrincipalService } from '../../shared/principal.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent implements OnInit {
  isLoading: boolean;
  notification: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private principal: PrincipalService,
    private cmsService: CmsService
  ) { }

  ngOnInit(): void {
    if (!this.data.dateOpened) {
      this.notificationService.markNotificationRead(this.data.notificationId, this.principal.id).subscribe();
    }

    this.isLoading = true;
    this.cmsService.getNotification(this.data.cmsNotificationId).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.notification = res.data.zoomNotifications[0];
        console.log(this.notification);
      },
      error: (err) => {
        this.isLoading = false;
      }
    });
  }

}
