import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-dealer-filtered-multi-select',
  templateUrl: './dealer-filtered-multi-select.component.html',
  styleUrls: ['./dealer-filtered-multi-select.component.css']
})
export class DealerFilteredMultiSelectComponent implements OnInit {

  @ViewChild('dealerSearch') searchTextBox: ElementRef;

  @Input()
  dealers: FormControl;
  @Input()
  selectedDealers: FormControl; 

  searchTextboxControl = new FormControl();
  selectedValues = [];
  data = []
  filteredOptions: Observable<any[]>;

  constructor() { }

  ngOnInit() {
    this.data = [];
    if (this.dealers.value)
      this.data = this.dealers.value;

    this.dealers.valueChanges.subscribe({
      next: (res) => {
        if (res) {
          this.data = res;
          this.filteredOptions = this._filter('');
        }
      }
    })

    this.selectedDealers.valueChanges.subscribe({
      next: (res) => {
        if (res) {
          this.filteredOptions = this._filter('');
        }
      }
    });

    this.searchTextboxControl.valueChanges.subscribe(val => {
      this.filteredOptions = this._filter(val);
    });
  }

  private _filter(name: string): Observable<any[]> {

    if (name === '') {
      return of(this.data);
    }

    const filterValue = name.toLowerCase();
    this.setSelectedValues();
    let filteredList = this.data.filter(option => option.dealerName.toLowerCase().includes(filterValue) || option.dealerName.toLowerCase().includes(filterValue));
    return of(filteredList);
  }

  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1)
    }
  }

  openedChange(e) {
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  setSelectedValues() {
    if (this.selectedDealers.value && this.selectedDealers.value.length > 0) {
      this.selectedDealers.value.forEach((val) => {
        if (this.selectedValues.indexOf(val) == -1) {
          this.selectedValues.push(val);
        }
      });
    }
  }
}
