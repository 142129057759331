<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sidenav>
    <ng-container
      *ngTemplateOutlet="
        topMenu;
        context: { direction: 'column', left: true }
      "></ng-container>
    <mat-divider></mat-divider>
    <ng-container
      *ngTemplateOutlet="
        fullMenu;
        context: { direction: 'column', left: true }
      "></ng-container>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill class="sidenav-content">
    <div fxLayout="column" class="view-port">
      <header>
        <div *ngIf="principal.isAuthenticated" style="padding: 0.5em 0">
          <mat-toolbar
            fxLayout="row wrap"
            fxLayout.lt-sm="column"
            fxLayoutAlign="start center"
            class="header-row">
            <div></div>
            <div fxFlex></div>
            <div
              fxLayout="row"
              fxLayoutAlign="space-between center"
              style="width: 100%">
              <div fxLayout="row" fxLayoutAlign="start center">
                <div fxFlexLayout="row" fxLayoutAlign="start center">
                  <button
                    name="menu"
                    mat-icon-button
                    fxHide.gt-md
                    fxFlex
                    (click)="sidenav.toggle()">
                    <mat-icon>menu</mat-icon>
                  </button>
                  <a
                    name="home"
                    class="navbar-brand"
                    (click)="routeHome()"
                    fxFlex="none"
                    fxHide.lt-lg
                    style="padding-left: 0.25em">
                    <img src="../../assets/logo-narrow.png" />
                  </a>
                </div>

                <div
                  *ngIf="principal.hasImpersonation"
                  fxHide.lt-lg
                  style="padding-left: 1em">
                  <small>Impersonating {{ principal.actorUserName }}</small>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="end center" fxHide.lt-lg>
                <ng-container
                  *ngTemplateOutlet="
                    fullMenu;
                    context: { direction: 'row', left: false }
                  "></ng-container>
                <small style="color: #545759; margin-left: 2rem"
                  >{{ principal.firstName }} {{ principal.lastName }}</small
                >
                <ng-container
                  *ngTemplateOutlet="
                    topMenu;
                    context: { direction: 'row', left: false }
                  "></ng-container>
              </div>
            </div>
          </mat-toolbar>
        </div>
        <mat-progress-bar
          *ngIf="loadingEvent$ | async"
          mode="indeterminate"
          color="accent"></mat-progress-bar>
      </header>

      <main fxFlex="5 0 auto">
        <div [ngClass]="{ 'dev-highlight': notReadyForLive }">
          <router-outlet></router-outlet>
        </div>
      </main>

      <app-footer></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #fullMenu let-direction="direction" let-leftAlign="left">
  <div [fxLayout]="direction" [ngClass]="{ 'menu-align-left': leftAlign }">
    <a
      *ngIf="
        principal.hasRoles([
          'Site Admin',
          'Contracts',
          'Cancel Contract',
          'Void Contracts',
          'Enter Contract',
          'Quotes',
          'View Saved Quotes',
          'Process Quotes'
        ])
      "
      mat-button
      name="{{ leftAlign ? 'mobile-' : '' }}contracts-menu"
      [matMenuTriggerFor]="contractsMenu"
      #contractsTop="matMenuTrigger">
      contracts
      <mat-icon aria-hidden="true">arrow_drop_down</mat-icon>
    </a>

    <a
      mat-button
      routerLinkActive="active"
      [matMenuTriggerFor]="dealerToolsMenu"
      #dealerTop="matMenuTrigger"
      name="{{ leftAlign ? 'mobile-' : '' }}dealer-tools-menu">
      dealer tools
      <mat-icon aria-hidden="true">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #dealerToolsMenu>
      <a
        *ngIf="
          principal.hasRoles([
            'Site Admin',
            'Remittance',
            'View Remittance',
            'View Invoice',
            'Pay Remittance',
            'APC View Remittance',
            'APC Pay Remittance',
            'APC View Invoice',
            'Reinsurance Dashboard',
            'Retro Dashboard'
          ])
        "
        mat-menu-item
        [matMenuTriggerFor]="remittanceMenu"
        #remittanceTop="matMenuTrigger"
        name="remittance-menu">
        remittance
      </a>

      <mat-menu #remittanceMenu>
        <a
          *ngIf="
            principal.hasRoles([
              'Site Admin',
              'Remittance',
              'View Remittance',
              'Pay Remittance'
            ])
          "
          mat-menu-item
          routerLink="/remittance"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          name="remittance">
          Remittance
        </a>
        <a
          *ngIf="
            principal.hasRoles(['Site Admin', 'Remittance', 'View Invoice'])
          "
          mat-menu-item
          routerLinkActive="active"
          routerLink="/remittance/paid"
          name="paid-invoices">
          Paid Invoices
        </a>
        <a
          *ngIf="
            principal.hasRoles([
              'Site Admin',
              'APC View Remittance',
              'APC Pay Remittance'
            ])
          "
          mat-menu-item
          routerLink="/remittance/apc"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          name="apc-remittance">
          APC Remittance
        </a>
        <a
          *ngIf="principal.hasRoles(['Site Admin', 'APC View Invoice'])"
          mat-menu-item
          routerLinkActive="active"
          routerLink="/remittance/apc/paid"
          name="apc-paid-invoices">
          APC Paid Invoices
        </a>
      </mat-menu>
      <a
        *ngIf="!principal.hasRoles(['Hide Reports'])"
        mat-menu-item
        [matMenuTriggerFor]="reportingMenu"
        #reportingTop="matMenuTrigger"
        name="reporting-menu">
        reporting
      </a>
      <mat-menu #reportingMenu>
        <a
          mat-menu-item
          routerLink="/reports/sales-overview"
          routerLinkActive="active"
          name="sales-overview">
          Sales Overview
        </a>
        <a
          mat-menu-item
          routerLink="/reports/product-sales"
          routerLinkActive="active"
          name="product-sales">
          Product Sales
        </a>
        <a
          mat-menu-item
          routerLink="/reports/sales-comparison"
          routerLinkActive="active"
          name="vsc-sales-comparison">
          VSC Sales Comparison
        </a>
        <a
          mat-menu-item
          routerLink="/reports/claims-paid"
          routerLinkActive="active"
          name="claims-paid">
          Claims Paid
        </a>
        <a
          mat-menu-item
          routerLink="/reports/user-summary"
          routerLinkActive="active"
          name="user-summary">
          User Summary
        </a>
      </mat-menu>
      <a
        mat-menu-item
        [matMenuTriggerFor]="dealerSettingsMenu"
        #reportingTop="matMenuTrigger"
        name="dealer-settings-menu">
        dealer settings
      </a>
      <mat-menu #dealerSettingsMenu>
        <a
          mat-menu-item
          routerLink="settings/users"
          *ngIf="principal.hasRoles(['Site Admin', 'Manage Users'], false)"
          routerLinkActive="active"
          (click)="sidenav.close()"
          name="user-summary">
          user summary
        </a>
        <a
          mat-menu-item
          routerLink="settings/enhanced-features"
          *ngIf="principal.hasRoles(['Site Admin', 'Enhanced Features'], false)"
          routerLinkActive="active"
          (click)="sidenav.close()"
          name="enhanced-features">
          enhanced features
        </a>
        <a
          mat-menu-item
          routerLink="settings/markup"
          *ngIf="
            principal.hasRoles(
              ['Site Admin', 'View Markup', 'Edit Markup'],
              false
            )
          "
          routerLinkActive="active"
          (click)="sidenav.close()"
          name="markup">
          markup
        </a>
        <a
          mat-menu-item
          routerLink="settings/sale-authority"
          routerLinkActive="active"
          (click)="sidenav.close()"
          name="sale-authority">
          sale authority
        </a>
        <a
          mat-menu-item
          routerLink="settings/ach-information"
          *ngIf="
            principal.hasRoles(['Site Admin', 'Manage ACH Information'], false)
          "
          routerLinkActive="active"
          (click)="sidenav.close()"
          name="ach-information">
          ach information
        </a>
        <a
          mat-menu-item
          routerLink="settings/managers"
          *ngIf="principal.hasRoles(['Site Admin', 'Sales Associates'], false)"
          routerLinkActive="active"
          (click)="sidenav.close()"
          name="fi-manager-sales-associates">
          F&amp;I Manager/Sales Associates
        </a>
      </mat-menu>
      <a
        *ngIf="principal.hasRoles(['Site Admin', 'Reinsurance Dashboard'])"
        mat-menu-item
        routerLink="/wealth-dashboards/reinsurance-wealth-dashboard"
        (click)="sidenav.close()"
        name="reinsurance-dashboard">
        Reinsurance Dashboard
      </a>
      <a
        *ngIf="principal.hasRoles(['Site Admin', 'Retro Dashboard'])"
        mat-menu-item
        routerLink="/wealth-dashboards/retro-wealth-dashboard"
        (click)="sidenav.close()"
        name="retro-dashboard">
        Retro Dashboard
      </a>
      <a
        mat-menu-item
        routerLink="/forms"
        routerLinkActive="active"
        (click)="sidenav.close()"
        name="forms">
        Forms
      </a>
      <a
        *ngIf="
          principal.hasRoles(['Site Admin', 'Access Cilajet Form']) &&
          principal.hasUserViewAccess(['Site Admin', 'Dealer', 'DealFi'])
        "
        mat-menu-item
        (click)="openCilajetOrderFormDialog()"
        name="cilajet-order-form">
        Cilajet Order Form
      </a>
      <a
        mat-menu-item
        routerLink="/faq"
        routerLinkActive="active"
        (click)="sidenav.close()"
        name="faq">
        Faq's
      </a>
      <a
        mat-menu-item
        routerLink="/videos"
        routerLinkActive="active"
        (click)="sidenav.close()"
        name="videos">
        Videos
      </a>
    </mat-menu>
    <a
      mat-button
      routerLinkActive="active"
      [matMenuTriggerFor]="agentToolsMenu"
      #salesTop="matMenuTrigger"
      *ngIf="
        principal.hasUserViewAccess(['MGA', 'Site Admin', 'Agent', 'Sub Agent'])
      "
      name="{{ leftAlign ? 'mobile-' : '' }}agent-tools-menu">
      agent tools
      <mat-icon aria-hidden="true">arrow_drop_down</mat-icon>
    </a>
    <a
      *ngIf="principal.hasRoles(['Customer'], false)"
      mat-button
      ngStyle.lt-lg="margin-top:1em"
      ngStyle.gt-md="margin-left:1em;"
      class="link-button"
      title="Merchandise Portal"
      href="https://ois.alphawarranty.com/login?token={{ token }}"
      name="{{ leftAlign ? 'mobile-' : '' }}merchandise-portal">
      Merchandise Portal
    </a>

    <a
      mat-button
      routerLinkActive="active"
      ngStyle.gt-md="margin-left:1em;"
      ngStyle.lt-lg="margin-top:1em"
      class="link-button"
      routerLink="/quicktrack"
      queryParamsHandling="merge"
      (click)="sidenav.close()"
      name="{{ leftAlign ? 'mobile-' : '' }}quicktrack">
      quicktrack
    </a>
  </div>
</ng-template>

<ng-template #topMenu let-direction="direction" let-leftAlign="left">
  <div [fxLayout]="direction" [ngClass]="{ 'menu-align-left': leftAlign }">
    <div fxLayoutAlign.lt-lg="space-between center">
      <h3
        style="margin: 0; color: #41b0db; padding-left: 1em"
        *ngIf="leftAlign">
        MENU
      </h3>
      <a
        mat-icon-button
        routerLinkActive="active"
        style="color: #545759; border: 2px solid #545759"
        [matMenuTriggerFor]="profileMenu"
        ngStyle.gt-md="margin-left: 1em"
        ngStyle.lt-lg="margin-right:1em"
        name="{{ leftAlign ? 'mobile-' : '' }}profile-menu">
        <mat-icon style="height: 27px" class="material-icons-outlined"
          >person</mat-icon
        >
      </a>
    </div>
    <mat-menu #profileMenu>
      <a
        mat-menu-item
        routerLink="settings/account"
        routerLinkActive="active"
        name="my-profile">
        my profile
      </a>
      <a
        *ngIf="
          principal.hasRoles(['Site Admin', 'Internal Cancellation'], false)
        "
        mat-menu-item
        title="Internal"
        routerLinkActive="active"
        [matMenuTriggerFor]="internal"
        #internalTop="matMenuTrigger"
        name="internal-menu">
        Site Admin/Internal
      </a>
      <mat-menu #internal>
        <a
          *ngIf="principal.hasRoles(['Site Admin', 'Internal Cancellation'])"
          mat-menu-item
          routerLink="/cancellation/initiate"
          name="internal-cancellation">
          Internal Cancellation
        </a>
        <a
          mat-menu-item
          routerLink="/impersonate"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="impersonate">
          Impersonate
        </a>
        <a
          mat-menu-item
          routerLink="site-admin/users"
          *ngIf="principal.hasRoles(['Site Admin', 'Manage Users'], false)"
          name="users">
          users
        </a>
        <a
          mat-menu-item
          routerLink="site-admin/users/create"
          *ngIf="principal.hasRoles(['Site Admin', 'Add User'], false)"
          name="add-user">
          add user
        </a>
        <a
          mat-menu-item
          routerLink="site-admin/manage-roles"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="zoom-roles">
          zoom roles
        </a>
        <a
          mat-menu-item
          routerLink="settings/email-settings"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          routerLinkActive="active"
          name="email-settings">
          email settings
        </a>
        <a
          mat-menu-item
          routerLink="settings/enhanced-features"
          *ngIf="principal.hasRoles(['Site Admin', 'Enhanced Features'], false)"
          routerLinkActive="active"
          name="enhanced-features">
          enhanced features
        </a>
        <a
          mat-menu-item
          routerLink="marketing-materials/pending-orders"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="pending-orders">
          Pending Material Orders
        </a>
        <a
          *ngIf="principal.hasRoles(['Site Admin'])"
          mat-menu-item
          routerLink="/marketing-materials/shipping-report"
          name="shipping-report">
          Marketing Materials Shipping Report
        </a>

        <a
          mat-menu-item
          routerLink="/notifications"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="quicksend">
          QuickSend
        </a>

        <a
          mat-menu-item
          routerLink="quicksync"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="quicksync">
          quicksync
        </a>
        <a
          mat-menu-item
          routerLink="site-admin/quickreinstate"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="quickreinstate">
          QuickReinstate
        </a>
        <a
          mat-menu-item
          routerLink="tooltips"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="tooltips">
          tooltips
        </a>
        <a
          mat-menu-item
          routerLink="/quicksign/dealer-assignment"
          *ngIf="principal.hasRoles(['Site Admin'], false) && notReadyForLive"
          name="quicksign-dealer-assignment">
          quicksign dealer assignment
        </a>
        <a
          mat-menu-item
          routerLink="settings/third-party-financing"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          routerLinkActive="active"
          name="third-party-financing">
          Third Party Financing
        </a>
        <a
          mat-menu-item
          routerLink="settings/service-plus"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          routerLinkActive="active"
          name="service-plus">
          service plus
        </a>
        <a
          mat-menu-item
          routerLink="settings/driveeasy"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          routerLinkActive="active"
          name="driveeasy">
          driveeasy
        </a>

        <a
          mat-menu-item
          routerLink="/reports/tca-conversion"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="tca-conversion">
          TCA Conversion
        </a>
        <a
          mat-menu-item
          routerLink="/reports/pending-dealer-attrition"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="pending-dealer-attrition">
          Pending Dealer Attrition
        </a>
        <a
          mat-menu-item
          routerLink="call-center"
          *ngIf="principal.hasRoles(['Site Admin'], false)"
          name="call-center">
          Call Center
        </a>
      </mat-menu>
      <a
        mat-menu-item
        title="Logout"
        routerLink="../"
        (click)="logoff()"
        name="{{ leftAlign ? 'mobile-' : '' }}logout">
        LogOut
      </a>
    </mat-menu>
    <button
      mat-icon-button
      color="primary"
      [matMenuTriggerFor]="notificationMenu"
      *ngIf="!principal.hasRoles(['Site Admin'])"
      name="{{ leftAlign ? 'mobile-' : '' }}notification-menu">
      <mat-icon
        class="material-icons-outlined"
        [matBadge]="notificationBadgeCount"
        matBadgeColor="warn"
        matBadgeSize="small"
        >notifications</mat-icon
      >
    </button>
  </div>
</ng-template>

<mat-menu #notificationMenu="matMenu">
  <div *ngFor="let notification of notifications; index as i">
    <button
      mat-menu-item
      (click)="openNotificationDialog(notification)"
      [ngClass]="{ unread: !notification.dateOpened }"
      name="notification-item-{{ i }}">
      {{ getNotificationTitle(notification.cmsNotificationId) }}
    </button>
  </div>
</mat-menu>

<mat-menu #contractsMenu>
  <a
    *ngIf="principal.hasRoles(['Site Admin', 'Enter Contract'])"
    mat-menu-item
    routerLink="/contract/rate"
    routerLinkActive="active"
    name="contract-entry">
    Contract Entry
  </a>
  <a
    mat-menu-item
    routerLink="/contracts"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    name="contract-search">
    Contract Search
  </a>
  <a mat-menu-item (click)="routeHome()" name="new-quote"> New Quote </a>

  <a
    mat-menu-item
    routerLink="/contracts/saved-quotes"
    routerLinkActive="active"
    name="saved-quotes">
    Saved Quotes
  </a>
</mat-menu>

<mat-menu #reportingMenu>
  <a mat-menu-item routerLink="/reports/sales-overview" name="sales-overview">
    Sales Overview
  </a>
  <a mat-menu-item routerLink="/reports/product-sales" name="product-sales">
    Product Sales
  </a>
  <a
    mat-menu-item
    routerLink="/reports/sales-comparison"
    name="vsc-sales-comparison">
    VSC Sales Comparison
  </a>
  <a mat-menu-item routerLink="/reports/user-summary" name="user-summary">
    User Summary
  </a>
  <a mat-menu-item routerLink="/reports/claims-paid" name="claims-paid">
    Claims Paid
  </a>
</mat-menu>

<mat-menu #agentToolsMenu>
  <a
    *ngIf="principal.hasRoles(['Site Admin', 'Create New Dealer Online'])"
    mat-menu-item
    routerLink="/quicksign"
    (click)="sidenav.close()"
    name="quicksign">
    <img height="40" src="/assets/quicksign-logo-gray.png" alt="quicksign" />
  </a>
  <a
    *ngIf="principal.hasRoles(['Site Admin', 'Agent Dashboard'])"
    mat-menu-item
    routerLinkActive="active"
    routerLink="/dashboard"
    queryParamsHandling="merge"
    (click)="sidenav.close()"
    name="agent-dashboard"
    >Agent dashboard</a
  >
  <a
    *ngIf="principal.hasRoles(['Site Admin', 'DealFi Dashboard'])"
    mat-menu-item
    target="_blank"
    [href]="getDealFiAgentReportUrl()"
    name="dealfi-dashboard">
    DealFi Dashboard
  </a>
  <a
    *ngIf="principal.hasRoles(['Site Admin', 'Rate Card Generator'])"
    mat-menu-item
    routerLink="/ratecard"
    (click)="sidenav.close()"
    name="rate-card-generator">
    rate card generator
  </a>
  <a
    *ngIf="
      principal.hasUserViewAccess(['MGA', 'Site Admin', 'Agent', 'Sub Agent'])
    "
    mat-menu-item
    routerLink="/marketing-materials"
    (click)="sidenav.close()"
    name="marketing-materials">
    order marketing materials
  </a>
  <a
    *ngIf="
      principal.hasRoles(['Site Admin', 'Access Cilajet Form']) &&
      principal.hasUserViewAccess(['Site Admin', 'MGA', 'Agent', 'Sub Agent'])
    "
    mat-menu-item
    (click)="openCilajetOrderFormDialog()"
    name="cilajet-order-form">
    Cilajet Order Form
  </a>
  <a
    *ngIf="principal.hasRoles(['Site Admin', 'Retro Dashboard'])"
    mat-menu-item
    routerLink="/wealth-dashboards/retro-wealth-dashboard"
    (click)="sidenav.close()">
    Retro Dashboard
  </a>
  <a
    *ngIf="principal.hasRoles(['Site Admin', 'Agent View Remittance'])"
    mat-menu-item
    routerLink="/remittance/agent"
    (click)="sidenav.close()"
    name="agent-remittance">
    agent remittance
  </a>
  <a mat-menu-item routerLink="/faq" (click)="sidenav.close()" name="faq">
    Faq's
  </a>
  <a mat-menu-item routerLink="/videos" (click)="sidenav.close()" name="videos">
    Videos
  </a>
  <a
    mat-menu-item
    routerLink="settings/agent-claim-alerts"
    *ngIf="principal.hasUserViewAccess(['Site Admin', 'MGA', 'Agent'])"
    routerLinkActive="active"
    name="agent-claim-alerts">
    agent claim alerts
  </a>
  <a
    *ngIf="principal.hasRoles(['Site Admin', 'Business Review'])"
    mat-menu-item
    routerLink="/business-reviews"
    routerLinkActive="active"
    (click)="sidenav.close()"
    name="business-review">
    business review (beta)
  </a>
</mat-menu>
