import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZoomContractService {
  private endpoint: string;

  constructor(private http: HttpClient) {
    this.endpoint = environment.dashboardEndpoint + environment.version;
  }

  contractDetails(contractNumber: string) {
    return this.http.get<any>(`${this.endpoint}contract/${contractNumber}/details`);
  }

  downloadContractPdf(contractNumber: string, isScsContract: string) {
    return this.http
      .get<Blob>(
        `${this.endpoint}contract/${contractNumber}/download`,{
          params: {isScsContract},
          responseType: 'blob' as 'json',
        }
      );
  }

  requestContractPdf(contractNumber: string) {
    return this.http.post(`${this.endpoint}contract/${contractNumber}/request-pdf`, {});
  }

  voidContract(contractNumber: string) {
    return this.http.delete<any>(`${this.endpoint}contract/${contractNumber}/void`);
  }

  removeDuplicate(oldContract: string, newContract: string){
    return this.http.delete<any>(
      `${this.endpoint}contract/delete-duplicate`,
      {
        params: {
          oldContractNumber: oldContract,
          newContractNumber: newContract
        }
      }
    )
  }
}
