<p>Are you sure you want to submit this override?</p>
<div fxFlexLayout="row" fxLayoutGap="1em" fxLayoutAlign="center center">
    <button
    mat-raised-button
    class="decoration-none white"
    color="primary"
    (click)="onCancelClick()"
    >
        Cancel
    </button>
    <button
    mat-raised-button
    class="decoration-none white"
    color="primary"
    (click)="onConfirmClick()"
    >
        Submit
    </button>
</div>
