export interface Overbill {
  overbillId: any;
  agreementDate: Date;
  payeeFirstName: string;
  payeeLastName: string;
  email: string;
  businessName: string;
  exemptPayeeCode: string;
  fatca: string;
  overbillCommissions: AlphaProgram[];
  taxClassification: string;
  llcClass: string;
  otherClassificationDesc: string;
  ssn: string;
  ein: string;
  file: string;
  address: Address;
  signerFirstName: string;
  signerLastName: string;
  signerTitle: string;
  signedDate: Date;
}

export class OverbillResults {
  dealerProfileId: any;
  email: string;
  overbillForm: string;
  agreementDate: Date;
}

export class SimpleDealerProfile {
  agentNumber: string;
  dealershipName: string;
  dealerSellingState: string;
  primaryContactEmail: string;
  useDefaultCommissionRate: boolean;
  gapProviderId: number;
  overbills: any[];
  agentEmail: string;
  isFranchise: string;
  dealerCommissions: AlphaProgram[];
  requiredForms: FormType[];
  dealerProfileId: string;
  federalTaxId: string;
  dealerInformationId: number;
  programs: any[];
  signerName: string;
  signerTitle: string;
  file: string;
  signedDate: Date;
  dealerSignatureId: number;
  onAgentReinsurance: boolean | null;
  servicePlusOptions: any[];
  hasRepairFacility: boolean;
}

export class AlphaProgram {
  programTermId: number;
  program: string;
  context: string;
  provider: string;
  isFranchise: boolean;
  alphaProgramId: number;
  dealerUseMax: boolean;
  dealerAmount: any;
  overbillAmount: any;
}

export class FormType {
  formTypeId: number;
  formName: string;
  displayName: string;
}

export class ProgramTerms {
  alphaProgramId: number;
  program: string;
  context: string;
  coverageGroupId: number;
  commissionMinimum: number;
  terms: Term[];
}

export class Term {
  programTermId: number;
  term: string;
  commissionMinimum: number;
}

export class GapProviders {
  gapProviderId: number;
  provider: string;
}

export class FloridaCommissionOptions {
  id: number;
  name: string;
}

export class DealerProfile {
  constructor() {
    // tslint:disable-next-line: no-use-before-declare
    this.repairFacility = new RepairFacility();
    // tslint:disable-next-line: no-use-before-declare
    this.mailingAddress = {} as Address;
    // tslint:disable-next-line: no-use-before-declare
    this.billingAddress = {} as Address;
    // tslint:disable-next-line: no-use-before-declare
    this.floridaDealerDetail = new FloridaDetail();
    // tslint:disable-next-line: no-use-before-declare
    this.driveEasyDetail = new DriveEasyDetail();
  }
  dealershipName: string;
  dealershipGroupName: string;
  phone: string;
  isFranchise: boolean;
  federalTaxId: string;
  primaryContactEmail: string;
  billingEmail: string;
  billingAddress: Address;
  principalOwnerFirstName: string;
  principalOwnerLastName: string;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  principalOwnerEmail: string;
  principalOwnerPhone: string;
  principalOwnerExtension: string;
  primaryContactPhone: string;
  primaryContactExtension: string;
  businessManager: string;
  serviceManager: string;
  fandIManager: string;
  yearsInBusiness: number;
  hasRepairFacility: boolean;
  repairFacility: RepairFacility;
  mailingAddress: Address;
  website: string;
  fax: string;
  averageVehiclesSold: number;
  projectedSales: number;
  averageInventory: number;
  averageSoldMileage: number;
  averageVehicleYear: number;
  isZoomMenuSystem: boolean;
  otherMenuSystemId: number;
  dealerUsers: DealerUser[];
  floridaDealerDetail: FloridaDetail;
  driveEasyDetail: DriveEasyDetail;
  commissionPrograms: CommissionProgramInput[];
}

export interface Address {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

export class RepairFacility {
  name: string;
  laborRate: number;
  partTax: number;
  laborTax: number;
  serviceManager: string;
  serviceCenterAddress: Address;
}

export class DealerUser {
  email: string;
  firstName: string;
  lastName: string;
}

export class AgentDealerInfo {
  dealerState: string;
  dealerEmail: string;
}

export class FloridaDetail {
  licenseNumber253: string;
  noticeToDealer: boolean;
}

export class DriveEasyDetail {
  hasNew: boolean;
  hasUsed: boolean;
  hasHighMileage: boolean;
  hasDriveEasyWrap: boolean;
  warrantyEligibilityTypeId: number;
}

export class Agreement {
  dealerProfileId: string;
  effectiveDate: string;
  agreementDate: string;
  signerFirstName: string;
  signerLastName: string;
  signerTitle: string;
  signedDate: string;
  file: string;
}

export class CommissionProgramInput {
  alphaProgramId: number;
  dealerUseMax: boolean;
  dealerAmount: any;
  overbillAmount: any;
  reserveFundAmount: any;
}

export class WarrantyEligibilityType {
  warrantyEligibilityTypeId: number;
  name: string;
}

export interface OtherMenuSystem {
  id: number;
  name: string;
}

export class AgreementViewModel {
  dealerInformation: DealerProfile;
  agreement: Agreement;
  address: Address;
}

export class Dealer {
  idealer_id: number;
  sdealer_number: string;
  sdealer_name: string;
  sdealer_address_1: string;
  sdealer_address_2: string;
  sdealer_city: string;
  sdealer_state: string;
  sdealer_zip_code: string;
  sdealer_phone_number: string;
  sdealership_contact: string;
  sdealer_status: string;
  sfederal_tax_id: string;
  cposted_labor_rate: any;
  sinsured_state: string;
  ndealer_tax_rate1: any;
  cdealer_labor_rate: any;
  sdealer_principal: string;
  sdealer_gen_manager: string;
  sdealer_sales_manager: string;
  sdealer_bus_manager: string;
  sdealer_service_manager: string;
  sdealer_act_payable: string;
  sdealer_primary_contact: string;
  sdealer_fi_manager: string;
  sdealer_fax: string;
  sdealer_email: string;
  sdealer_contact_other: string;
  sdealer_web_page: string;
  sdealer_mail_addr1: string;
  sdealer_mail_addr2: string;
  sdealer_mail_city: string;
  sdealer_mail_state: string;
  sdealer_mail_zip_code: string;
  sdealer_legal_name: string;
  dtCreated: Date;
  sservicer_fax: string;
  sservicer_email: string;
}

export class DealerAuthPrograms {
  program: string;
  contractTypeDesc: string;
  productType: string;
}

export class AddProgramModel {
  program: string;
  commission: any;
}

export class EditPrograms {
  dealer: string;
  dealerNo: string;
  requestedBy: string;
  addPrograms: AddProgramModel[];
  removePrograms: string[];
}

export class AddUserModel {
  dealer: string;
  dealerNo: string;
  requestedBy: string;
  dealerUser: DealerUser[];
}

export class ModifyDefaultCommissionModel {
  agentNumber: string;
  fullName: string;
  signature: string;
  currentCommission: string;
  signedDate: string;
  file: string;
}

export class ModifyAgentReservesModel {
  agentNumber: string;
  signature: string;
  modifiedReserves: Reserve[];
}

export class Reserve {
  CombinedDesc: string;
  CoverageGroup: string;
  CoverageGroupId: number;
  TermGroup: string;
  TermGroupId: number;
  OldReserve: number;
  NewReserve: number;

  constructor(
    combinedDesc: string,
    coverageGroup: string = '',
    coverageGroupId: number,
    termGroup: string = '',
    termGroupId: number,
    oldReserve: number = 0,
    newReserve: number = 0,
  ) {
    this.CombinedDesc = combinedDesc;
    this.CoverageGroup = coverageGroup;
    this.CoverageGroupId = coverageGroupId;
    this.TermGroup = termGroup;
    this.TermGroupId = termGroupId;
    this.OldReserve = oldReserve;
    this.NewReserve = newReserve;
  }
}
