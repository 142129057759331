import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PrincipalService } from './principal.service';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthorizedGuard implements CanActivate {
  constructor(private principal: PrincipalService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    // check to see if you authenticated
    // with is the permission of being authorized -- maybe rename to AuthenticatedGuard?
    // NOTE: here is where we CAN do redirect based on expecting you to be logged in
    // canActivate will just prevent redirection with NO errors
    if (!this.principal.isAuthenticated) {
      if (!environment.production) {
        // adding a debug console warning for dev
        console.warn('Not Authenticated');
      }
      // redirect to login page
      return this.router.navigate(['/login']);
    }
    return true;
  }
}
