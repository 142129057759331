import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { PageViewService } from 'src/shared/page-view.service';
import { PrincipalService } from '../shared/principal.service';
import { filter, pairwise } from 'rxjs/operators';
import { DealerService } from 'src/shared/dealer.service';

declare let gtag: Function;
declare const icPatronChat: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private principal: PrincipalService,
    private pageViewService: PageViewService,
    private dealerService: DealerService,
  ) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof NavigationEnd))
      .subscribe({
        next: (event: NavigationEnd) => {
          if (this.principal.hasUserViewAccess(['RF'])) {
            if (!this.router.url.includes('/rf/'))
              this.router.navigate(['/rf404']);
          } else if (
            this.principal.hasUserViewAccess(['Dealer']) ||
            this.principal.hasUserViewAccess(['Agent'])
          ) {
            var waitTimeInSec = 20;
            this.dealerService.checkForDealerSurvey(waitTimeInSec);
          } else {
            gtag('config', 'G-89LEFTWCQ3', {
              page_path: event.urlAfterRedirects,
            });
          }
        },
      });
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        if (
          this.principal.isAuthenticated &&
          events[0].urlAfterRedirects.split('?')[0] !==
            events[1].urlAfterRedirects.split('?')[0]
        ) {
          this.pageViewService
            .logView(
              this.principal.id,
              events[1].urlAfterRedirects.split('?')[0],
            )
            .subscribe();
        }
      });
  }

  async ngOnInit() {
    try {
      this.removeResidualChat();
      await this.loadExternalChatScript();
      await this.initializePatronChat();
    } catch (error) {
      console.error('Chat initialization or script loading failed:', error);
    }
  }

  async loadExternalChatScript() {
    return new Promise<void>((resolve, reject) => {
      let script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://home-c54.nice-incontact.com/inContact/ChatClient/js/embed.min.js`;
      script.onload = () => {
        resolve();
      };
      script.onerror = (error) => {
        reject(error);
      };
      this.renderer.appendChild(document.body, script);
    });
  }

  async initializePatronChat() {
    return new Promise<void>((resolve, reject) => {
      icPatronChat.init({
        serverHost: 'https://home-c54.nice-incontact.com',
        bus_no: 4604375,
        poc: '85ff4c00-80ed-40f6-a2c4-940b29873980',
        onInitialized: () => {
          resolve();
        },
        onError: (error: any) => {
          reject(error);
        },
      });
    });
  }

  removeResidualChat() {
    const chat = document.getElementsByClassName('button-iframe-wrap')[0];
    if (chat) {
      chat.remove();
    }
  }
}
