export class SiriusXMRealTimeModel {
  vin: string;
  firstName: string;
  lastName: string;
  address1: string;
  city: string;
  state: string;
  customerCountry: string;
  zip: string;
  consumerEmail: string;
  homePhone: string;
  corpId: string;
  dealerId: string;
  contractNumber: string;
}

export class SiriusXMRealTimeResponseModel {
  deviceId: string;
  isEligible: string;
  url: string;
  resultCode: string;
  errorCode: string | any;
  errorMessage: string | any;
}

