import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PageViewService {
  private endPoint: string;

  constructor(private httpClient: HttpClient) {
    this.endPoint = `${environment.zoomEndpoint}api/page-views`;
  }

  logView(userId: any, route: any) {
    const date = new Date();
    return this.httpClient.post<any>(`${this.endPoint}/log`, {
      userId: userId,
      route: route,
      timeStamp: date.toJSON(),
    });
  }
}
