import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PrincipalService } from './principal.service';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private principal: PrincipalService, private loader: LoadingService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loader.show();
    const map = request.url.match(
      /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/im
    );
    if (
      (map && map.some((x) => x == 'blog.alphawarranty.com')) ||
      request.url.includes('api.zippopotam.us')
    ) {
    } else if (this.principal.isAuthenticated) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.principal.token}`,
          'zoom-scs-number': this.principal.scsNumber,
        },
      });
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.loader.hide();
        this.principal.setAlphaOrAlpine();
      })
    );
  }
}
