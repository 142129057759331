<div>
  <p>
    <strong>OVERRIDE </strong>
    <sub class="subtext">Override a claim using your premium reserve.</sub>
  </p>
</div>

<div>
  <form [formGroup]="overrideForm">
    <mat-form-field appearance="outline">
      <mat-label>Your Name</mat-label>
      <input formControlName="name" matInput required />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Your Title</mat-label>
      <input formControlName="title" matInput required />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Who is getting paid?</mat-label>
      <mat-select formControlName="paymentRecipient" matInput required>
        <mat-option [value]="'Shop'">Shop</mat-option>
        <mat-option [value]="'Customer'">Customer</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Amount</mat-label>
      <span matPrefix>$&nbsp;</span>
      <input
        input
        type="decimal"
        max="2147483647"
        formControlName="paymentAmount"
        matInput
        required
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Reason(s) for the Override Request (Ex. Cover Engine, Extra Day of Rental, Cover Fluids)</mat-label>
      <textarea
        formControlName="additionalComments"
        matInput
        rows="2"
        required
      ></textarea>
    </mat-form-field>
  </form>
</div>
<div
  fxLayout="row"
  fxLayoutGap="1em"
  style="justify-content: center"
  mat-dialog-actions
>
  <div>
    <button
      mat-raised-button
      class="decoration-none white"
      color="primary"
      (click)="onCancelClick()"
    >
      Cancel
    </button>
  </div>
  <div>
    <button
      mat-raised-button
      class="decoration-none white"
      color="primary"
      (click)="openConfirmDialog()"
      [disabled]="!overrideForm.valid"
    >
      SUBMIT
    </button>
  </div>
</div>
