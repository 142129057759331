import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ScsDetail, UserService } from '../user.service';

@Component({
  selector: 'zoom-scs-mga-select',
  templateUrl: './mga-select.component.html',
  styleUrls: ['./mga-select.component.css']
})
export class MgaSelectComponent implements OnInit {
  filteredMgas: Observable<ScsDetail[]>;
  filterInput = new FormControl(null);

  @Input('required')
  controlRequired = false;

  @Input('label')
  formLabel = 'Agent';

  @Input('bindFormControl')
  formControl = new FormControl();

  constructor(private user: UserService) { }

  ngOnInit(): void {
    this.filteredMgas = this.filterInput.valueChanges.pipe(
      debounceTime(500),
      switchMap((value: string | ScsDetail | null) => {
        if (value && (value as string).length > 2) {
          return this.user.mgaScsAccessFilter(value as string);
        }
        return EMPTY;
      })
    );
    const existing = this.formControl.value;
    if (existing) {
      this.user.preloadMgaScsAccessFilter(existing)
        .subscribe({
          next: result => {
            this.filterInput.setValue(result);
          }
        });
      }
  }

  displayWith(value: ScsDetail | string): string {
    if (value && (value as ScsDetail).scsNumber) {
      const detail = value as ScsDetail;
      return `${detail.scsNumber} - ${detail.name}`;
    }
    return value as string;
  }

  optionSelected() {
    this.formControl.setValue(this.filterInput.value.scsNumber);
  }
}

