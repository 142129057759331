import { Injectable } from '@angular/core';
import { interval, Subject, Subscription, timer } from 'rxjs';
import { environment } from '../environments/environment';
import { Principal } from './principal.model';
import { UsersService } from './users.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PrincipalService {
  private uuidInstance: string;
  private principalKey = 'user_principal';
  private uuidKey = 'generated_uuid';
  private scsDealerKey = 'colby_needs_help';

  private session: Session = this.defaultSession();
  private timer: Subscription;
  private authenticatedSubject = new Subject<boolean>();

  constructor(
    private usersService: UsersService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    const rawData = localStorage.getItem(this.principalKey);
    if (rawData) {
      this.set(JSON.parse(rawData));
    }
    const storedUUID = localStorage.getItem(this.uuidKey);
    if (storedUUID) {
      this.setUUID(storedUUID);
    }
  }

  get token() {
    return this.session.access_token;
  }

  get userName() {
    return this.session.claims.unique_name;
  }

  get actorUserName() {
    return this.session.claims.unique_name;
  }

  get firstName() {
    return this.session.claims.given_name;
  }

  get lastName() {
    return this.session.claims.family_name;
  }

  get id() {
    return this.session.claims.nameid;
  }

  get isAuthenticated() {
    return !!this.session.access_token;
  }

  get scsNumber() {
    return localStorage.getItem(this.scsDealerKey);
  }

  set scsNumber(value: string) {
    localStorage.setItem(this.scsDealerKey, value);
    this.usersService.updateUserLastDealerNumber(this.id, value).subscribe();
    if (!environment.production) {
      console.log('Setting ScsNumber: ', value);
    }
  }

  get authenticationMethod() {
    return this.session.claims.sign_in;
  }

  get uuid() {
    return this.uuidInstance;
  }

  get hasImpersonation() {
    return this.session.hasImpersonation;
  }

  get userViewAccessType() {
    return this.session.claims.user_view_access_type;
  }
  get phoneNumber() {
    return this.session.claims.phone_number;
  }
  clear() {
    if (this.timer) {
      this.timer.unsubscribe();
      this.timer = undefined;
    }
    this.session = this.defaultSession();
    this.authenticatedSubject.next(false);
    localStorage.removeItem(this.principalKey);
    localStorage.removeItem(this.scsDealerKey);
  }

  flush(all: boolean = false) {
    if (!environment.production) {
      console.log('*Flushing');
    }
    localStorage.removeItem(this.principalKey);
    if (all) {
      if (!environment.production) {
        console.log('*Flushing ALL');
      }
      localStorage.removeItem(this.uuidKey);
      localStorage.removeItem(this.scsDealerKey);
    }
  }

  hasRoles(roles: string[]) {
    return this.session.roles.some((sr) => roles.some((r) => sr == r));
  }

  hasUserViewAccess(uvas: string[]) {
    return uvas.some((u) => u === this.session.claims.user_view_access_type);
  }

  isAlphaorAlpine() {
    return this.session.alphaOrAlpine;
  }

  set(principal: Principal) {
    localStorage.setItem(this.principalKey, JSON.stringify(principal));
    const claims = JSON.parse(atob(principal.token.split('.')[1]));
    let actorClaims: any | null;
    let sessionRoles = [];
    let roleRef = claims.role;
    if (claims.actort) {
      actorClaims = JSON.parse(atob(claims.actort.split('.')[1]));
      roleRef = actorClaims.role;
    }
    if (roleRef && roleRef instanceof Array) {
      sessionRoles = roleRef;
    } else if (roleRef) {
      sessionRoles.push(roleRef);
    }

    var alphaOrAlpine = null;
    var alphaOrAlpineCookieExists = this.cookieService.check('alphaOrAlpine');
    
    if(alphaOrAlpineCookieExists){
      alphaOrAlpine = this.cookieService.get('alphaOrAlpine');
    }

    this.session = {
      access_token: principal.token,
      claims: actorClaims ? actorClaims : claims,
      roles: sessionRoles,
      hasImpersonation: !!actorClaims,
      alphaOrAlpine: alphaOrAlpineCookieExists ? alphaOrAlpine : '',
    };
    const expiry = new Date(claims.exp * 1000 - 60000);
    if (!environment.production) {
      console.log('Setting Principal: ', principal);
      console.log('Claims: ', this.session.claims);
      console.log('Expires: ', expiry.toLocaleString());
      if (this.hasImpersonation) {
        console.log('Impersonation Active');
      }
    }
    this.authenticatedSubject.next(true);
    timer(expiry).subscribe({
      next: () => this.clear(),
    });
    if (!this.scsNumber) {
      this.scsNumber = principal.lastScsNumber;
    }
  } 

  setAlphaOrAlpine(){
    if (!this.session.alphaOrAlpine) { 
      this.usersService.isAlphaOrAlpineToolsEndpoint().subscribe({ 
        next: (response) => { 
          var alphaOrAlpine = response.content; 
          this.cookieService.set('alphaOrAlpine', alphaOrAlpine); 
          this.session.alphaOrAlpine = alphaOrAlpine;
        }, 
      }); 
    } 
  }

  authenticated() {
    return this.authenticatedSubject.asObservable();
  }

  setUUID(id: string) {
    if (!environment.production) {
      console.log('Current UUID: ', id);
    }
    localStorage.setItem(this.uuidKey, id);
    this.uuidInstance = id;
  }

  private defaultSession(): Session {
    return {
      access_token: null,
      claims: {},
      hasImpersonation: false,
      roles: [],
      alphaOrAlpine: '',
    };
  }
}

interface Session {
  access_token: string | null;
  claims: any;
  hasImpersonation: boolean;
  roles: any[];
  alphaOrAlpine: string;
}
