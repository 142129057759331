import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PrincipalService } from '../principal.service';
import { UserService, ScsDetail } from '../user.service';
import { UsersService } from '../users.service';

@Component({
  selector: 'zoom-dealer-select-form-control-editable',
  templateUrl: './dealer-select-form-control-editable.component.html',
  styleUrls: ['./dealer-select-form-control-editable.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DealerSelectFormControlEditableComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DealerSelectFormControlEditableComponent,
    },
  ],
})
export class DealerSelectFormControlEditableComponent
  implements ControlValueAccessor, Validator, OnInit
{
  scsAccessFilter: Observable<any[]>;
  inputFilter = new FormControl();

  @Input('label')
  label: string = 'Dealer';

  @Input('required')
  isRequired: string | boolean = false;

  @Input('includeDFI')
  includeDFI: boolean = true;

  @Input('onlyActiveDealers')
  onlyActiveDealers: boolean = false;

  disabled = false;
  touched = false;

  onChange: (value: string) => any;
  onTouched: () => void;
  onValidate: () => void;

  constructor(
    public principal: PrincipalService,
    private userService: UserService,
    private users: UsersService
  ) {}

  ngOnInit(): void {
    this.scsAccessFilter = this.inputFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap((value: string | ScsDetail) => {
        if (!value) {
          return this.userService.scsAccessFilter(
            '',
            this.includeDFI,
            this.onlyActiveDealers
          );
        }
        if (value && (value as string).length > 0) {
          return this.userService.scsAccessFilter(
            value as string,
            this.includeDFI,
            this.onlyActiveDealers
          );
        }
        return EMPTY;
      })
    );
    if (
      !this.label ||
      this.label.length === 0 ||
      this.label == '' ||
      this.label == ''
    ) {
      this.label = null;
    }
  }

  displayWith(value: ScsDetail): string | ScsDetail {
    if (value && value.scsNumber) {
      return `${value.scsNumber} - ${value.name}`;
    }
    return value;
  }

  private loadFilter(scsNumber: string): void {
    if (!scsNumber || scsNumber == 'undefined' || scsNumber == 'null') {
      this.users.user(this.principal.id).subscribe({
        next: (res) => {
          scsNumber = res.assignedDealerNum;
          this.principal.scsNumber = res.assignedDealerNum;
          this.loadFilter(res.assignedDealerNum);
        },
      });
    }
    this.userService.preloadDealerScsAccessFilter(scsNumber).subscribe({
      next: (result) => {
        this.inputFilter.setValue(result);
        this.principal.scsNumber = result.scsNumber;
        this.onChange(result.scsNumber);
      },
    });
  }

  optionSelected(input: HTMLInputElement): void {
    this.markAsTouched();
    if (this.disabled) {
      return;
    }
    const selectedValue = this.inputFilter.value.scsNumber;
    this.principal.scsNumber = selectedValue;

    // Align the seleted option to the left
    input.blur();
    input.setSelectionRange(0, 0);
    input.focus();

    this.onChange(selectedValue);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.loadFilter(obj);
    } else if (
      this.principal.scsNumber &&
      this.principal.scsNumber.length > 3
    ) {
      this.loadFilter(this.principal.scsNumber);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    const value = control.value;
    if (!value) {
      return {
        dealerNumber: {
          required: 'A dealer number must be selected',
        },
      };
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidate = fn;
  }

  clearText() {
    this.inputFilter.patchValue(null);
  }
}
