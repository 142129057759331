import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleChartsModule, ScriptLoaderService } from 'angular-google-charts';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { ChipDealerSelectComponent } from './chip-dealer-select/chip-dealer-select.component';
import { DealerSelectComponent } from './dealer-select/dealer-select.component';
import { SafePipe } from './safe.pipe';
import { SignatureFieldComponent } from './signature-field/signature-field.component';
import { ProgramSelectComponent } from './program-select/program-select.component';
import { NgxMaskModule } from 'ngx-mask';
import { TermMileagePipe } from './term-mileage.pipe';
import { AgentSelectComponent } from './agent-select/agent-select.component';
import { MgaSelectComponent } from './mga-select/mga-select.component';
import { AnnualVscSalesComponent } from '../dashboard/annual-vsc-sales/annual-vsc-sales.component';
import { SignatureFormControlComponent } from './signature-form-control/signature-form-control.component';
import { DealerSelectFormControlComponent } from './dealer-select-form-control/dealer-select-form-control.component';
import { SignatureDialogComponent } from './signature-dialog/signature-dialog.component';
import { DealerMultiSelectComponent } from './dealer-multi-select/dealer-multi-select.component';
import { DealerSelectFormControlEditableComponent } from './dealer-select-form-control-editable/dealer-select-form-control-editable.component';
import { DealerFilteredMultiSelectComponent } from './dealer-filtered-multi-select/dealer-filtered-multi-select.component';
import { AgentFilteredMultiselectComponent } from './agent-filtered-multi-select/agent-filtered-multi-select.component';
import { DealerFilteredChipSelectComponent } from './dealer-filtered-chip-select/dealer-filtered-chip-select.component';
import { AgentFilteredChipSelectComponent } from './agent-filtered-chip-select/agent-filtered-chip-select.component';
import { QuicktrackTableComponent } from './quicktrack-table/quicktrack-table.component';
import { QuicktrackTilesComponent } from './quicktrack-tiles/quicktrack-tiles.component';
import { QuicktrackClaimDetailComponent } from './quicktrack-claim-detail/quicktrack-claim-detail.component';
import { OverrideDialogComponent } from './override-dialog/override-dialog.component';
import { OverrideDialogConfirmationComponent } from './override-dialog/override-dialog-confirmation/override-dialog-confirmation.component';
import { QuicksignServicePlusComponent } from './quicksign/quicksign-service-plus/quicksign-service-plus.component';
import { QuicksignServicePlusTooltipDialogComponent } from './quicksign/quicksign-service-plus-tooltip-dialog/quicksign-service-plus-tooltip-dialog.component';
import { QuicksignServicePlusBreakdownComponent } from './quicksign/quicksign-service-plus-breakdown/quicksign-service-plus-breakdown.component';
import { RetroReinsuranceServicePlusDialogComponent } from '../shared/quicksign/retro-reinsurance-service-plus-dialog/retro-reinsurance-service-plus-dialog.component';
import { DealerSurveyDialogComponent } from 'src/dealer-survey-dialog/dealer-survey-dialog.component';
import { CilajetDialogComponent } from 'src/cilajet-dialog/cilajet-dialog.component';

@NgModule({
  declarations: [
    SafePipe,
    DealerSelectComponent,
    SignatureFieldComponent,
    ChipDealerSelectComponent,
    ProgramSelectComponent,
    TermMileagePipe,
    AgentSelectComponent,
    MgaSelectComponent,
    AnnualVscSalesComponent,
    SignatureFormControlComponent,
    DealerSelectFormControlComponent,
    DealerSelectFormControlEditableComponent,
    SignatureDialogComponent,
    DealerMultiSelectComponent,
    AgentFilteredMultiselectComponent,
    DealerFilteredMultiSelectComponent,
    DealerFilteredChipSelectComponent,
    AgentFilteredChipSelectComponent,
    QuicktrackTableComponent,
    QuicktrackTilesComponent,
    QuicktrackClaimDetailComponent,
    OverrideDialogComponent,
    OverrideDialogConfirmationComponent,
    QuicksignServicePlusComponent,
    QuicksignServicePlusTooltipDialogComponent,
    QuicksignServicePlusBreakdownComponent,
    RetroReinsuranceServicePlusDialogComponent,
    DealerSurveyDialogComponent,
    CilajetDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    GoogleChartsModule,
    SignaturePadModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    GoogleChartsModule,
    DealerSelectComponent,
    ChipDealerSelectComponent,
    AgentSelectComponent,
    MgaSelectComponent,
    SignatureFieldComponent,
    SignaturePadModule,
    SafePipe,
    TermMileagePipe,
    ProgramSelectComponent,
    NgxMaskModule,
    AnnualVscSalesComponent,
    SignatureFormControlComponent,
    DealerSelectFormControlComponent,
    DealerSelectFormControlEditableComponent,
    DealerMultiSelectComponent,
    AgentFilteredMultiselectComponent,
    DealerFilteredMultiSelectComponent,
    DealerFilteredChipSelectComponent,
    AgentFilteredChipSelectComponent,
    QuicktrackTableComponent,
    QuicktrackTilesComponent,
    QuicktrackClaimDetailComponent,
    OverrideDialogComponent,
    OverrideDialogConfirmationComponent,
    QuicksignServicePlusComponent,
    QuicksignServicePlusBreakdownComponent,
    RetroReinsuranceServicePlusDialogComponent,
    DealerSurveyDialogComponent,
    CilajetDialogComponent,
  ],
  providers: [ScriptLoaderService, SafePipe, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
