import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, EMPTY } from 'rxjs';
import { startWith, debounceTime, switchMap, map } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-agent-filtered-chip-select',
  templateUrl: './agent-filtered-chip-select.component.html',
  styleUrls: ['./agent-filtered-chip-select.component.css'],
})
export class AgentFilteredChipSelectComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  accessFilter: Observable<any[]>;
  inputFilter = new FormControl();
  agents: { agentNumber: string; agentName: string }[] = [];

  @Input('bindFormControl')
  formControl = new FormControl();

  @Input('required')
  required = false;

  @Input('label')
  label: string = 'Agent';

  @Input('selectedAgents')
  selectedAgents: [] = [];

  @Input('agentList')
  agentList: { agentNumber: string; agentName: string }[] = [];

  @ViewChild('agentInput') agentInput: ElementRef<HTMLInputElement>;

  constructor(private dashboard: DashboardService) {
    this.accessFilter = this.inputFilter.valueChanges.pipe(
      startWith(null),
      map((m: string | null) => (m ? this._filter(m) : this.agentList.slice()))
    );
  }

  ngOnInit() {
    if (this.selectedAgents) {
      this.selectedAgents.forEach((x) => {
        var agent = this.agentList.find((m) => m.agentNumber == x);
        if (agent) {
          this.agents.push({
            agentNumber: agent.agentNumber,
            agentName: agent.agentName,
          });
        }
      });
    }
  }

  private _filter(value: string): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.agentList.filter((m) =>
        m.agentName.toLowerCase().includes(filterValue)
        || m.agentNumber.toLowerCase().includes(filterValue)
      );
    }
    return this.agentList;
  }

  ngOnChanges() {
    if (!this.selectedAgents) {
      this.agents = [];
    }
  }

  displayWith(value: AgentDetail) {
    if (value && value.agentNumber) {
      return `${value.agentNumber} - ${value.agentName}`;
    }
    return value;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our agent
    if (value) {
      this.agents.push({ agentNumber: event.value, agentName: event.value });
    }
    // Clear the input value
    event.input!.value = '';
    this.formControl.setValue(this.agents.map((m) => m.agentNumber));
  }

  remove(dealer: any): void {
    const index = this.agents.indexOf(dealer);
    if (index >= 0) {
      this.agents.splice(index, 1);
    }
    this.formControl.setValue(this.agents.map((m) => m.agentNumber));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(!this.agents.find(m => m.agentNumber == event.option.value.agentNumber)){
      this.agents.push({
        agentNumber: event.option.value.agentNumber,
        agentName: event.option.value.agentName,
      });
    }
    this.agentInput.nativeElement.value = '';
    this.formControl.setValue(this.agents.map((m) => m.agentNumber));
  }
}

export interface AgentDetail {
  agentNumber: string;
  agentName: string;
}
