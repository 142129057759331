import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../../shared/auth.service';
import { Credentials } from '../../shared/authentication.model';
import { PrincipalService } from '../../shared/principal.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  loading = false;
  message: string = '';
  redirectUrl: string | null = '';
  redirectDealerNumber: string = '';

  loginForm = new FormGroup({
    userName: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private principal: PrincipalService,
    protected title: Title,
    private cookieService: CookieService,
  ) {
    title.setTitle(`${environment.titlePrefix}Login`);
  }

  ngOnInit() {
    this.redirectUrl = '/contract/rate';
    if (this.activatedRoute.snapshot.queryParamMap.has('redirectUrl')) {
      this.redirectUrl =
        this.activatedRoute.snapshot.queryParamMap.get('redirectUrl');
    }
    if (this.activatedRoute.snapshot.queryParamMap.has('flush')) {
      this.principal.flush();
    }
    if (this.activatedRoute.snapshot.queryParamMap.has('redirectDealerNumber')) {
      const dealerNumber = this.activatedRoute.snapshot.queryParamMap.get('redirectDealerNumber') as string;
      this.principal.scsNumber = dealerNumber;
    }
    if (this.activatedRoute.snapshot.queryParamMap.has('token')) {
      this.loading = true;
      this.authService
        .authenticateToken(
          {
            token: this.activatedRoute.snapshot.queryParamMap.get('token'),
          },
          this.principal.uuid
        )
        .subscribe({
          next: (response) => {
            this.principal.set(response);
            this.router.navigate([this.redirectUrl]).then((fulfilled) => {
              this.loading = false;
            });
          },
          error: () => {
            // set error message -- require reattempt
            this.message = 'Link Login Failed. Go Back and Try Again.';
            this.loading = false;
          },
        });
    } else if (this.principal.isAuthenticated) {
      this.router.navigate([this.redirectUrl]);
    }
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    const login = this.loginForm.value as Credentials;
    this.authService.login(login, this.principal.uuid).subscribe({
      next: (response) => {
        localStorage.clear();
        if (this.activatedRoute.snapshot.queryParamMap.has('redirectDealerNumber')) {
          const dealerNumber = this.activatedRoute.snapshot.queryParamMap.get('redirectDealerNumber') as string;
          this.principal.scsNumber = dealerNumber;
        }
        if (this.cookieService.check("alphaOrAlpine")) {
          this.cookieService.delete("alphaOrAlpine");
        }
        this.principal.set(response);
        this.router.navigate([this.redirectUrl]).then(() => {
          this.loading = false;
        });
      },
      error: (err: any) => {
        // check for 403 -- account locked
        if (err.status === 403) {
          this.message = 'This account has been locked due to too many failed login attempts. \nPlease wait 15 minutes and try again.';
          this.loading = false;
          return;
        }
        // set error message -- require reattempt
        this.message = 'Invalid Credentials. Try Again.';
        this.loading = false;
      },
    });
  }

  forgotPassword() {
    const userNameControl = this.loginForm.controls.userName;
    if (userNameControl.invalid) {
      this.message = 'Please enter your username';
      return;
    }
    this.loading = true;
    this.authService.forgotPassword(userNameControl.value).subscribe({
      next: (e) => {
        console.log(e);
        //this.router
        //.navigate(['/forgot-password'], {
        //  queryParams: { userName: userNameControl.value },
        //})
        if (e) {
          this.snackBar.open(
            'An error occurred while sending email: ' + e,
            'Okay',
            { duration: 15000 }
          );
        } else {
          this.snackBar.open(
            'An email was sent to the entered username.  Please click on the Reset Password link provided in the email to complete your password reset.',
            'Okay!',
            { duration: 15000 }
          );
        }
        this.loading = false;
      },
    });
  }
}
