import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../../shared/auth.service';
import { ZoomValidators } from '../../shared/zoom-validators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  passwordControl = new FormControl(null, [Validators.minLength(6)]);
  resetPasswordForm = new FormGroup({
    userName: new FormControl(null, Validators.required),
    token: new FormControl(),
    password: this.passwordControl,
    newPassword: new FormControl(null, [ZoomValidators.areSame(this.passwordControl)]),
  });

  message: string = null;
  isSubmitting = false;
  isInvalidToken: boolean = false;;
  isResending = false;
  isLoaded = false;
  constructor(
    private authentication: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    protected title: Title,
    private snackBar: MatSnackBar
  ) {
    title.setTitle(`${environment.titlePrefix}Forgot Password`);
  }

  ngOnInit(): void {
    const userName = this.route.snapshot.queryParamMap.get('userName');
    const token = this.route.snapshot.queryParamMap.get('token');
    this.resetPasswordForm.patchValue({ userName, token });
    this.authentication.validateToken(token).subscribe({
      next: () => {
        this.isLoaded = true;
      },
      error: (err) => {
        this.isInvalidToken = true;
        this.isLoaded = true;
      }
    })
  }

  resend() {
    const userNameControl = this.resetPasswordForm.controls.userName;
    if (userNameControl.invalid) {
      this.message = 'Please enter your username';
      return;
    }
    this.isResending = true
    this.authentication.forgotPassword(userNameControl.value).subscribe({
      next: (e) => {
        this.isResending = false;
        if (e) {
          this.snackBar.open(
            'An error occurred while sending email: ' + e,
            'Okay',
            { duration: 15000 }
          );
        } else {
          this.snackBar.open(
            'An email was sent to the entered username.  Please click on the Reset Password link provided in the email to complete your password reset.',
            'Okay!',
            { duration: 15000 }
          );
        }
      },
      error: () => {
        this.isResending = false;
      }
    });
  }

  submit() {
    // Enforce that if the password is updated to run validation on the confirmation
    (this.resetPasswordForm.controls.newPassword as AbstractControl).updateValueAndValidity();
    if (!this.resetPasswordForm.value.token) {
      this.snackBar.open('Token not found.  Please click resend email to obtain a token.');
      return;
    }
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.isSubmitting = true;
    const formData = this.resetPasswordForm.value;
    this.authentication.updateForgottenPassword(formData)
      .subscribe({
        next: (res) => {
          this.isSubmitting = false;
          this.router.navigate(['/login'], {
            queryParams: {
              token: btoa(res.userId)
            }
          }).then(() => { });
        },
        error: err => {
          this.isSubmitting = false;
          this.message = '';
          err.error.forEach((e) => {
            this.message += e.description + ' \n';
          });
          if (this.message.length == 0) {
            this.message = 'An unexpected error occurred.  Please try again.';
          }
        }
      });
  }
}
