import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PrincipalService } from '../principal.service';
import { ScsDetail, UserService } from '../user.service';

@Component({
  selector: 'zoom-scs-dealer-select',
  templateUrl: './dealer-select.component.html',
  styleUrls: ['./dealer-select.component.css'],
})
export class DealerSelectComponent implements OnInit {
  scsAccessFilter: Observable<any[]>;
  inputFilter = new FormControl();

  @Input('bindFormControl')
  formControl = new FormControl();

  @Input('required')
  required = false;

  @Input('label')
  label: string = 'Dealer';

  @Input('includeDFI')
  includeDFI: boolean = true;

  @Input('onlyActiveDealers')
  onlyActiveDealers: boolean = false;

  @Input('autoClearInput')
  autoClearInput: boolean = false;

  @Input('preloadFromPrincipal')
  preloadFromPrincipal: boolean = true;

  constructor(
    public principal: PrincipalService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.scsAccessFilter = this.inputFilter.valueChanges.pipe(
      debounceTime(500),
      switchMap((value: string | ScsDetail) => {
        if (!value) {
          if (this.autoClearInput) {
            this.formControl.setValue(null, { emitEvent: false });
          }
          return this.userService.scsAccessFilter(
            '',
            this.includeDFI,
            this.onlyActiveDealers,
          );
        }
        if (value && (value as string).length > 0) {
          return this.userService.scsAccessFilter(
            value as string,
            this.includeDFI,
            this.onlyActiveDealers,
          );
        }
        return EMPTY;
      }),
    );

    if (
      this.principal.scsNumber &&
      this.principal.scsNumber.length > 3 &&
      this.preloadFromPrincipal
    ) {
      // LOAD the value
      this.userService
        .preloadDealerScsAccessFilter(this.principal.scsNumber, this.includeDFI)
        .subscribe({
          next: (result) => {
            this.inputFilter.setValue(result);
            this.formControl.setValue(this.principal.scsNumber);
          },
        });
    }
  }

  displayWith(value: ScsDetail) {
    if (value && value.scsNumber) {
      return `${value.scsNumber} - ${value.name}`;
    }
    return value;
  }

  optionSelected() {
    this.formControl.setValue(this.inputFilter.value.scsNumber);
    this.principal.scsNumber = this.inputFilter.value.scsNumber;
  }
  clearText() {
    this.formControl.setValue(null);
    this.inputFilter.patchValue(null);
  }

  get inputIsClear(): boolean {
    return this.formControl.value == null || this.formControl.value == '';
  }
}
