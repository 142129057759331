import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  endpoint: string = null;
  private userEndpoint: string = null;
  private toolsEndpoint: string = environment.toolsEndpoint + environment.version;

  constructor(private http: HttpClient) {
    this.endpoint = `${environment.zoomEndpoint}api/users`;
    this.userEndpoint = environment.dashboardEndpoint + environment.version;
  }

  users(page: number, pageSize: number, username?: string) {
    const query = this.createQueryParams({ page, pageSize, username });
    return this.http.get<PagedResult>(this.endpoint, { params: query });
  }

  getUsersWithFilters(filters: any): Observable<PagedResult> {
    return this.http.get<PagedResult>(this.endpoint, { params: filters });
  }


  userSummary(page: any, pageSize: any, dealerNumber: any) {
    const query = {
      page,
      pageSize,
    } as any;
    if (dealerNumber) {
      query.dealerNumber = dealerNumber;
    }
    return this.http.get<PagedResult>(`${this.endpoint}/user-summary`, {
      params: query,
    });
  }

  addUser(postObj: any, ccEmails: string[] = []) {
    return this.http.post<string>(`${this.endpoint}`, postObj, {
      params: { ccEmails },
      responseType: 'text' as 'json',
    });
  }

  user(userId: any) {
    return this.http.get<any>(`${this.endpoint}/${userId}`);
  }

  updateUser(userId: any, payload: any) {
    return this.http.put<any>(`${this.endpoint}/${userId}`, payload);
  }

  userScs(userId: any) {
    return this.http.get<any>(`${this.endpoint}/${userId}/scs`);
  }

  updateUserScs(userId: any, payload: any) {
    return this.http.put(`${this.endpoint}/${userId}/scs`, payload);
  }

  userPermissions(userId: any) {
    return this.http.get<any[]>(`${this.endpoint}/${userId}/permissions`);
  }

  updateUserPermissions(userId: any, payload: any) {
    return this.http.put(`${this.endpoint}/${userId}/permissions`, payload);
  }

  getUserLastDealerNumber(userId: any) {
    return this.http.get<any>(`${this.endpoint}/${userId}/last-scs-number`);
  }

  updateUserLastDealerNumber(userId: any, dealerNumber: any) {
    return this.http.put(
      `${this.endpoint}/${userId}/last-dealer-number`,
      {},
      { params: { dealerNumber } },
    );
  }

  notificationEmail(userId: any, payload: any) {
    return this.http.post(
      `${this.endpoint}/${userId}/notification-email`,
      payload,
    );
  }

  getNewUserWelcomeEmailCC(dealerNumber: any) {
    return this.http.get<any[]>(
      `${this.userEndpoint}users/user-welcome-email-cc`,
      {
        params: { dealerNumber },
      },
    );
  }

  getNewDealerWelcomeEmailCC(agentNumber: any) {
    return this.http.get<any[]>(
      `${this.userEndpoint}users/dealer-welcome-email-cc`,
      {
        params: { agentNumber },
      },
    );
  }

  getEmailCcSettings(agentNumber: string) {
    return this.http.get<any[]>(`${this.userEndpoint}users/email-cc-settings`, {
      params: { agentNumber },
    });
  }

  postEmailCcSetting(postBody) {
    return this.http.post<any[]>(
      `${this.userEndpoint}users/email-cc-settings`,
      postBody,
    );
  }

  deleteEmailCcSetting(id) {
    return this.http.delete<any[]>(
      `${this.userEndpoint}users/email-cc-settings`,
      {
        params: { id },
      },
    );
  }

  getRetroGroupByUserId(userId: string) {
    return this.http.get<any>(`${this.endpoint}/retro-group`, {
      params: { userId },
    });
  }

  getRetroGroupByUserName(userName: string) {
    return this.http.get<any>(`${this.endpoint}/retro-group`, {
      params: { userName },
    });
  }

  getReinsuranceCompanyId(userId: string) {
    return this.http.get<any>(
      `${this.endpoint}/${userId}/reinsurance-companies`,
    );
  }

  getRetroGroupId(userId: string) {
    return this.http.get<any>(`${this.endpoint}/${userId}/retro-groups`);
  }

  getUserViewReinsuranceCompanies(userId: string) {
    return this.http.get<any>(
      `${this.endpoint}/${userId}/reinsurance-view-companies`,
    );
  }

  private createQueryParams(params: { [key: string]: any }): { [key: string]: any } {
    return Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {  // This will check for undefined and null but not for empty string
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  isAlphaOrAlpineToolsEndpoint() {
    return this.http.get<any>(`${this.toolsEndpoint}settings/is-alpha-or-alpine`);
  }
}

export interface PagedResult {
  page: number;
  pageSize: number;
  length: number;
  pageCount: number;
  pagedResults: any[];
}
