<mat-form-field class="agent-chip-list" appearance="outline">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let agent of agents" (removed)="remove(agent)">
      {{ agent.agentNumber }} {{ agent.agentName }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      #agentInput
      placeholder="Start typing to filter..."
      [formControl]="inputFilter"
      [matAutocomplete]="matAutocomplete"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete
    #matAutocomplete="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="selected($event)"
    autoActiveFirstOption="true"
  >
    <mat-option *ngFor="let opt of accessFilter | async" [value]="opt">
      {{ opt.agentNumber }} - {{ opt.agentName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
