<div fxLayout="column" fxLayoutGap="1em" *ngIf="servicePlusOptions.length > 0">
  <div *ngIf="showLogo">
    <img
      src="../../../assets/images/quicksign/ServicePlus Logo.png"
      style="height: 1.5em"
      alt="Service Plus Logo"
    />
  </div>
  <mat-checkbox
    *ngIf="showServiceCenterQuestion"
    [formControl]="hasServiceCenter"
    >Dealer has a Service Center</mat-checkbox
  >
  <mat-checkbox-group fxLayoutGap="1em">
    <div
      *ngFor="let option of servicePlusOptions; let i = index"
      fxLayoutAlign="start center"
      fxLayoutGap=".5em"
      fxLayout="row wrap"
    >
      <mat-checkbox
        [disabled]="getDisabledValue(option)"
        (change)="checkboxChangeEvent($event, option, i)"
        [(ngModel)]="option.checked"
      >
        <div fxLayoutAlign="center" fxLayout="row" fxLayoutGap=".5em">
          <span>{{ option.name }}</span>
        </div>
      </mat-checkbox>
      <mat-icon (click)="openTooltipDialog(option)" style="cursor: pointer">
        info
      </mat-icon>
    </div>
  </mat-checkbox-group>
  <div *ngIf="showBreakdown">
    <app-quicksign-service-plus-breakdown></app-quicksign-service-plus-breakdown>
  </div>
</div>
