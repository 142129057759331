import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ClaimList } from '../claim.model';
import { ClaimService } from '../claim.service';
import { PrincipalService } from '../principal.service';
import { DealerService } from '../dealer.service';

@Component({
  selector: 'app-quicktrack-tiles',
  templateUrl: './quicktrack-tiles.component.html',
  styleUrls: ['./quicktrack-tiles.component.css'],
})
export class QuicktrackTilesComponent implements OnInit {
  @Input() params: Observable<any>;
  @Input() isDashboard: boolean = false;
  loading = true;
  appearance = 'none';
  color = 'primary';
  multipleDealers = false;
  claimPaidTotal: any;
  claimSubmittedTotal: any;
  claimsOpenTotal: any;
  claimsAmountPaid: any;
  claimDuration: any;
  npsScore: any;

  claimDataSource = new MatTableDataSource<ClaimList>();
  claimColumns = [
    'customer',
    'claimEntryDate',
    'contractNumber',
    'claimNumber',
    'status',
    'datePaid',
    'paidAmount',
    'action',
  ];
  claimColumnsWithDealers = [
    'customer',
    'claimEntryDate',
    'contractNumber',
    'dealerNumber',
    'claimNumber',
    'status',
    'datePaid',
    'paidAmount',
    'action',
  ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  datePipe = new DatePipe('en-US');

  constructor(
    private route: ActivatedRoute,
    private service: ClaimService,
    private principal: PrincipalService,
    private dealerService: DealerService
  ) {
    this.appearance = environment.theme.input;
    this.color = environment.theme.primary;
  }

  ngOnInit() {
    this.params.subscribe({
      next: (result) => {
        this.query(result);
      },
    });
  }

  ngOnDestroy() {
    var waitTimeInSec = 0;
    var forceAllowForThisPage = true;
    this.dealerService.checkForDealerSurvey(
      waitTimeInSec,
      forceAllowForThisPage
    );
  }

  query(params: any) {
    if (params == null) {
      return;
    }
    this.loading = true;
    params.startDate = this.datePipe.transform(
      new Date(params.startDate).toJSON(),
      'yyyy-MM-dd'
    );
    params.endDate = this.datePipe.transform(
      new Date(params.endDate).toJSON(),
      'yyyy-MM-dd'
    );
    this.claimPaidTotal = this.service.getClaimsPaidTotal(params);
    this.claimSubmittedTotal = this.service.getClaimsSubmittedTotal(params);
    this.claimsOpenTotal = this.service.getClaimsOpenTotal(params);
    this.service.getClaimsAmountPaidTotal(params).subscribe((result) => {
      this.claimsAmountPaid = result;
    });
    this.service.getClaimAvgClaimDuration(params).subscribe((result) => {
      if (!result) {
        this.claimDuration = '--';
      } else {
        this.claimDuration = result;
      }
    });
    this.service.getNpsScore(params).subscribe((n) => {
      if (!n) {
        this.npsScore = '--';
      } else {
        this.npsScore = n;
      }
    });
  }

  getClaimStatus(status: string) {
    let softStatus = '';
    switch (status) {
      case 'Denied':
        softStatus = 'Closed - Not Covered';
        break;
      case 'Pending':
      case 'Authorized':
      case 'Approved':
        softStatus = 'Open';
        break;
      case 'Paid':
      case 'Closed':
        softStatus = status;
        break;
    }
    return softStatus;
  }
}
