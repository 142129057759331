<div
  class="margin-bottom-lg margin-top-lg"
  fxLayout="row wrap"
  fxLayout.lt-md="column"
  fxLayoutGap="20px"
  fxLayoutAlign="space-between stretch"
  *ngIf="!isDashboard"
>
  <div fxFlex="calc(16% - 10px)">
    <div
      class="claim-stat"
      fxLayout="column"
      fxLayout.lt-md="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="5px"
      matTooltip="Number of claims paid for the time frame selected."
      matTooltipPosition="above"
    >
      <div class="small">Paid Claims</div>
      <div class="stat-text">
        {{ claimPaidTotal | async }}
      </div>
    </div>
  </div>
  <div fxFlex="calc(16% - 10px)">
    <div
      class="claim-stat"
      fxLayout="column"
      fxLayout.lt-md="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="5px"
      matTooltip="Number of claims submitted for the timeframe selected."
      matTooltipPosition="above"
    >
      <div class="small">Submitted Claims</div>
      <div class="stat-text">
        {{ claimSubmittedTotal | async }}
      </div>
    </div>
  </div>
  <div fxFlex="calc(16% - 10px)">
    <div
      class="claim-stat"
      fxLayout="column"
      fxLayout.lt-md="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="5px"
      matTooltip="Claims currently being processed for the time frame selected."
      matTooltipPosition="above"
    >
      <div class="small">Open Claims</div>
      <div class="stat-text">
        {{ claimsOpenTotal | async }}
      </div>
    </div>
  </div>
  <div fxFlex="calc(16% - 10px)">
    <div
      class="claim-stat"
      fxLayout="column"
      fxLayout.lt-md="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="5px"
      matTooltip="Amount paid for authorized claims for the time frame selected."
      matTooltipPosition="above"
    >
      <div class="small">Paid Total</div>
      <div *ngIf="claimsAmountPaid">
        <ng-template
          [ngIf]="claimsAmountPaid > 9999"
          [ngIfElse]="baseAmountPaid"
        >
          <div *ngIf="claimsAmountPaid > 9999" class="stat-text">
            {{
              claimsAmountPaid / 1000 | currency : 'USD' : 'symbol' : '1.0-0'
            }}K
          </div>
        </ng-template>
        <ng-template #baseAmountPaid>
          <div class="stat-text">
            {{ claimsAmountPaid | currency : 'USD' : 'symbol' : '1.0-0' }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div fxFlex="calc(16% - 10px)">
    <div
      class="claim-stat"
      fxLayout="column"
      fxLayout.lt-md="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="5px"
      matTooltip="Average number of days to process a claim submitted for the time frame selected."
      matTooltipPosition="above"
    >
      <div class="small">Avg Claim Length (Days)</div>
      <div class="stat-text">
        {{ claimDuration | number : '1.0-2' }}
      </div>
    </div>
  </div>
  <div fxFlex="calc(16% - 10px)">
    <div
      class="claim-stat"
      fxLayout="column"
      fxLayout.lt-md="row wrap"
      fxLayoutAlign="center center"
      fxLayoutGap="5px"
      matTooltip="Net promoter score (NPS) is the gold standard of customer experience metrics. NPS measures the loyalty of customers to a company. The average NPS score in 2021 for the Automotive Industry is 14, while for the insurance industry it is 10. Alpha warranty exceeds both these averages and provides your customers an exceptional claims experience!"
      matTooltipPosition="above"
    >
      <div class="small">
        Repair NPS
        <mat-icon
          class="info-icon"
          matTooltipClass="dashboard-tool-tip"
          matTooltip="Net promotor score (NPS) measures the loyalty of customers."
        >
          info
        </mat-icon>
      </div>
      <div class="stat-text">
        {{ npsScore }}
      </div>
    </div>
  </div>
</div>

<div
  fxLayout="row wrap"
  fxLayout.lt-sm="column"
  fxLayoutGap="20px"
  class="stat-box margin-bottom-lg margin-top-lg"
  *ngIf="isDashboard"
>
  <mat-card class="bg-green stat-box" fxFlex>
    <mat-card-content>
      <div fxLayout="row">
        <div fxFlex="25%" fxFlex.lt-md="20">
          <mat-icon aria-hidden="true">construction</mat-icon>
        </div>
        <div
          fxFlex
          fxFlex.lt-md="60"
          fxLayoutAlign.lt-md="center center"
          fxLayout.lt-md="column"
        >
          <small>PAID CLAIMS</small>
          <div class="stat-text">
            {{ claimPaidTotal | async }}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="bg-light-blue stat-box" fxFlex>
    <mat-card-content>
      <div fxLayout="row">
        <div fxFlex="25%" fxFlex.lt-md="20">
          <mat-icon aria-hidden="true">emoji_transportation</mat-icon>
        </div>
        <div
          fxFlex
          fxFlex.lt-md="60"
          fxLayoutAlign.lt-md="center center"
          fxLayout.lt-md="column"
        >
          <small>SUBMITTED CLAIMS</small>
          <div class="stat-text">
            {{ claimSubmittedTotal | async }}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="bg-red stat-box" fxFlex>
    <mat-card-content>
      <div fxLayout="row">
        <div fxFlex="25%" fxFlex.lt-md="20">
          <mat-icon aria-hidden="true">description</mat-icon>
        </div>
        <div
          fxFlex
          fxFlex.lt-md="60"
          fxLayoutAlign.lt-md="center center"
          fxLayout.lt-md="column"
        >
          <div fxLayoutAlign="space-between center">
            <small>OPEN CLAIMS</small>
          </div>
          <div class="stat-text">{{ claimsOpenTotal | async }}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<br />
<div
  fxLayout="row wrap"
  fxLayout.lt-sm="column"
  fxLayoutGap="20px"
  class="stat-box margin-bottom-lg margin-top-lg"
  *ngIf="isDashboard"
>
  <mat-card class="bg-black stat-box" fxFlex>
    <mat-card-content>
      <div fxLayout="row">
        <div fxFlex="25%" fxFlex.lt-md="20">
          <mat-icon aria-hidden="true">paid</mat-icon>
        </div>
        <div
          fxFlex
          fxFlex.lt-md="60"
          fxLayoutAlign.lt-md="center center"
          fxLayout.lt-md="column"
        >
          <small>PAID TOTAL</small>
          <div class="stat-text">
            {{ claimsAmountPaid | currency : 'USD' : 'symbol' : '1.0-0' }}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="bg-dark-gray stat-box" fxFlex>
    <mat-card-content>
      <div fxLayout="row">
        <div fxFlex="25%" fxFlex.lt-md="20">
          <mat-icon aria-hidden="true">access_time</mat-icon>
        </div>
        <div
          fxFlex
          fxFlex.lt-md="60"
          fxLayoutAlign.lt-md="center center"
          fxLayout.lt-md="column"
        >
          <small>AVG CLAIM LENGTH (DAYS)</small>
          <div class="stat-text" *ngIf="claimDuration != '--'">
            {{ claimDuration | number : '1.0-2' }}
          </div>
          <div class="stat-text" *ngIf="claimDuration == '--'">
            {{ claimDuration }}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="bg-yellow stat-box" fxFlex>
    <mat-card-content>
      <div fxLayout="row">
        <div fxFlex="25%" fxFlex.lt-md="20">
          <mat-icon aria-hidden="true">sports_score</mat-icon>
        </div>
        <div
          fxFlex
          fxFlex.lt-md="60"
          fxLayoutAlign.lt-md="center center"
          fxLayout.lt-md="column"
        >
          <div fxLayoutAlign="start center" fxLayoutGap="5px">
            <small>REPAIR NPS</small>
            <mat-icon
              class="info-icon"
              matTooltipClass="dashboard-tool-tip"
              matTooltip="Net promotor score (NPS) measures the loyalty of customers."
            >
              info
            </mat-icon>
          </div>
          <div class="stat-text">{{ npsScore }}</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
