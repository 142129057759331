
<div class="container">
  <h3 *ngIf="!routeId">Not Found</h3>
  <h3 *ngIf="routeId">Redirecting...</h3>

  <div class="content-panel">
    <div *ngIf="!routeId">
      Page not found.
    </div>
    <div *ngIf="routeId">
      Redirect to claim after login.
    </div>
  </div>
</div>
