import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PrincipalService } from '../principal.service';
import { ScsDetail, UserService } from '../user.service';

@Component({
  selector: 'zoom-scs-agent-select',
  templateUrl: './agent-select.component.html',
  styleUrls: ['./agent-select.component.css'],
})
export class AgentSelectComponent implements OnInit, AfterViewInit {
  filteredAgents: Observable<ScsDetail[]>;
  filterInput = new FormControl(null);

  @Input('required')
  controlRequired = false;

  @Input('label')
  formLabel = 'Agent';

  @Input('bindFormControl')
  formControl = new FormControl();

  @Input('showClearButton')
  showClearButton: boolean = false;

  @Output()
  optionSelectEvent = new EventEmitter<any>();

  initialLoad: boolean = true;
  constructor(private user: UserService, private principal: PrincipalService) {}

  ngAfterViewInit(): void {
    this.filterInput.patchValue('');
  }

  ngOnInit(): void {
    this.filteredAgents = this.filterInput.valueChanges.pipe(
      debounceTime(500),
      switchMap((value: string | ScsDetail | null) => {
        if (this.initialLoad) {
          this.initialLoad = false;
          return this.user.agentScsAccessFilter('');
        }
        if (value && (value as string).length > 2) {
          return this.user.agentScsAccessFilter(value as string);
        }
        return EMPTY;
      }),
    );

    const existing = this.formControl.value;
    if (existing && this.principal.hasUserViewAccess(['MGA', 'Site Admin'])) {
      this.user.preloadAgentScsAccessFilter(existing).subscribe({
        next: (result) => {
          this.filterInput.setValue(result);
        },
      });
    }
  }

  displayWith(value: ScsDetail | string): string {
    if (value && (value as ScsDetail).scsNumber) {
      const detail = value as ScsDetail;
      return `${detail.name} - ${detail.scsNumber}`;
    }
    return value as string;
  }

  optionSelected() {
    this.formControl.setValue(this.filterInput.value.scsNumber);
    this.optionSelectEvent.emit(this.filterInput.value.scsNumber);
  }

  clearText() {
    this.formControl.setValue(null);
    this.filterInput.patchValue(null);
  }

  get inputIsClear(): boolean {
    return this.formControl.value == null || this.formControl.value == '';
  }
}
