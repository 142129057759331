<div class="container">
    <h1>SiriusXM Real Time Interface</h1>

    <form class="content-panel" (submit)="onSubmit()" #siriusXMRealTimeForm="ngForm" [formGroup]="siriusXMRealTimeFormGroup">
        <div fxflex>
            <h3>Customer Information</h3>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[0]">
                <mat-label>Vin*</mat-label>
                <input matInput formControlName="vin" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[1]">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[2]">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[3]">
                <mat-label>Street</mat-label>
                <input matInput formControlName="address1" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[4]">
                <mat-label>City</mat-label>
                <input matInput formControlName="city" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[5]">
                <mat-label>State</mat-label>
                <input matInput formControlName="state" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[6]">
                <mat-label>Zip</mat-label>
                <input matInput formControlName="zip" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[7]">
                <mat-label>Country</mat-label>
                <input matInput formControlName="country" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[8]">
                <mat-label>Home Phone</mat-label>
                <input matInput formControlName="phone" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[9]">
                <mat-label>E-mail</mat-label>
                <input matInput formControlName="email" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="field" [floatLabel]="labelNames[10]">
                <mat-label>Dealer ID</mat-label>
                <input matInput formControlName="dealerId" />
            </mat-form-field>

            <div fxLayoutAlign="end" class="update-submit">
                <button mat-flat-button color="primary" type="button" (click)="testUpdateCrm()">Test CRM</button>
            </div>
            <div fxLayoutAlign="end" class="update-submit">
                <button mat-flat-button color="primary" type="button" (click)="testUploadToSxm()">Test Upload</button>
            </div>
            <div fxLayoutAlign="end" class="update-submit">
                <button mat-flat-button color="primary" type="button" (click)="runTestCases()">Run Test Cases</button>
            </div>
            <div fxLayoutAlign="end" class="update-submit">
                <button mat-flat-button color="primary" type="button" (click)="testApplyToExistingContracts()">Test Apply to Existing</button>
            </div>
        </div>
        <h3>Response</h3>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 responseTable">
            <ng-container matColumnDef="deviceId">
                <th mat-header-cell *matHeaderCellDef>Device ID</th>
                <td mat-cell *matCellDef="let element"> {{element.deviceId}} </td>
            </ng-container>

            <ng-container matColumnDef="isEligible">
                <th mat-header-cell *matHeaderCellDef>Eligible</th>
                <td mat-cell *matCellDef="let element"> {{element.isEligible}} </td>
            </ng-container>

            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef>URL</th>
                <td mat-cell *matCellDef="let element"> {{element.url}} </td>
            </ng-container>

            <ng-container matColumnDef="resultCode">
                <th mat-header-cell *matHeaderCellDef>Result Code</th>
                <td mat-cell *matCellDef="let element"> {{element.resultCode}} </td>
            </ng-container>

            <ng-container matColumnDef="errorCode">
                <th mat-header-cell *matHeaderCellDef>Error Code</th>
                <td mat-cell *matCellDef="let element"> {{element.errorCode}} </td>
            </ng-container>

            <ng-container matColumnDef="errorMessage">
                <th mat-header-cell *matHeaderCellDef>Error Message</th>
                <td mat-cell *matCellDef="let element"> {{element.errorMessage}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </form>
</div>