import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retro-reinsurance-service-plus-dialog',
  templateUrl: './retro-reinsurance-service-plus-dialog.component.html',
  styleUrls: ['./retro-reinsurance-service-plus-dialog.component.css']
})
export class RetroReinsuranceServicePlusDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
