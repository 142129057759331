<mat-form-field appearance="outline">
  <mat-label>Agents</mat-label>
  <mat-select (openedChange)="openedChange($event)"
              placeholder="Agents"
              [formControl]="selectedAgents"
              multiple>
    <div class="select-container">
      <mat-optgroup>
        <mat-form-field style="width: 100%">
          <input #agentSearch
                 autocomplete="off"
                 placeholder="Search"
                 aria-label="Search"
                 matInput
                 [formControl]="searchTextboxControl" />
          <button [disableRipple]="true"
                  *ngIf="agentSearch.value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearSearch($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </mat-optgroup>
      <mat-optgroup *ngIf="
          (filteredOptions | async) && (filteredOptions | async).length == 0">
        <div>No results found!</div>
      </mat-optgroup>
      <mat-option (onSelectionChange)="selectionChange($event)"
                  *ngFor="let option of filteredOptions | async"
                  [value]="option.agentNumber">
        {{ option.agentNumber != '' ? option.agentNumber + " - " + option.agentName : option.agentName }}  
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
