import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { QuickSignService } from 'src/shared/quick-sign.service';
import { SiriusXMRealTimeModel, SiriusXMRealTimeResponseModel } from 'src/shared/sirius-xm.model';
import { SiriusXMService } from 'src/shared/sirius-xm.service';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatFormField } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { SiriusXmModule } from '../sirius-xm.module';

@Component({
  selector: 'sirius-xm-realtime',
  templateUrl: './sirius-xm-realtime.component.html',
  styleUrls: ['./sirius-xm-realtime.component.css']
})
export class SiriusXMRealtimeComponent implements OnInit {
  displayedColumns: string[] = [];
  displayedErrorColumns: string[] = ['deviceId', 'isEligible', 'url', 'resultCode','errorCode', 'errorMessage'];
  displayedSuccessColumns: string[] = ['deviceId', 'isEligible', 'url', 'resultCode'];
  dataSource: SiriusXMRealTimeResponseModel[];
  showSigHint = false;
  agentNo: string;
  labelNames = [
    'Vin',
    'First Name',
    'Last Name',
    'Street',
    'City',
    'State',
    'Zip',
    'Country',
    'Home Phone',
    'Email',
    'DealerId'
  ]

  constructor(
    public quickSign: QuickSignService,
    private snackBar: MatSnackBar,
    private router: Router,
    private sxm: SiriusXMService
    ) { }

    siriusXMRealTimeFormGroup = new FormGroup({
      vin: new FormControl(null,[Validators.required]),
      firstName: new FormControl(null),
      lastName: new FormControl(null),
      address1: new FormControl(null),
      city: new FormControl(null),
      state: new FormControl(null),
      zip: new FormControl(null),
      country: new FormControl(null),
      phone: new FormControl(null),
      email: new FormControl(null),
      dealerId: new FormControl(null),
  });


  ngOnInit(): void {
    var resp = new SiriusXMRealTimeResponseModel();
    this.dataSource = [resp];
    resp.deviceId = "0";
    resp.isEligible = "Yes";
    resp.url = "https://sms-tst.corp.siriusxm.com/ttgllvsmsweb03-9013/cuwi9001-000/one-click_view.action?cna_id=cb8260ad-febc-4296-8f50-c2b771ad4bf9";
    resp.resultCode = "SUCCESS";
    resp.errorCode = "";
    resp.errorMessage = "";
    this.checkDisplayedColumns();
  }

  checkDisplayedColumns(){
    if(this.dataSource[0].resultCode == "SUCCESS"){
      this.displayedColumns = this.displayedSuccessColumns;
    }else{
      this.displayedColumns = this.displayedErrorColumns;
    }
  }

  onSubmit(){
    var model = new SiriusXMRealTimeModel();
    var tableItem = new SiriusXMRealTimeResponseModel();
    // model.vin = this.siriusXMRealTimeFormGroup.controls["vin"].value;
    // model.firstName = this.siriusXMRealTimeFormGroup.controls["firstName"]?.value;
    // model.lastName = this.siriusXMRealTimeFormGroup.controls["lastName"]?.value;
    // model.address1 = this.siriusXMRealTimeFormGroup.controls["address1"]?.value;
    // model.city = this.siriusXMRealTimeFormGroup.controls["city"]?.value;
    // model.state = this.siriusXMRealTimeFormGroup.controls["state"]?.value;
    // model.zip = this.siriusXMRealTimeFormGroup.controls["zip"]?.value;
    // model.customerCountry = this.siriusXMRealTimeFormGroup.controls["country"]?.value;
    // model.homePhone = this.siriusXMRealTimeFormGroup.controls["phone"]?.value;
    // model.consumerEmail = this.siriusXMRealTimeFormGroup.controls["email"]?.value;
    // model.dealerId = this.siriusXMRealTimeFormGroup.controls["dealerId"]?.value;
    model.vin = "1VWBH7A37CC050316";
    model.firstName = "First";
    model.lastName = "Last";
    model.address1 = "Address";
    model.city = "City";
    model.state = "UT";
    model.zip = "84070";
    model.customerCountry = "USA";
    model.homePhone = "1234567890";
    model.consumerEmail = "test@example.com"
    model.dealerId = "1234567";


    this.dataSource = [tableItem];
    this.checkDisplayedColumns();
  }

  testUpdateCrm(){
    this.sxm.RealTimeCrmTest().subscribe({
      next: (result) => {
        var a = result;
      },
    });
  }

  testUploadToSxm(){
    /*var model = new SiriusXMRealTimeModel();
    var tableItem = new SiriusXMRealTimeResponseModel();
    model.vin = this.siriusXMRealTimeFormGroup.controls["vin"].value;
    model.firstName = this.siriusXMRealTimeFormGroup.controls["firstName"]?.value;
    model.lastName = this.siriusXMRealTimeFormGroup.controls["lastName"]?.value;
    model.address1 = this.siriusXMRealTimeFormGroup.controls["address1"]?.value;
    model.city = this.siriusXMRealTimeFormGroup.controls["city"]?.value;
    model.state = this.siriusXMRealTimeFormGroup.controls["state"]?.value;
    model.zip = this.siriusXMRealTimeFormGroup.controls["zip"]?.value;
    model.customerCountry = this.siriusXMRealTimeFormGroup.controls["country"]?.value;
    model.homePhone = this.siriusXMRealTimeFormGroup.controls["phone"]?.value;
    model.consumerEmail = this.siriusXMRealTimeFormGroup.controls["email"]?.value;
    model.dealerId = this.siriusXMRealTimeFormGroup.controls["dealerId"]?.value;*/
    var model = new SiriusXMRealTimeModel();
    var tableItem = new SiriusXMRealTimeResponseModel();
    model.vin = "5FNYF6H0XJB020817";
    model.firstName = "First";
    model.lastName = "Last";
    model.address1 = "Address";
    model.city = "City";
    model.state = "UT";
    model.customerCountry = "USA";
    model.zip = "84070";
    model.homePhone = "1234567890";
    model.consumerEmail = "dylanf@alphawarranty.com"
    model.dealerId = "DL00148";
    model.contractNumber = "0000000";
    model.corpId = "13547";
    this.sxm.RealTimeRequest(model).subscribe({
          next: (result) => {
            tableItem.deviceId = result.deviceId,
            tableItem.isEligible =  result.isEligible,
            tableItem.url = result.url,
            tableItem.resultCode =  result.resultCode,
            tableItem.errorCode = result.errorCode,
            tableItem.errorMessage =  result.errorMessage
          },
        });
  }

  runTestCases(){
    this.sxm.RealTimeTestCases().subscribe({
      next: (result) => {
        var a = result;
      },
    });
  }

  testApplyToExistingContracts(){
    this.sxm.RealTimeApplyToExistingContracts().subscribe({
      next: (result) => {
        var a = result;
      },
    });
  }

}
