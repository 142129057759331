<mat-accordion>
  <mat-expansion-panel [expanded]="expanded">
    <mat-expansion-panel-header
      >Benefit Markup Breakdown</mat-expansion-panel-header
    >
    <mat-panel-description>
      <div class="table-container" fxFlex>
        <table
          mat-table
          [dataSource]="benefitsMarkupData"
          class="table-striped"
        >
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="max-width: 150px">
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="budget">
            <th mat-header-cell *matHeaderCellDef>
              A La Car,<br />
              BudgetCare
            </th>
            <td mat-cell *matCellDef="let element">
              ${{ element.budgetValue | number }}
            </td>
          </ng-container>
          <ng-container matColumnDef="aPlusP1NewCar">
            <th mat-header-cell *matHeaderCellDef>
              PriorityOne, <br />A+ , IntelliCare, <br />New Car
            </th>
            <td mat-cell *matCellDef="let element">
              ${{ element.aPlusP1NewCarValue | number }}
            </td>
          </ng-container>
          <ng-container matColumnDef="other">
            <th mat-header-cell *matHeaderCellDef>
              Other<br />(Ancillary, etc)
            </th>
            <td mat-cell *matCellDef="let element">
              ${{ element.otherValue | number }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>
