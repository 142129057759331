import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private endpoint: string;
  constructor(private http: HttpClient) {
    this.endpoint = environment.toolsEndpoint + environment.version;
  }

  markup(dealerNumber: string) {
    return this.http.get<any[]>(`${this.endpoint}settings/markup`, {
      params: { dealerNumber: dealerNumber },
    });
  }

  addMarkup(model: any) {
    return this.http.post(`${this.endpoint}settings/markup/add`, model);
  }

  editMarkup(model: any) {
    return this.http.post(`${this.endpoint}settings/markup`, model);
  }

  getSaleAuthority(dealerNumber: string, productCode: string) {
    return this.http.get<any[]>(`${this.endpoint}settings/dealer-authority`, {
      params: {
        dealerNumber: dealerNumber,
        productCode: productCode,
      },
    });
  }

  markupDetails(markupId: any) {
    return this.http.get<any>(`${this.endpoint}settings/markup/${markupId}`);
  }

  deleteMarkup(markupId: any) {
    return this.http.delete<any>(`${this.endpoint}settings/markup/${markupId}`);
  }

  getACHWalletList(dealerNumber: string) {
    return this.http.get<any[]>(`${this.endpoint}settings/wallet-list`, {
      params: { dealerNumber },
    });
  }

  addAchWallet(postObj: any) {
    return this.http.post(`${this.endpoint}settings/wallet`, postObj);
  }

  setAchDefault(walletId: string, dealerNumber: string) {
    return this.http.put(
      `${this.endpoint}settings/wallet/set-default`,
      {},
      { params: { walletId, dealerNumber } }
    );
  }

  deleteAchWallet(walletId: string) {
    return this.http.delete(`${this.endpoint}settings/wallet/${walletId}`);
  }

  getDriveEasy(dealerNumber: string) {
    return this.http.get(`${this.endpoint}settings/driveeasy`, {
      params: { dealerNumber },
    });
  }

  addDriveEasy(model: any) {
    return this.http.post(`${this.endpoint}settings/driveeasy/add`, model);
  }

  editDriveEasy(model: any) {
    return this.http.post(`${this.endpoint}settings/driveeasy`, model);
  }

  getDealerSettings(dealerNumber: string) {
    return this.http.get<any>(`${this.endpoint}settings/dealer-settings`, {
      params: { dealerNumber },
    });
  }

  updateDealerSettings(postObj: any) {
    return this.http.put(`${this.endpoint}settings/dealer-settings`, postObj);
  }

  addDealerSettings(postObj: any) {
    return this.http.post(`${this.endpoint}settings/dealer-settings`, postObj);
  }

  getDealerState(dealerNumber: string){
    return this.http.get<any>(`${this.endpoint}settings/dealer-state`, {
      params: { dealerNumber },
    });
  }

  getManagers(dealerNumber: string) {
    return this.http.get<any[]>(`${this.endpoint}settings/managers`, {
      params: { dealerNumber },
    });
  }

  getManager(dealerNumber: string, mgrId: number) {
    return this.http.get(
      `${this.endpoint}settings/manager/${dealerNumber}/${mgrId}`
    );
  }

  createManager(model: any) {
    return this.http.post(`${this.endpoint}settings/add-manager`, model);
  }

  updateManager(model: any) {
    return this.http.put(`${this.endpoint}settings/edit-manager`, model);
  }

  deleteManager(dealerNumber: string, mgrId: number) {
    return this.http.delete(
      `${this.endpoint}settings/manager/${dealerNumber}/${mgrId}`
    );
  }
  getDealerUsers(dealerNumber: string) {
    return this.http.get<any>(`${this.endpoint}settings/users`, {
      params: { dealerNumber },
    });
  }

  paymentPlanTypes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.endpoint}settings/payment-plan-types`);
  }

  paymentPlan(accountNumber: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}settings/payment-plans`, {
      params: {
        accountNumber
      }
    });
  }

  upsertPaymentPlan(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}settings/payment-plans`, payload);
  }

  getCustomLayouts() {
    return this.http.get<any[]>(`${this.endpoint}settings/custom-layouts`);
  }

  addCustomLayout(payload: any) {
    return this.http.post(`${this.endpoint}settings/custom-layouts`, payload);
  }

  updateCustomLayout(customLayoutId: number,formData: FormData) {
    return this.http.put(`${this.endpoint}settings/custom-layouts/${customLayoutId}`, formData);
  }

  deleteCustomLayout(customLayoutId: any) {
    return this.http.delete(`${this.endpoint}settings/custom-layouts/${customLayoutId}`);
  }

  getUserLogo() {
    return this.http.get<any>(`${this.endpoint}settings/custom-layouts/user-logo`);
  }

  upsertDealerServicePlus(postBody: any ) {
    return this.http.post<any>(`${this.endpoint}settings/service-plus`, postBody);
  }

  getIsAlpine() {
    return this.http.get<any>(`${this.endpoint}settings/is-alpine`);
  }
}
