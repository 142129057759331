import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { FormType } from '../quick-sign.model';
import { QuickSignService } from '../quick-sign.service';

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.css'],
})
export class SignatureDialogComponent implements OnInit {
  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public matSnackBar: MatSnackBar,
    public service: QuickSignService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  loading = true;
  dealerProfileId: string;
  existingSignature: any;
  hasSignature = false;
  signatureForm = new FormGroup({
    file: new FormControl(),
    signerFirstName: new FormControl(null, Validators.required),
    signerLastName: new FormControl(null, Validators.required),
    signerTitle: new FormControl(null, Validators.required),
    dealerProfileId: new FormControl(),
    signedDate: new FormControl(new Date()),
  });

  contextualFormArray: FormArray;
  agreementsEvent = new Subject();
  pendingAgreements: FormType[];
  agreements = [];

  ngOnInit(): void {
    this.dealerProfileId = this.data.dealerProfileId;
    this.signatureForm.controls.dealerProfileId.setValue(
      this.data.dealerProfileId,
    );

    if (this.data.file) {
      this.signatureForm.patchValue({
        signerTitle: this.data.signerTitle,
        signerFirstName: this.data.signerFirstName,
        signerLastName: this.data.signerLastName,
      });
      this.existingSignature = this.data.file;
      this.loading = false;
    } else if (this.dealerProfileId) {
      this.getSignature();
      this.getAgreements();
    } else {
      this.loading = false;
    }
    this.dialogRef.updateSize('825px');
  }

  getSignature() {
    this.service.getSignature(this.dealerProfileId).subscribe({
      next: (res) => {
        if (res) {
          this.signatureForm.patchValue({
            signerTitle: res.signerTitle,
            signerFirstName: res.signerFirstName,
            signerLastName: res.signerLastName,
            signedDate: res.signedDate,
            file: res.file,
          });
          this.existingSignature = res.file;
        }
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  getAgreements() {
    this.contextualFormArray = new FormArray([]);
    this.service
      .getDealerAgreementsForAcceptance(this.dealerProfileId)
      .subscribe({
        next: (res) => {
          res.forEach((x) => {
            const isAccepted = new FormControl(false, Validators.requiredTrue);
            this.contextualFormArray.push(isAccepted);
            x.isAccepted = isAccepted;
            this.agreements.push(x);
          });
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 422) {
            console.log('error', 'already completed');
          }
        },
      });
  }

  moveToNextTab() {
    const tabGroup = this.tabGroup;
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) {
      return;
    }
    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabGroup.selectedIndex + 1) % tabCount;
  }

  sanitize(html: string, displayName: string) {
    if (displayName == 'ZERO Agreement' || displayName == 'SPP Agreement') {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    return html;
  }

  updateSignature() {
    this.service.updateSignature(this.signatureForm.value).subscribe({
      next: () => this.dialogRef.close(),
      error: () =>
        this.matSnackBar.open(
          'There was an error updating the signature. Please try again.',
          'Dismiss',
        ),
    });
  }
}
