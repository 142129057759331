import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'termMileage'
})
export class TermMileagePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === 999999) {
      return 'Unlimited';
    }
    return value;
  }

}
