<mat-form-field appearance="outline">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    type="text"
    matInput
    placeholder="Start entering characters to filter..."
    [formControl]="inputFilter"
    [matAutocomplete]="matAutocomplete"
    [required]="isRequired"
    #input
    name="dealer-select"
  />
  <mat-icon matSuffix (click)="clearText()">close</mat-icon>
  <mat-autocomplete
    #matAutocomplete="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="optionSelected(input)"
    autoActiveFirstOption="true"
  >
    <mat-option *ngFor="let opt of scsAccessFilter | async" [value]="opt">
      {{ opt.scsNumber }} - {{ opt.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
