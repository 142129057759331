import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private endpoint: string;
  constructor(private http: HttpClient) {
    this.endpoint = environment.dashboardEndpoint + environment.version;
  }

  scsAccessList() {
    return this.http.get<any[]>(`${this.endpoint}users/scs-access-list`);
  }

  scsAccessFilter(
    filter: string,
    includeDFI: boolean = true,
    onlyActiveDealers: boolean = false,
    includeDemos: boolean = true,
    excludeProfitSharing: boolean = false,
    isDealerReinsurance: boolean = false
  ) {
    return this.http.get<ScsDetail[]>(
      `${this.endpoint}users/scs-access-filter/dealer`,
      {
        params: {
          filter,
          includeDFI: includeDFI.toString(),
          onlyActiveDealers: onlyActiveDealers.toString(),
          includeDemos: includeDemos.toString(),
          excludeProfitSharing: excludeProfitSharing.toString(),
          isDealerReinsurance: isDealerReinsurance.toString(),
        },
      }
    );
  }

  preloadAccessFilter(dealerNumbers: string[]) {
    return this.http.post<any[]>(
      `${this.endpoint}users/scs-access-filter/preload`,
      dealerNumbers
    );
  }

  preloadDealerScsAccessFilter(
    dealerNumber: string,
    includeDFI: boolean = true
  ) {
    return this.http.get<any>(
      `${this.endpoint}users/scs-access-filter/dealer/preload`,
      {
        params: {
          dealerNumber,
          includeDFI: includeDFI.toString(),
        },
      }
    );
  }

  agentScsAccessFilter(filter: string) {
    return this.http.get<ScsDetail[]>(
      `${this.endpoint}users/scs-access-filter/agent`,
      {
        params: { filter },
      }
    );
  }

  preloadAgentScsAccessFilter(agentNumber: any) {
    return this.http.get<ScsDetail>(
      `${this.endpoint}users/scs-access-filter/agent/preload`,
      {
        params: { agentNumber },
      }
    );
  }

  mgaScsAccessFilter(filter: string) {
    return this.http.get<ScsDetail[]>(
      `${this.endpoint}users/scs-access-filter/mga`,
      {
        params: { filter },
      }
    );
  }

  preloadMgaScsAccessFilter(mgaNumber: any) {
    return this.http.get<ScsDetail>(
      `${this.endpoint}users/scs-access-filter/mga/preload`,
      {
        params: { mgaNumber },
      }
    );
  }
}

export interface ScsDetail {
  scsNumber: string;
  name: string;
}
