<div matDialogContent>
  For dealers that participate in Retro or Reinsurance the following benefits
  are included and do not need to be added:
  <ul>
    <li>Limited 3rd Party Part Sourcing</li>
    <li>No 3rd Party Part Sourcing</li>
    <li>Enhanced Labor Rate</li>
  </ul>
</div>
<div fxLayoutAlign="end" matDialogActions>
  <button color="primary" mat-flat-button matDialogClose>Okay</button>
</div>
