import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { DealerProductType } from './contract.model';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private endPoint: string;
  private postalCodeEndpoint: string;
  private webhookEndPoint: string;

  constructor(private httpClient: HttpClient) {
    this.endPoint = environment.toolsEndpoint + environment.version;
    this.postalCodeEndpoint = 'https://api.zippopotam.us/us/';
    //this.webhookEndPoint = environment.pcrsWebhook + environment.version;
  }

  private buildParams(source: any) {
    const result = {};
    for (const prop in source) {
      if (source[prop] != null || source[prop] != undefined) {
        result[prop] = source[prop];
      }
    }
    return result as Params;
  }

  getRates(payload: any): Observable<any> {
    return this.httpClient.get<any>(`${this.endPoint}quote/rates`, {
      params: this.buildParams(payload),
    });
  }

  getProducts(dealerNumber: string) {
    return this.httpClient.get<DealerProductType[]>(
      `${this.endPoint}quote/dealer-products`,
      {
        params: { dealerNumber },
      }
    );
  }

  getDealerSettings(dealerNumber: string) {
    return this.httpClient.get<any>(`${this.endPoint}quote/dealer-settings`, {
      params: { dealerNumber },
    });
  }

  getDealerInformation(dealerNumber: string) {
    return this.httpClient.get<any>(`${this.endPoint}quote/dealer-information`, {
      params: { dealerNumber },
    });
  }

  incompleteQuoteUpdate(params: any) {
    return this.httpClient.put<any>(
      `${this.endPoint}quote/incomplete-quote-update`,
      params
    )
  }

  getAListSales(value: any) {
    return this.httpClient.get<any>(
      `${this.endPoint}quote/dealer/a-list-score`,
      { params: { dealerNumber: value } }
    );
  }

  getLenders(filter: string) {
    return this.httpClient.get<any[]>(`${this.endPoint}quote/lenders`, {
      params: { filter },
    });
  }

  getManagers(dealerNumber: string) {
    return this.httpClient.get<any[]>(`${this.endPoint}quote/managers`, {
      params: { dealerNumber },
    });
  }

  saveContractQuote(body: any): Observable<any> {
    return this.httpClient.post(`${this.endPoint}quote/saved-quote`, body);
  }

  getContractQuote(savedQuoteId: number): Observable<any> {
    return this.httpClient.get(
      `${this.endPoint}quote/saved-quote/${savedQuoteId}`
    );
  }

  previewContract(body: any) {
    return this.httpClient.post<any>(
      `${this.endPoint}quote/contract-preview`,
      body
    );
  }

  generateContract(body: any): Observable<any> {
    // return this.generateContracts([body]);
    return this.httpClient.post<any>(`${this.endPoint}quote/contracts`, body);
  }

  addContractNote(payload: any) {
    return this.httpClient.post<any>(`${this.endPoint}quote/contract-note`, payload);
  }

  savedQuotes(params) {
    return this.httpClient.get<any[]>(`${this.endPoint}quote/saved-quotes`, {
      params: params,
    });
  }

  updateSavedQuote(payload: any) {
    return this.httpClient.put<any>(
      `${this.endPoint}quote/update-saved-quote-product`,
      payload
    );
  }

  printQuoteComparison(body: any) {
    return this.httpClient
      .post(`${this.endPoint}quote/print-quote-comparison`, body, {
        responseType: 'blob',
      })
      .pipe(
        map((result: Blob) => {
          return URL.createObjectURL(result);
        })
      );
  }

  getLocation(postalCode: any) {
    return this.httpClient.get<any>(`${this.postalCodeEndpoint}${postalCode}`);
  }

  coveredComponents(coverageCode: string, rateBook: string) {
    let scrubbedCode = encodeURI(coverageCode);
    return this.httpClient.get<any>(
      `${this.endPoint}quote/covered-components`,
      {
        params: {
          coverageCode: scrubbedCode.replace('+', '%2b'),
          ratebookCode: rateBook
        },
      }
    );
  }

  sendActivationEmail(quoteId: any, email: any) {
    const url = `${this.endPoint}quote/${quoteId}/activation-email`;
    const params = { email };
    return this.httpClient.post(url, null, { params });
  }
}
