<form [formGroup]="productFormGroup">
  <div
    formGroupName="programs"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap="1em"
    fxLayoutGap.lt-md="0"
  >
    <mat-form-field fxFlex appearance="outline" floatLabel="always">
      <mat-label> VSC </mat-label>
      <mat-select multiple formControlName="vscProgramControl">
        <mat-option
          *ngFor="let pr of vscPrograms"
          [value]="pr.alphaProgramId"
          name="programId"
        >
          {{ pr.program }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex appearance="outline" floatLabel="always">
      <mat-label>GAP Provider</mat-label>
      <mat-select
        formControlName="gapProviderId"
        (selectionChange)="gapProviderChange()"
      >
        <mat-option [value]=""></mat-option>
        <mat-option *ngFor="let g of gapProviders" [value]="g.gapProviderId">
          {{ g.provider }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex appearance="outline" floatLabel="always">
      <mat-label> Summit Protection </mat-label>
      <mat-select multiple formControlName="summitProtectionProgramControl">
        <mat-option
          *ngFor="let pr of ancPrograms"
          [value]="pr.alphaProgramId"
          name="programId"
        >
          {{ pr.program }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field fxFlex appearance="outline" floatLabel="always">
      <mat-label> Warranty </mat-label>
      <mat-select formControlName="warrantyProgramControl">
        <mat-option [value]=""></mat-option>
        <mat-option
          *ngFor="let pr of warrantyPrograms"
          [value]="pr.alphaProgramId"
          name="programId"
        >
          {{ pr.program }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
  </div>

  <div
    formGroupName="gapPrograms"
    *ngIf="selectedGapProvider() == 1 || selectedGapProvider() == 2"
  >
    <div *ngIf="selectedGapProvider() == 1 && isFranchise">
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1em"
        fxLayoutGap.lt-md="0"
      >
        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> GAP </mat-label>
          <mat-select formControlName="franchiseClassicStandardGapControl">
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let pr of franchiseClassicStandardGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> Essential </mat-label>
          <mat-select formControlName="franchiseClassicEssentialGapControl">
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let pr of franchiseClassicEssentialGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> Commercial </mat-label>
          <mat-select
            multiple
            formControlName="franchiseClassicCommercialGapControl"
          >
            <mat-option
              *ngFor="let pr of franchiseClassicCommercialGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="selectedGapProvider() == 2 && isFranchise">
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1em"
        fxLayoutGap.lt-md="0"
      >
        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> GAP </mat-label>
          <mat-select formControlName="franchiseCarcoStandardGapControl">
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let pr of franchiseCarcoStandardGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label *ngIf="selectedGapProvider() == 1"> Essential </mat-label>
          <mat-label *ngIf="selectedGapProvider() == 2">
            Buy Here Pay Here
          </mat-label>
          <mat-select formControlName="franchiseCarcoBuyHerePayHereGapControl">
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let pr of franchiseCarcoBuyHerePayHereGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> Commercial </mat-label>
          <mat-select
            multiple
            formControlName="franchiseCarcoCommercialGapControl"
          >
            <mat-option
              *ngFor="let pr of franchiseCarcoCommercialGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="selectedGapProvider() == 1 && !isFranchise">
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1em"
        fxLayoutGap.lt-md="0"
      >
        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> GAP </mat-label>
          <mat-select formControlName="independentClassicStandardGapControl">
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let pr of independentClassicStandardGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label *ngIf="selectedGapProvider() == 1"> Essential </mat-label>
          <mat-label *ngIf="selectedGapProvider() == 2">
            Buy Here Pay Here
          </mat-label>
          <mat-select formControlName="independentClassicEssentialGapControl">
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let pr of independentClassicEssentialGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> Commercial </mat-label>
          <mat-select
            multiple
            formControlName="independentClassicCommercialGapControl"
          >
            <mat-option
              *ngFor="let pr of independentClassicCommercialGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="selectedGapProvider() == 2 && !isFranchise">
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="1em"
        fxLayoutGap.lt-md="0"
      >
        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> GAP </mat-label>
          <mat-select formControlName="independentCarcoStandardGapControl">
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let pr of independentCarcoStandardGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label *ngIf="selectedGapProvider() == 1"> Essential </mat-label>
          <mat-label *ngIf="selectedGapProvider() == 2">
            Buy Here Pay Here
          </mat-label>
          <mat-select
            formControlName="independentCarcoBuyHerePayHereGapControl"
          >
            <mat-option [value]=""></mat-option>
            <mat-option
              *ngFor="let pr of independentCarcoBuyHerePayHereGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex appearance="outline" floatLabel="always">
          <mat-label> Commercial </mat-label>
          <mat-select
            multiple
            formControlName="independentCarcoCommercialGapControl"
          >
            <mat-option
              *ngFor="let pr of independentCarcoCommercialGapPrograms"
              [value]="pr.alphaProgramId"
              name="programId"
            >
              {{ pr.program }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
