
<h4>Current Year vs Last Year</h4>
<div *ngIf="!data">
  <div>No Results</div>
</div>
<div class="table-container">
  <div style="min-height: 450px !important; ">
    <div *ngIf="data" style="min-height: 450px !important; min-width: 928px !important ">
      <google-chart style="width: 100%; height: 400px"
                    [type]="chartType"
                    [data]="data"
                    [columns]="columnNames"
                    [options]="options"
                    (ready)="onReady($event)"></google-chart>
    </div>
  </div>
</div>
<div *ngIf="chartImage && data">
  <a mat-flat-button
     color="primary"
     [href]="chartImage"
     download>
    <mat-icon>download</mat-icon>
    Download Chart Image
  </a>
</div>
