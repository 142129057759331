import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, EMPTY } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dealer-filtered-chip-select',
  templateUrl: './dealer-filtered-chip-select.component.html',
  styleUrls: ['./dealer-filtered-chip-select.component.css'],
})
export class DealerFilteredChipSelectComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  accessFilter: Observable<any[]>;
  inputFilter = new FormControl();
  dealers: { dealerNumber: string; dealerName: string }[] = [];
  limit = 8192;

  @Input('bindFormControl')
  formControl = new FormControl();

  @Input('required')
  required = false;

  @Input('label')
  label: string = 'Dealer';

  @Input('dealersLoaded')
  dealersLoaded: boolean = true;

  @Input('selectedDealers')
  selectedDealers: [] = [];

  @Input('dealerList')
  dealerList: { dealerNumber: string; dealerName: string }[] = [];

  @Input('agentNumber')
  agentNumber: [] = [];

  @ViewChild('dealerInput') dealerInput: ElementRef<HTMLInputElement>;

  constructor(private dashboard: DashboardService) {
    this.accessFilter = this.inputFilter.valueChanges.pipe(
      startWith(null),
      map((m: string | null) =>
        m ? this._filter(m) : this.dealerList.slice(0, this.limit)
      )
    );

    if (!this.agentNumber) {
      this.limit = 150;
    }
  }

  ngOnInit() {
    if (this.selectedDealers) {
      this.dashboard.filtersGetSelectedDealers(this.selectedDealers).subscribe({
        next: (res) => {
          this.dealers = res;
          this.formControl.setValue(this.dealers.map((m) => m.dealerNumber));
        },
      });
    }

    // this.accessFilter = this.inputFilter.valueChanges.pipe(
    //   startWith(null),
    //   debounceTime(500),
    //   switchMap((value: string | DealerDetail) => {
    //     if (!value) {
    //       return this.dashboard.filtersDealersByAgentNumber(
    //         this.agentNumber,
    //         ''
    //       );
    //     }
    //     if (value && (value as string).length > 0) {
    //       return this.dashboard.filtersDealersByAgentNumber(
    //         this.agentNumber,
    //         value as string
    //       );
    //     }
    //     return EMPTY;
    //   })
    // );
  }

  private _filter(value: string): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.dealerList.filter(
        (m) =>
          m.dealerName.toLowerCase().includes(filterValue) ||
          m.dealerNumber.toLowerCase().includes(filterValue)
      );
    }
    return this.dealerList.slice(0, this.limit);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dealersLoaded) {
      this.inputFilter.enable();
    } else {
      this.inputFilter.disable();
    }

    if (changes.dealerList) {
      this.accessFilter = this.inputFilter.valueChanges.pipe(
        startWith(null),
        map((m: string | null) =>
          m ? this._filter(m) : this.dealerList.slice(0, this.limit)
        )
      );
    }

    if (!this.selectedDealers) {
      this.dealers = [];
    }
  }

  displayWith(value: DealerDetail) {
    if (value && value.dealerNumber) {
      return `${value.dealerNumber} - ${value.dealerName}`;
    }
    return value;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our dealer
    if (value) {
      this.dealers.push({ dealerNumber: event.value, dealerName: event.value });
    }
    // Clear the input value
    event.input!.value = '';
    this.formControl.setValue(this.dealers.map((m) => m.dealerNumber));
  }

  remove(dealer: any): void {
    const index = this.dealers.indexOf(dealer);
    if (index >= 0) {
      this.dealers.splice(index, 1);
    }
    this.formControl.setValue(this.dealers.map((m) => m.dealerNumber));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (
      !this.dealers.find(
        (m) => m.dealerNumber == event.option.value.dealerNumber
      )
    ) {
      this.dealers.push({
        dealerNumber: event.option.value.dealerNumber,
        dealerName: event.option.value.dealerName,
      });
    }

    this.dealerInput.nativeElement.value = '';
    this.formControl.setValue(this.dealers.map((m) => m.dealerNumber));
  }
}

export interface DealerDetail {
  dealerNumber: string;
  dealerName: string;
}
