<mat-form-field appearance="outline" (blur)="blur()">
  <mat-label>{{ displaylabel }}</mat-label>
  <mat-chip-list #chipList [required]="isRequired">
    <mat-chip *ngFor="let d of selectedDealers; let i = index"
              [removable]="removable"
              (removed)="remove(i)">
      {{ d.scsNumber }} - {{ d.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      type="text"
      matInput
      #dlFilter
      [formControl]="inputFilter"
      [matAutocomplete]="dealerFilterAc"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [required]="isRequired"
    />
  </mat-chip-list>
  <mat-autocomplete
    #dealerFilterAc="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="autoSelectChange(dlFilter)"
  >
    <mat-option *ngFor="let d of scsAccessFilter | async" [value]="d">
      {{ d.scsNumber }} - {{ d.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
