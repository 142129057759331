<div class="autoscroll-width table-border table-container" [hidden]="loading">
  <table mat-table class="table-striped" matSort [dataSource]="claimDataSource">
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
      <td mat-cell *matCellDef="let row">
        {{ row.firstName | uppercase }} {{ row.lastName | uppercase }}
      </td>
    </ng-container>
    <ng-container matColumnDef="claimEntryDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Claim Date</th>
      <td mat-cell *matCellDef="let row">
        {{ row.claimSystemEntryDate | date : 'M/d/yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="contractNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract #</th>
      <td mat-cell *matCellDef="let row">
        <a mat-link-button (click)="navigateToContract(row.contractNumber)">
          {{ row.contractNumber }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="dealerNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Dealer #</th>
      <td mat-cell *matCellDef="let row">
        {{ row.dealerNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="claimNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Claim Number</th>
      <td mat-cell *matCellDef="let row">
        <a
          mat-link-button
          (click)="navigateToClaim(row.claimNumber, row.dealerNumber)"
        >
          {{ row.claimNumber }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row">
        {{ getClaimStatus(row.claimStatus) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="datePaid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Paid</th>
      <td mat-cell *matCellDef="let row">
        {{ row.dateClaimPaid | date : 'M/d/yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="paidAmount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid Amount</th>
      <td mat-cell *matCellDef="let row">
        {{ row.amountPaid | currency }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th width="100" mat-header-cell *matHeaderCellDef>Details</th>
      <td mat-cell *matCellDef="let row">
        <a
          mat-icon-button
          (click)="navigateToClaim(row.claimNumber, row.dealerNumber)"
        >
          <mat-icon class="alpha-blue">open_in_new</mat-icon>
        </a>
      </td>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="
        multipleDealers ? claimColumnsWithDealers : claimColumns
      "
    ></tr>
    <tr
      mat-row
      *matRowDef="
        let row;
        columns: multipleDealers ? claimColumnsWithDealers : claimColumns
      "
    ></tr>
  </table>
  <mat-paginator
    [pageSize]="25"
    [pageSizeOptions]="[5, 10, 25, 100]"
  ></mat-paginator>
</div>
