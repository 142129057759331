<div fxLayout="row" fxLayoutAlign="flex-end">
  <button mat-icon-button (click)="onDialogClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content style="margin-right: 20px; margin-left: 20px">
  <form [formGroup]="surveyForm">
    <div [ngSwitch]="dialogChanger">
      <div *ngSwitchCase="'None'">
        <div fxLayout="column" fxLayoutGap="30px" mat-dialog-content>
          <div fxLayoutAlign="center">
            <h2
              *ngIf="!isTimeoutTriggered()"
              class="yellow bold-text"
              style="font-weight: bold; margin: 0px"
              ngStyle.xs="font-size: 3em;">
              Your Voice Matters!
            </h2>
            <h2
              *ngIf="isTimeoutTriggered()"
              class="yellow bold-text"
              style="font-weight: bold; margin: 0px"
              ngStyle.xs="font-size: 3em;">
              We Love Feedback!
            </h2>
          </div>
          <div fxLayoutAlign="center">
            <p
              class="blue-text"
              style="margin-bottom: 0px"
              ngStyle.xs="font-size: 1em;">
              How was your experience with Alpha Warranty Services?
            </p>
          </div>
          <div fxLayoutAlign="space-evenly center" style="margin-bottom: 40px">
            <div
              fxLayout="row"
              fxLayoutGap="100px"
              fxLayoutGap.xs="30px"
              fxLayout.xs="column">
              <div fxLayoutAlign="start">
                <button
                  mat-ripple
                  value="Positive"
                  class="material-symbols-outlined rounded-button-positive"
                  (click)="onPositiveFeedback()">
                  thumb_up
                </button>
              </div>
              <div fxLayoutAlign="end" style="margin: 0 auto">
                <button
                  mat-ripple
                  [value]="'Negative'"
                  class="material-symbols-outlined rounded-button-negative"
                  (click)="onNegativeFeedback()">
                  thumb_down
                </button>
              </div>
            </div>
          </div>
          <div fxLayoutAlign="space-evenly center" style="margin-top: 20px">
            <img
              alt="Alpha Warranty Services"
              height="45"
              src="../../assets/alpha-logo-black.png" />
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'postiveFeedback'">
        <div fxLayout="column">
          <div fxLayoutAlign="center">
            <h2
              class="yellow bold-text"
              style="font-weight: bold; margin-bottom: 20px; margin-top: 0"
              ngStyle.xs="font-size: 3em;">
              Thank you!
            </h2>
          </div>
          <div fxLayoutAlign="center">
            <p class="blue-text" style="font-size: 20px">
              Please leave any additional comments about <br />
              your experience below!
            </p>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline">
              <textarea
                formControlName="Testimonial"
                class="testimonial"
                matInput
                placeholder="Add testimonial here..."></textarea>
            </mat-form-field>
            <a (click)="popoutChat()"> CHAT WITH US </a>
          </div>
          <div fxLayout="row" fxLayoutAlign="flex-end">
            <button
              mat-raised-button
              color="primary"
              (click)="submitTestimonial()">
              Submit
            </button>
          </div>
          <div fxLayoutAlign="center" style="margin-top: 20px">
            <img
              alt="Alpha Warranty Services"
              height="45"
              src="../../assets/alpha-logo-black.png" />
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'negativeFeedback'" fxLayout="column">
        <div fxLayout="column">
          <div fxLayoutAlign="center">
            <h2
              class="yellow bold-text"
              style="font-weight: bold; margin-bottom: 20px; margin-top: 0"
              ngStyle.xs="font-size: 3em;">
              We're sorry...
            </h2>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="center"
            style="margin-bottom: 1em">
            <p
              class="blue-text bold"
              style="font-size: 21px; margin-bottom: 0.5em">
              Please use the box below to share your feedback.
            </p>

            <p class="blue-text" style="font-size: 16px">
              The feedback shared below supports our ongoing efforts to develop
              products and services that enhance the experience for both dealers
              and contract holders.
            </p>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline">
              <textarea
                formControlName="Testimonial"
                class="testimonial"
                matInput
                placeholder="Add feedback here..."></textarea>
            </mat-form-field>
            <a (click)="popoutChat()"> CHAT WITH US </a>
          </div>
          <div fxLayout="row" fxLayoutAlign="center" style="margin-bottom: 1em">
            <button
              mat-raised-button
              color="primary"
              (click)="submitTestimonial()">
              Submit
            </button>
          </div>

          <p class="blue-text" style="font-size: 16px">
            If further assistance is needed <br />
            please call <b>1.800.662.5519 ext. 3</b>
          </p>

          <div fxLayoutAlign="center">
            <img
              alt="Alpha Warranty Services"
              height="45"
              src="../../assets/alpha-logo-black.png" />
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
