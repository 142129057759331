<footer>
  <article
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutGap="2em;"
    fxLayoutAlign=" center"
  >
    <img
      *ngIf="logoPath"
      alt="Alpha Warranty Services"
      height="45"
      [src]="logoPath"
    />
    <div>
      <div fxFlex fxLayoutAlign="center center" class="social-icons">
        <a
          href="http://www.facebook.com/pages/Alpha-Warranty-Services/313071106890"
          target="_blank"
          ><img src="/assets/images/social/facebook-white.png"
        /></a>
        <a
          href="https://www.linkedin.com/company/alpha-warranty-services"
          target="_blank"
          ><img src="/assets/images/social/linkedin-white.png"
        /></a>
        <a href="http://twitter.com/alphawarranty" target="_blank"
          ><img src="/assets/images/social/twitter-white.png"
        /></a>
        <a href="https://www.instagram.com/alphawarranty/" target="_blank"
          ><img src="/assets/images/social/instagram-white.png"
        /></a>
        <a
          href="https://www.youtube.com/channel/UCJoOYkBY3wWjUkL0wjV3fnA"
          target="_blank"
          ><img src="/assets/images/social/youtube-white.png"
        /></a>
      </div>
    </div>
    <div>
      <small>
        1.800.662.5519
        <br />Copyright &copy; {{ year }} <br />
        Rights Reserved by Alpha Warranty Services Inc.
        <br />
      </small>
    </div>
    <div
      fxFlex
      fxLayoutAlign="end center"
      fxFlexAlign="end"
      style="padding-right: 200px"
    >
      <img
        alt="SOC Logo"
        height="65"
        src="../../assets/21972-312_SOC_NonCPA.png"
      />
    </div>
  </article>
</footer>
