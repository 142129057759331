<div id="cilajet-order-form" fxLayout="column" fxLayoutAlign="start center">
  <div id="scroll" style="width: 100%; max-width: 900px">
    <mat-card-header>
      <div
        style="width: 100%"
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <h2>CILAJET ORDER FORM</h2>
        <button mat-icon-button class="grey-icon" (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <hr />
    <div mat-dialog-content>
      <form [formGroup]="formGroup" fxLayout="column" fxLayoutGap="1em">
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          fxLayoutAlign="space-between start"
          fxLayoutGap="1em">
          <div id="shipping-section" fxLayout="column" fxFlex="48">
            <div>
              <h4>Ship To:</h4>
            </div>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Dealership/Agency Name</mat-label>
              <input
                matInput
                formControlName="shippingTo"
                placeholder=""
                required />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Email Address</mat-label>
              <input
                matInput
                formControlName="shippingEmail"
                placeholder=""
                required />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Attention</mat-label>
              <input
                matInput
                formControlName="shippingAttention"
                placeholder="" />
            </mat-form-field>

            <div>
              <h4>Shipping Address:</h4>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Address</mat-label>
                <input
                  matInput
                  formControlName="shippingAddress"
                  placeholder="Address"
                  required />
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayout.lt-sm="row wrap"
              fxLayoutAlign="space-between center"
              fxLayoutGap="10px"
              fxLayoutGap.lt-sm="0px"
              style="width: 100%">
              <div fxFlex="20" fxFlex.lt-sm="100">
                <mat-form-field appearance="outline">
                  <mat-label>Zip</mat-label>
                  <input
                    matInput
                    formControlName="shippingPostalCode"
                    placeholder="Zip Code"
                    (keyup)="postalLookup($event)"
                    mask="00000"
                    required />
                </mat-form-field>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100" style="flex-basis: shrink">
                <mat-form-field appearance="outline">
                  <mat-label>City</mat-label>
                  <input
                    matInput
                    formControlName="shippingCity"
                    placeholder="City"
                    required />
                </mat-form-field>
              </div>
              <div fxFlex="23" fxFlex.lt-sm="100">
                <mat-form-field appearance="outline">
                  <mat-label>State</mat-label>
                  <mat-select formControlName="shippingState" required>
                    <mat-option
                      *ngFor="let state of states$"
                      [value]="state.abbreviation">
                      {{ state.abbreviation }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div id="billing-section" fxLayout="column" fxFlex="48">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <h4>Bill To:</h4>
              <div>
                <mat-checkbox
                  formControlName="sameAsShipping"
                  (click)="toggleSameAsShipping($event)">
                  Same as Shipping
                </mat-checkbox>
              </div>
            </div>
            <mat-form-field appearance="outline" *ngIf="showBillingCategory">
              <mat-label>Category</mat-label>
              <mat-select formControlName="billingCategory">
                <mat-option [value]="'dealer'">Dealer</mat-option>
                <mat-option [value]="'agency'">Agent/Sub Agent</mat-option>

                <mat-option *ngIf="isAdmin" [value]="'admin'">Admin</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Dealership/Agency Name</mat-label>
              <input
                matInput
                formControlName="billingTo"
                placeholder=""
                required />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Email Address</mat-label>
              <input
                matInput
                formControlName="billingEmail"
                placeholder=""
                required />
            </mat-form-field>

            <div
              fxHide.lt-md
              style="height: 67px"
              *ngIf="!showBillingCategory"></div>

            <div>
              <h4>Billing Address:</h4>
            </div>
            <div fxLayout="row">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Address</mat-label>
                <input
                  matInput
                  formControlName="billingAddress"
                  placeholder="Address"
                  required />
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayout.lt-sm="row wrap"
              fxLayoutAlign="space-between center"
              fxLayoutGap="10px"
              fxLayoutGap.lt-sm="0px"
              style="width: 100%">
              <div fxFlex="20" fxFlex.lt-sm="100">
                <mat-form-field appearance="outline">
                  <mat-label>Zip</mat-label>
                  <input
                    matInput
                    formControlName="billingPostalCode"
                    placeholder="Zip Code"
                    (keyup)="postalLookup($event, false)"
                    mask="00000"
                    required />
                </mat-form-field>
              </div>
              <div fxFlex="50" fxFlex.lt-sm="100" style="flex-basis: shrink">
                <mat-form-field appearance="outline">
                  <mat-label>City</mat-label>
                  <input
                    matInput
                    formControlName="billingCity"
                    placeholder="City"
                    required />
                </mat-form-field>
              </div>
              <div fxFlex="23" fxFlex.lt-sm="100">
                <mat-form-field appearance="outline">
                  <mat-label>State</mat-label>
                  <mat-select formControlName="billingState" required>
                    <mat-option
                      *ngFor="let state of states$"
                      [value]="state.abbreviation">
                      {{ state.abbreviation }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <mat-card
          id="note-card"
          fxLayout="column"
          fxLayoutAlign="space-between center"
          fxLayoutGap="1em">
          <p>
            *Note: Standard Ground Shipping is included. Rush Shipping is
            available for additional charge.
          </p>
        </mat-card>
        <div fxLayout="column">
          <div
            class="info-alert"
            fxLayout="column"
            fxLayoutAlign="space-between center">
            <h3>CILAJET WINDSHIELD</h3>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 50px">QTY</th>
                <th>ITEM</th>
                <th>UNIT PRICE</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 50px">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      formControlName="quantity"
                      mask="099"
                      required />
                  </mat-form-field>
                </td>
                <td>Cilajet Windshield Protection Packets</td>
                <td>{{ unitPrice | currency }}</td>
                <td *ngIf="selectedQty <= 0">-</td>
                <td *ngIf="selectedQty > 0">
                  {{ unitPrice * selectedQty | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap=".5em">
        <small *ngIf="formGroup.invalid" class="hint">
          Please fill out all required fields.
        </small>
        <button
          *ngIf="!submitting"
          [disabled]="formGroup.invalid"
          mat-raised-button
          color="primary"
          (click)="submit()">
          Submit
        </button>
        <mat-spinner *ngIf="submitting" diameter="35"></mat-spinner>
      </div>
    </div>
  </div>
</div>
