<h3 mat-dialog-title>Please agree to all agreements to move forward.</h3>

<mat-dialog-content>
  <mat-divider></mat-divider>
  <form formArray="contextualFormArray" fxLayout="column">
    <mat-tab-group #tabGroup>
      <div *ngFor="let fm of agreements">
        <mat-tab disabled>
          <div class="agreement-template">
            <div
              class="tab-content small"
              [innerHTML]="sanitize(fm.agreement, fm.displayName)"
            ></div>
            <ng-template mat-tab-label>
              <mat-icon
                *ngIf="fm.isAccepted.value"
                class="tab-icon"
                color="primary"
                >done_outline</mat-icon
              >
              <mat-icon
                *ngIf="!fm.isAccepted.value"
                class="tab-icon"
                color="warning"
                >error_outline</mat-icon
              >
              {{ fm.displayName }}
            </ng-template>
          </div>
          <p class="signature-disclosure">
            <mat-checkbox
              matInput
              class="margin-top-sm"
              labelPosition="before"
              [formControl]="fm.isAccepted"
              (click)="moveToNextTab()"
            >
              I agree to the {{ fm.displayName }}
            </mat-checkbox>
          </p>
        </mat-tab>
      </div>
      <mat-tab *ngIf="agreements.length > 0" disabled>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon" color="warning">error_outline</mat-icon>
          Signature
        </ng-template>
        <div class="signature-disclosure">
          By signing here, I certify that I am signing in my own name and I
          represent and warrant that I have the authority to execute this
          Agreement on behalf of the Dealer, I consent to conducting these
          transactions electronically, and I understand that this signature
          applies to all selected agreements in this Dealer signup.
        </div>
        <div [formGroup]="signatureForm">
          <div class="sig-container top-margin">
            <app-signature-field
              *ngIf="!loading"
              [existingSignature]="existingSignature"
              formControlName="file"
            ></app-signature-field>
          </div>
          <div
            class="input-row"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutGap="20px"
            fxLayoutGap.lt-md="0px"
          >
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Printed First Name</mat-label>
              <input
                matInput
                placeholder="Printed First Name"
                formControlName="signerFirstName"
                required
              />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Printed Last Name</mat-label>
              <input
                matInput
                placeholder="Printed First Name"
                formControlName="signerLastName"
                required
              />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Signer Title</mat-label>
              <input
                matInput
                placeholder="Signer Title"
                formControlName="signerTitle"
                required
              />
            </mat-form-field>
          </div>
          <div style="padding-bottom: 2em" fxLayoutAlign="end">
            <button mat-button type="button" [matDialogClose]="false">
              Cancel
            </button>
            <button
              *ngIf="!dealerProfileId"
              mat-raised-button
              type="button"
              [matDialogClose]="this.signatureForm.value"
              [disabled]="!signatureForm.valid"
              color="primary"
            >
              Add
            </button>
            <button
              *ngIf="dealerProfileId"
              mat-raised-button
              type="button"
              (click)="updateSignature()"
              [disabled]="!signatureForm.valid"
              color="primary"
            >
              Update
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</mat-dialog-content>
