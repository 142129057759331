import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AlphaProgram, GapProviders } from '../quick-sign.model';
import { QuickSignService } from '../quick-sign.service';

@Component({
  selector: 'app-program-select',
  templateUrl: './program-select.component.html',
  styleUrls: ['./program-select.component.css'],
})
export class ProgramSelectComponent implements OnInit {
  @Input()
  franchise: boolean = false;
  @Output('programsChange')
  selectedPrograms: EventEmitter<any[]> = new EventEmitter<any[]>();

  gapProviders: GapProviders[];

  vscPrograms: AlphaProgram[];
  warrantyPrograms: AlphaProgram[];
  ancPrograms: AlphaProgram[];
  franchiseClassicStandardGapPrograms: AlphaProgram[];
  franchiseClassicEssentialGapPrograms: AlphaProgram[];
  franchiseClassicCommercialGapPrograms: AlphaProgram[];
  franchiseCarcoStandardGapPrograms: AlphaProgram[];
  franchiseCarcoBuyHerePayHereGapPrograms: AlphaProgram[];
  franchiseCarcoCommercialGapPrograms: AlphaProgram[];
  independentClassicStandardGapPrograms: AlphaProgram[];
  independentClassicEssentialGapPrograms: AlphaProgram[];
  independentClassicCommercialGapPrograms: AlphaProgram[];
  independentCarcoStandardGapPrograms: AlphaProgram[];
  independentCarcoBuyHerePayHereGapPrograms: AlphaProgram[];
  independentCarcoCommercialGapPrograms: AlphaProgram[];

  gapProviderControl = new FormControl();

  franchiseClassicStandardGapControl = new FormControl(null);
  franchiseClassicEssentialGapControl = new FormControl(null);
  franchiseClassicCommercialGapControl = new FormControl(null);
  franchiseCarcoStandardGapControl = new FormControl(null);
  franchiseCarcoBuyHerePayHereGapControl = new FormControl(null);
  franchiseCarcoCommercialGapControl = new FormControl(null);
  independentClassicStandardGapControl = new FormControl(null);
  independentClassicEssentialGapControl = new FormControl(null);
  independentClassicCommercialGapControl = new FormControl(null);
  independentCarcoStandardGapControl = new FormControl(null);
  independentCarcoBuyHerePayHereGapControl = new FormControl(null);
  independentCarcoCommercialGapControl = new FormControl(null);
  vscProgramControl = new FormControl(null);
  warrantyProgramControl = new FormControl(null);
  summitProtectionProgramControl = new FormControl(null);

  programsFormGroup = new FormGroup({
    vscProgramControl: this.vscProgramControl,
    warrantyProgramControl: this.warrantyProgramControl,
    summitProtectionProgramControl: this.summitProtectionProgramControl,
    gapProviderId: this.gapProviderControl,
  });

  gapProgramsFormGroup = new FormGroup({
    franchiseClassicStandardGapControl: this.franchiseClassicStandardGapControl,
    franchiseClassicEssentialGapControl: this.franchiseClassicEssentialGapControl,
    franchiseClassicCommercialGapControl: this.franchiseClassicCommercialGapControl,
    franchiseCarcoStandardGapControl: this.franchiseCarcoStandardGapControl,
    franchiseCarcoBuyHerePayHereGapControl: this.franchiseCarcoBuyHerePayHereGapControl,
    franchiseCarcoCommercialGapControl: this.franchiseCarcoCommercialGapControl,
    independentClassicStandardGapControl: this.independentClassicStandardGapControl,
    independentClassicEssentialGapControl: this.independentClassicEssentialGapControl,
    independentClassicCommercialGapControl: this.independentClassicCommercialGapControl,
    independentCarcoStandardGapControl: this.independentCarcoStandardGapControl,
    independentCarcoBuyHerePayHereGapControl: this.independentCarcoBuyHerePayHereGapControl,
    independentCarcoCommercialGapControl: this.independentCarcoCommercialGapControl,
  });

  productFormGroup = new FormGroup({
    programs: this.programsFormGroup,
    gapPrograms: this.gapProgramsFormGroup
  });

  constructor(private quicksign: QuickSignService) {
    this.productFormGroup.valueChanges
      .pipe(debounceTime(150))
      .subscribe({
        next: values => {
          const programs = this.combinePrograms();
          if (!environment.production) {
            console.log(programs);
          }
          this.selectedPrograms.next(programs);
        }
      });
  }

  ngOnInit() {
    // Load programs
    this.quicksign.getGapPrograms().subscribe({
      next: (gapPrograms) => {
        this.franchiseCarcoBuyHerePayHereGapPrograms = gapPrograms.filter(
          (x) =>
            x.isFranchise && x.provider == 'CarCo' && x.context == 'Essential'
        );
        this.franchiseCarcoCommercialGapPrograms = gapPrograms.filter(
          (x) =>
            x.isFranchise && x.provider == 'CarCo' && x.context == 'Commercial'
        );
        this.franchiseCarcoStandardGapPrograms = gapPrograms.filter(
          (x) =>
            x.isFranchise && x.provider == 'CarCo' && x.context == 'Signature'
        );
        this.franchiseClassicCommercialGapPrograms = gapPrograms.filter(
          (x) =>
            x.isFranchise &&
            x.provider == 'Classic' &&
            x.context == 'Commercial'
        );
        this.franchiseClassicEssentialGapPrograms = gapPrograms.filter(
          (x) =>
            x.isFranchise && x.provider == 'Classic' && x.context == 'Essential'
        );
        this.franchiseClassicStandardGapPrograms = gapPrograms.filter(
          (x) =>
            x.isFranchise &&
            x.provider == 'Classic' &&
            (x.context == 'Signature' || x.context == 'Complete')
        );
        this.independentClassicStandardGapPrograms = gapPrograms.filter(
          (x) =>
            !x.isFranchise &&
            x.provider == 'Classic' &&
            (x.context == 'Signature' || x.context == 'Complete')
        );
        this.independentClassicEssentialGapPrograms = gapPrograms.filter(
          (x) =>
            !x.isFranchise &&
            x.provider == 'Classic' &&
            x.context == 'Essential'
        );
        this.independentClassicCommercialGapPrograms = gapPrograms.filter(
          (x) =>
            !x.isFranchise &&
            x.provider == 'Classic' &&
            x.context == 'Commercial'
        );
        this.independentCarcoStandardGapPrograms = gapPrograms.filter(
          (x) =>
            !x.isFranchise && x.provider == 'CarCo' && x.context == 'Signature'
        );
        this.independentCarcoCommercialGapPrograms = gapPrograms.filter(
          (x) =>
            !x.isFranchise && x.provider == 'CarCo' && x.context == 'Commercial'
        );
        this.independentCarcoBuyHerePayHereGapPrograms = gapPrograms.filter(
          (x) =>
            !x.isFranchise && x.provider == 'CarCo' && x.context == 'Essential'
        );
      },
    });
    this.quicksign.getPrograms().subscribe({
      next: (programs) => {
        this.vscPrograms = programs.filter((x) => x.context == 'VSC');
        this.warrantyPrograms = programs.filter((x) => x.context == 'Warranty');
        this.ancPrograms = programs.filter(
          (x) => x.context == 'Summit Protection'
        );
      },
    });
    this.quicksign.getGapProviders().subscribe({
      next: (providers) => {
        this.gapProviders = providers;
      },
    });
  }

  gapProviderChange() {
    const gpv = this.gapProviderControl.value;
    if (gpv == 1) {
      this.franchiseCarcoStandardGapControl.reset();
      this.franchiseCarcoBuyHerePayHereGapControl.reset();
      this.franchiseCarcoCommercialGapControl.reset();
      this.independentCarcoStandardGapControl.reset();
      this.independentCarcoBuyHerePayHereGapControl.reset();
      this.independentCarcoCommercialGapControl.reset();
    } else if (gpv == 2) {
      this.franchiseClassicStandardGapControl.reset();
      this.franchiseClassicEssentialGapControl.reset();
      this.franchiseClassicCommercialGapControl.reset();
      this.independentClassicStandardGapControl.reset();
      this.independentClassicEssentialGapControl.reset();
      this.independentClassicCommercialGapControl.reset();
    } else {
      this.gapProgramsFormGroup.reset();
    }
  }

  selectedGapProvider() {
    return this.gapProviderControl.value;
  }

  get isFranchise() {
    return this.franchise;
  }

  combinePrograms() {
    let programs = [];
    if (this.vscProgramControl.value) {
      this.vscProgramControl.value.forEach(p => { programs.push(p); });
    }
    if (this.summitProtectionProgramControl.value) {
      this.summitProtectionProgramControl.value.forEach(p => { programs.push(p); });
    }
    if (this.warrantyProgramControl.value) {
      programs.push(this.warrantyProgramControl.value);
    }
    return programs.concat(this.combineGapPrograms());
  }

  combineGapPrograms() {
    let gapPrograms = [];
    // franchise carco
    if (this.franchiseCarcoStandardGapControl.value) {
      gapPrograms.push(this.franchiseCarcoStandardGapControl.value);
    }
    if (this.franchiseCarcoBuyHerePayHereGapControl.value) {
      gapPrograms.push(this.franchiseCarcoBuyHerePayHereGapControl.value);
    }
    if (this.franchiseCarcoCommercialGapControl.value) {
      this.franchiseCarcoCommercialGapControl.value.forEach(val => gapPrograms.push(val));
    }

    //franchise classic
    if (this.franchiseClassicStandardGapControl.value) {
      gapPrograms.push(this.franchiseClassicStandardGapControl.value);
    }
    if (this.franchiseClassicEssentialGapControl.value) {
      gapPrograms.push(this.franchiseClassicEssentialGapControl.value);
    }
    if (this.franchiseClassicCommercialGapControl.value) {
      this.franchiseClassicCommercialGapControl.value.forEach(val => gapPrograms.push(val));
    }
    //Independent classic
    if (this.independentClassicStandardGapControl.value) {
      gapPrograms.push(this.independentClassicStandardGapControl.value);
    }
    if (this.independentClassicEssentialGapControl.value) {
      gapPrograms.push(this.independentClassicEssentialGapControl.value);
    }
    if (this.independentClassicCommercialGapControl.value) {
      this.independentClassicCommercialGapControl.value.forEach(val => gapPrograms.push(val));
    }

    // independent carco
    if (this.independentCarcoStandardGapControl.value) {
      gapPrograms.push(this.independentCarcoStandardGapControl.value);
    }
    if (this.independentCarcoBuyHerePayHereGapControl.value) {
      gapPrograms.push(this.independentCarcoBuyHerePayHereGapControl.value);
    }
    if (this.independentCarcoCommercialGapControl.value) {
      this.independentCarcoCommercialGapControl.value.forEach(val => gapPrograms.push(val));
    }
    return gapPrograms;
  }
}
