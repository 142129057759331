import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CilajetService {
  private endpoint: string;
  constructor(private httpClient: HttpClient) {
    this.endpoint = environment.dashboardEndpoint;
  }

  submitForm(payload: any) {
    return this.httpClient.post(`${this.endpoint}api/cilajet`, payload);
  }
}
