<div fxLayout="row" fxLayoutAlign="center center" style="height: 100vh">
  <div class="form-card">
    <img src="../../assets/logo-md.png" />
    <h2 style="text-transform: none; color: #41b0db">
      Agent and Dealer Portal
    </h2>
    <form style="margin-bottom: 1em" [formGroup]="loginForm" (submit)="login()">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput type="text" formControlName="userName" />
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" />
      </mat-form-field>

      <div fxLayoutAlign="center">
        <button
          *ngIf="!loading"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="loginForm.invalid"
        >
          CONTINUE
        </button>
        <mat-spinner *ngIf="loading" class="loading" diameter="36">
        </mat-spinner>
      </div>
    </form>

    <button mat-button (click)="forgotPassword()" color="primary">
      Reset password
    </button>

    <p *ngIf="message" class="mat-error">
      {{ message }}
    </p>

    <div class="copyright-state">
      <div>
        Contact Sales Support at <strong>1.800.662.5519</strong> for Assistance
      </div>
      <div>
        Copyright &#169; All Rights Reserved by Alpha Warranty Services Inc.
      </div>
    </div>
  </div>
</div>
